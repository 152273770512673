import styles from "./index.module.scss"
import DropdownSelect from "react-dropdown-select"
import classNames from "classnames"
import { UITypes } from "../../../types"
import "../../../assets/libs/select.css"

interface Props {
  label?: string
  placeholder?: string
  values?: UITypes.Option[]
  name?: string
  disabled?: boolean
  errorMessage?: string
  options?: UITypes.Option[]
  portal?: HTMLElement
  multi?: boolean
  isLoading?: boolean
  onChange?: (value: UITypes.Option[]) => void
  minWidth?: string
}

export const SelectStatic = ({
  label,
  options = [],
  placeholder,
  disabled,
  values = [],
  name,
  portal,
  multi,
  isLoading,
  errorMessage,
  onChange,
  minWidth,
}: Props) => {
  return (
    <div className={styles.fieldWrapper}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <div className={classNames(styles.field)}>
        <DropdownSelect
          disabled={disabled}
          options={options}
          values={values?.length ? values : []}
          searchable
          loading={isLoading}
          className={classNames("custom", {
            [styles.error]: errorMessage,
            [styles.disabled]: disabled,
          })}
          name={name}
          style={{ minWidth: minWidth }}
          multi={multi}
          placeholder={placeholder}
          portal={portal}
          onChange={onChange as any}
        />
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
