import { useRef } from "react"
import classNames from "classnames"
import dayjs from "dayjs"
import { Line } from "react-chartjs-2"
import { Chart, registerables, ScriptableContext } from "chart.js"
import { v4 as uuidv4 } from "uuid"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ModalLayout } from "../../layouts/ModalLayout"
import { useAppSelector } from "../../store/hooks"
import { parseDateStringToUTCString } from "../../utils/parseDateStringToUTCString"
import parseCelsiusToFahrenheit from "../../utils/parseCelsiusToFahrenheit"
import { Tooltip } from "../../components/common/Tooltip"
import { defaultChartTooltipOptions, getChartGradient } from "../../utils/chartUtils"
import { getRoundedTemperature } from "../../utils/getRoundedTemperature"
import styles from "./index.module.scss"
import { NoDataLayer } from "../../components/chart/NoDataLayer"

Chart.register(...registerables)

export const PerformanceTestReportModal = ({ onCancel }) => {
  const performanceTestReport = useAppSelector((state) => state.tempData.performanceTestReport)

  const parsedData =
    performanceTestReport?.data.map((item) => ({
      temperature: parseCelsiusToFahrenheit(item.temperature),
      timestamp: parseDateStringToUTCString(item.timestamp),
    })) || []

  const data = {
    labels: [...parsedData.map((item) => item.timestamp), ...Array(60)].slice(0, 60),
    datasets: [
      {
        label: "",
        data: parsedData.map((item) => item.temperature),
        fill: "start",
        backgroundColor: (context: ScriptableContext<"line">) =>
          getChartGradient(context, "rgba(210,250,255,1)", "rgba(255,255,255,0)"),
        borderColor: "#43E0F8",
        pointStyle: false as unknown as "circle",
        pointBackgroundColor: "#fff",
      },
      ...(parsedData.length === 0
        ? [
            {
              label: "",
              data: [74, 75, 76, 77, 78],
            },
          ]
        : []),
    ],
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...defaultChartTooltipOptions,
        callbacks: {
          title: () => {
            return ""
          },
          label: function (this: any, tooltipItem) {
            const date = new Date(tooltipItem.label)
            const label = `${getRoundedTemperature(tooltipItem.formattedValue, 2)}°F ${date.toLocaleTimeString("IT")}`
            return label
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          color: "#e2e8f0",
        },
        grid: {
          color: "#e2e8f0",
        },
        ticks: {
          maxRotation: 0,
          maxTicksLimit: 15,
          color: "#94A3B8",
          callback: function (value) {
            return Math.floor(value / 4)
          },
        },
      } as any,
      y: {
        position: "left",
        border: {
          display: false,
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          maxTicksLimit: 6,
          stepSize: parsedData.length === 0 ? 1 : 0.1,
          color: "#94A3B8",
          callback: function (value) {
            return `${getRoundedTemperature(value, 1)}°F`
          },
        },
      } as any,
    },
    onHover: (ctx) => {
      ctx.chart.data.datasets[0].pointStyle = true
    },
  }

  const renderStatusTooltipContent = () => {
    if (performanceTestReport?.status?.toLowerCase() === "passed") {
      return (
        <>
          <div>• The system successfully completed the performance test.</div>
          <div>• The system maintained temperatures within the expected range.</div>
          <div>• No issues were detected during the test.</div>
          <div>• The performance test showed a temperature change of 2°F or more.</div>
        </>
      )
    }

    return (
      <>
        <div>• The system encountered an issue during the performance test.</div>
        <div>
          • The temperature may not have stayed within the expected range, or the data could be incomplete due to an
          internet outage.
        </div>
        <div>• Further inspection is recommended to identify and resolve the issue.</div>
        <div>
          • The performance test showed either a temperature change of less than 2°F or incomplete data was received by
          the server.
        </div>
      </>
    )
  }

  return (
    <>
      <ModalLayout width="580px" onCancel={onCancel} cancelButtonText="Close" buttonSize="fullWidth">
        <ModalHeader title="Report" />
        <div className={styles.modalSubtitle}>
          <p>Details</p>
        </div>
        <div className={classNames(styles.modalContent, styles.performanceTestModalContent)}>
          <div className={styles.contentRow}>
            <div className={styles.contentWrapper}>
              <div className={styles.detailsInfo}>
                <h4>Customer</h4>
                <p>{performanceTestReport?.customer}</p>
              </div>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.contentWrapper}>
              <div className={styles.detailsInfo}>
                <h4>Device Location(Name)</h4>
                <p>{performanceTestReport?.where_installed_name || "-"}</p>
              </div>
              <div className={styles.detailsInfo}>
                <h4>Zip Code</h4>
                <p>{performanceTestReport?.zip_code || "-"}</p>
              </div>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.contentWrapper}>
              <div className={styles.detailsInfo}>
                <h4>Date & Time</h4>
                <p>
                  {dayjs(parseDateStringToUTCString(performanceTestReport?.time || "")).format(
                    "MMM D, YYYY [at] h:mmA",
                  )}
                </p>
              </div>
            </div>
            <div className={styles.contentWrapper}>
              <div className={styles.detailsInfo}>
                <h4>Status</h4>
                <div className={styles.statusContainer}>
                  <div className={classNames(styles.testStatus, styles[performanceTestReport?.status?.toLowerCase()!])}>
                    <span></span>
                    <p>{performanceTestReport?.status}</p>
                  </div>
                  <Tooltip id={uuidv4()} text="">
                    {renderStatusTooltipContent()}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.contentWrapper}>
              <div className={styles.detailsInfo}>
                <h4>Performance Test</h4>
                <p>
                  {performanceTestReport?.action === "heating" ? "Winter" : "Summer"}{" "}
                  <span>({performanceTestReport?.action === "heating" ? "Heating" : "Cooling"})</span>
                </p>
              </div>
            </div>
            <div className={styles.contentWrapper}>
              <div className={styles.detailsInfo}>
                <h4>Temperature</h4>
                {parsedData.length === 0 ? (
                  <p>No Data</p>
                ) : (
                  <p>
                    from {parsedData[0].temperature.toFixed(1)}°F to{" "}
                    {parsedData[parsedData.length - 1].temperature.toFixed(1)}°F
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.reportDiagramContainer}>
            {parsedData.length === 0 && <NoDataLayer />}
            <Line data={data} options={options} />
          </div>
        </div>
      </ModalLayout>
    </>
  )
}
