import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconServiceTitan } from "../../assets/svg/modal-icon-service-titan.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { showAddContractorModal1 } from "../../store/modalSlise"
import { useAppDispatch } from "../../store/hooks"
import { setContractor } from "../../store/tempDataSlise"

interface Props {
  onCancel: () => void
}

export const ServiceTitanModal = ({ onCancel }: Props) => {
  const dispatch = useAppDispatch()

  const onButtonClick = (isServiceTitan: boolean, event?) => {
    event && event.preventDefault()
    onCancel()
    dispatch(setContractor({ isServiceTitan }))
    dispatch(showAddContractorModal1(true))
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="490px"
          cancelButtonText="No"
          confirmButtonText="Yes"
          onConfirm={(event) => onButtonClick(true, event)}
          onCancel={() => onButtonClick(false)}
          onClose={onCancel}
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconServiceTitan />
            <h3 className={styles.modalTitle}>ServiceTitan</h3>
            <div className={styles.modalMessage}>Do you want to add a contractor with a ServiceTitan account?</div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
