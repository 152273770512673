import styles from "./index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconTransfer } from "../../assets/svg/modal-icon-transfer.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { SelectDynamic } from "../../components/form/SelectDynamic"
import { useLazyGetContractorsQuery } from "../../store/api/contractorsApi"
import {
  useLazyPutTransferDevicesQuery,
  useLazyGetPhysicalStatusesWidgetQuery,
  useLazyGetInstalledDevicesWidgetQuery,
  useLazyGetAdminDevicesQuery,
} from "../../store/api/adminApi"
import { useState } from "react"
import { UITypes } from "../../types"
import { toast } from "react-toastify"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setSelectedDeviceIDs } from "../../store/tempDataSlise"

interface Props {
  onCancel: () => void
}

export const TransferDeviceModal = ({ onCancel }: Props) => {
  const [selectedContractor, setSelectedContractor] = useState<undefined | UITypes.Option[]>(undefined)
  const [triggerTransferDevices, { isFetching }] = useLazyPutTransferDevicesQuery()
  const [triggerGetPhysicalStatusesWidget] = useLazyGetPhysicalStatusesWidgetQuery()
  const [triggerGetInstalledDEvicesWidget] = useLazyGetInstalledDevicesWidgetQuery()
  const [triggerGetAdminDevices] = useLazyGetAdminDevicesQuery()
  const dispatch = useAppDispatch()
  const [errorMessage, setErrorMessage] = useState("")
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)

  const onSubmit = async (event) => {
    event.preventDefault()

    if (!selectedContractor?.length) {
      setErrorMessage("Please select a contractor")
      return
    }

    try {
      const res: any = await triggerTransferDevices({
        device_ids: selectedDeviceIDs,
        contractor_id: selectedContractor[0]?.value as string,
      })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetAdminDevices(requestParams === null ? {} : requestParams)
        triggerGetPhysicalStatusesWidget()
        triggerGetInstalledDEvicesWidget()
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconTransfer />
            <h3 className={styles.modalTitle}>Transfer</h3>
            <div className={styles.modalMessage}>
              Are you sure you want to transfer the device(s) to the selected Contractor?
            </div>
            <div className={styles.selectWrapper}>
              <SelectDynamic
                label="Contractors"
                valueName="id"
                labelName="name"
                placeholder="Select contractor"
                name="name"
                errorMessage={errorMessage}
                useLazyQuery={useLazyGetContractorsQuery}
                onChange={setSelectedContractor}
              />
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
