import { Line } from "react-chartjs-2"
import { Chart, ScriptableContext, registerables } from "chart.js"
import parseCelsiusToFahrenheit from "../../utils/parseCelsiusToFahrenheit"
import {
  borderColorWithSkipped,
  borderDashWithSkipped,
  defaultChartTooltipOptions,
  getChartGradient,
} from "../../utils/chartUtils"
import { getRoundedTemperature } from "../../utils/getRoundedTemperature"
import { NoDataLayer } from "../../components/chart/NoDataLayer"
import styles from "./index.module.scss"
import { ApiTypes } from "../../types"
import dayjs from "dayjs"
import { ChartSettings } from "./StatsReportChartsWrapper"

Chart.register(...registerables)

interface Props {
  selectedTime: string | number | boolean
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
  isSingleDataPoint: boolean
}

const TemperatureLineChart = ({ selectedTime, historicalData, isSingleDataPoint }: Props) => {
  const settings = ChartSettings[selectedTime as keyof typeof ChartSettings]

  const parsedData =
    historicalData.map((item) => ({
      temperature:
        item["avg#average_current_temperature_embedded"].value === null
          ? null
          : parseCelsiusToFahrenheit(item["avg#average_current_temperature_embedded"].value),
      timestamp: item.key_as_string,
    })) || []

  const data = {
    labels: [...parsedData.map((item) => item.timestamp)],
    datasets: [
      {
        label: "",
        data: parsedData.map((item) => item.temperature),
        fill: "start",
        backgroundColor: (context: ScriptableContext<"line">) =>
          getChartGradient(context, "rgba(210,250,255,1)", "rgba(255,255,255,0)"),
        borderColor: "#43E0F8",
        pointStyle: isSingleDataPoint as unknown as "circle",
        pointBackgroundColor: "#fff",
        spanGaps: true,
        segment: {
          borderDash: borderDashWithSkipped,
          borderColor: borderColorWithSkipped,
        },
      },
      ...(parsedData.length === 0
        ? [
            {
              label: "",
              data: [74, 75, 76, 77, 78],
            },
          ]
        : []),
    ],
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...defaultChartTooltipOptions,
        callbacks: {
          title: () => {
            return ""
          },
          label: function (this: any, tooltipItem) {
            const date = dayjs(tooltipItem.label)
            const label = `${getRoundedTemperature(tooltipItem.formattedValue, 2)}°F ${date.format("YYYY-MM-DD HH:mm")}`

            return label
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          color: "#e2e8f0",
        },
        grid: {
          color: "#e2e8f0",
        },
        ticks: {
          maxRotation: 0,
          maxTicksLimit: settings.ticksCount,
          callback: (value) => value / settings.dataCountsDivider,
          color: "#94A3B8",
        },
      } as any,
      y: {
        position: "left",
        border: {
          display: false,
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          maxTicksLimit: 6,
          stepSize: parsedData.length === 0 ? 1 : 0.1,
          callback: function (value) {
            return `${getRoundedTemperature(value, 1)}°F`
          },
          color: "#94A3B8",
        },
      } as any,
    },
    onHover: (ctx) => {
      ctx.chart.data.datasets[0].pointStyle = true
    },
  }

  return (
    <div className={styles.chartContainer}>
      {parsedData.length === 0 && <NoDataLayer />}
      <Line data={data} options={options} />
    </div>
  )
}

export default TemperatureLineChart
