/* eslint-disable */
import { UITypes, ApiTypes } from "../../../types"
import { convertDataToOptions } from "../../../utils/convertData"
import { useEffect, useState } from "react"
import styles from "../SelectDynamic/index.module.scss"
import DropdownSelect from "react-dropdown-select"
import classNames from "classnames"
import debounce from "lodash.debounce"
import "../../../assets/libs/select.css"

interface Props {
  label?: string
  placeholder?: string
  name?: string
  values?: UITypes.Option[]
  errorMessage?: string
  valueName: string
  labelName: string
  className?: string
  onChange?: (value: UITypes.Option[]) => void
  data: any
  dropdownHeight?: string
}

export const SelectSubOptions = ({
  label,
  placeholder,
  name,
  values = [],
  errorMessage,
  valueName,
  labelName,
  className,
  dropdownHeight,
  onChange,
  data,
}: Props) => {
  const [searchValue, setSearchValue] = useState("")

  const onSetSearchValue = (data) => {
    const { search } = data?.state
    setSearchValue(search)
  }

  const setDebaunceSearchValue = debounce(onSetSearchValue, 600)

  const onSelectChange = (options) => {
    onChange && onChange(options)
  }

  return (
    <div className={classNames(styles.fieldWrapper, className)}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <div className={classNames(styles.field)}>
        <DropdownSelect
          options={convertDataToOptions(data, valueName, labelName)}
          placeholder={placeholder}
          name={name}
          className={classNames("custom", {
            [styles.error]: errorMessage,
          })}
          values={values}
          searchFn={setDebaunceSearchValue}
          onChange={onSelectChange}
          dropdownHeight={dropdownHeight}
        />
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
