import { PropsWithChildren } from "react"
import { NavLink as ReactRouterNavLink } from "react-router-dom"
import classNames from "classnames"
import styles from "./index.module.scss"

interface Props extends PropsWithChildren {
  to: string
  className?: string
  activeClassName?: string
  disabled?: boolean
  onClick?: () => void
}

export const NavLink = ({ to, className, activeClassName, children, disabled, onClick }: Props) => {
  return disabled ? (
    <span onClick={onClick} className={classNames(className, styles.disabled)}>
      {children}
    </span>
  ) : (
    <ReactRouterNavLink
      to={to}
      onClick={onClick}
      className={(navData) => classNames(className, `${navData.isActive && activeClassName}`)}
    >
      {children}
    </ReactRouterNavLink>
  )
}
