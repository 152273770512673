import { Oval } from "react-loader-spinner"
import styles from "./index.module.scss"

interface Props {
  width?: number
  height?: number
}

export const Spinner = ({ width = 28, height = 28 }: Props) => (
  <Oval
    height={height}
    width={width}
    color="#fff"
    wrapperStyle={{}}
    wrapperClass={styles.spinnerWrapper}
    visible={true}
    ariaLabel="oval-loading"
    secondaryColor="#000"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
)
