import styles from "./index.module.scss"
import classNames from "classnames"
import { UITypes } from "../../../types"

interface Props {
  type: UITypes.DeviceWorkStatus | ""
}

export const StatusOfWork = ({ type }: Props) => {
  let label = ""

  switch (type) {
    case "offline":
      label = "Offline"
      break

    case "online":
      label = "Online"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.deviceStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
