import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { ApiTypes, UITypes } from "../../../types"
import { useEffect } from "react"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import {
  setSelectedDeviceForMonitoringReport,
  setSelectedDevicesForPerformanceTest,
} from "../../../store/tempDataSlise"
import { FilterTags } from "../../../components/common/FilterTags"
import { ReactComponent as IconPower } from "../../../assets/svg/icon-power.svg"
import { showPerformanceTestActionsModal, showStatsReportModal } from "../../../store/modalSlise"
import { useLazyGetDevicesQuery } from "../../../store/api/devicesApi"
import { convertDataToMonitoringTableRows } from "../../../utils/convertData"
import styles from "./index.module.scss"

// TABLE HEADER
const getTableHeaderCells = (): UITypes.TableHeaderCell[] => {
  return [
    {
      title: "Email",
      type: "sort-search",
      name: "email",
      size: "middle",
    },
    {
      title: "Status",
      type: "select",
      name: "contractorPerformanceTestStatus",
      size: "small",
    },
    {
      title: "Work status",
      type: "select",
      name: "contractorDeviceWorkStatus",
      size: "small",
    },
    {
      title: "City",
      type: "sort-search",
      name: "city",
      size: "small",
    },
    {
      title: "ZIP code",
      type: "sort-search",
      name: "zip_code",
      size: "small",
    },
    {
      title: "State",
      type: "sort-search",
      name: "state",
      size: "small",
    },
    {
      title: "Customer",
      type: "sort-search",
      name: "homeowner_name",
      size: "middle",
    },
    {
      title: "Device S/N",
      type: "sort-search",
      name: "sn",
      size: "small",
    },
    {
      title: "Device name",
      type: "sort-search",
      size: "middle",
      name: "name",
    },
    {
      title: "Address",
      type: "sort-search",
      name: "address",
    },
    {
      title: "HVAC",
      type: "select",
      size: "small",
      name: "hvac",
    },
    {
      title: "Alerts",
      type: "select",
      size: "small",
      name: "devicesAlerts",
    },
    {
      title: "Membership",
      type: "select",
      name: "devicesMembership",
      size: "small",
    },
    {
      title: "System type",
      type: "select",
      size: "small",
      name: "deviceSystemTypeStatus",
    },
    {
      title: "Accessories",
      type: "select",
      size: "small",
      name: "deviceAccessoriesStatus",
    },
  ]
}

export const Monitoring = () => {
  const dispatch = useAppDispatch()
  const [triggerGetMonitoringData, { isFetching, data }] = useLazyGetDevicesQuery({
    pollingInterval: 30000,
    skipPollingIfUnfocused: true,
  })
  const requestParams = useAppSelector((state) => state.tempData.requestParams)

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetMonitoringData({ phisical_status: "installed", ...params })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams])

  const handleShowActionsModal = (id: number) => {
    const device = data?.data.find((item) => item.id === id)

    dispatch(
      setSelectedDevicesForPerformanceTest({
        isImmediate: true,
        devices: [
          ...[{ id, email: device?.email, sn: device?.sn, system_type: device?.system_type }],
        ] as ApiTypes.Model.PerformanceTestDevice[],
      }),
    )
    dispatch(showPerformanceTestActionsModal(true))
  }

  const handleShowStatsReportModal = (id: number) => {
    const device = data?.data.find((item) => item.id === id)

    dispatch(setSelectedDeviceForMonitoringReport(device))
    dispatch(showStatsReportModal(true))
  }

  return (
    <ContentWrapper title="Monitoring Page">
      <nav className={styles.tableNavWrapper}>
        <div>
          {/* <ButtonNew onClick={() => {}} color="greyBordered">
            <IconPower />
            Reboot
          </ButtonNew> */}
        </div>
        <div className={styles.tableNavButtonsWrapper}>
          {/* <ButtonNew className={styles.tableNavItem} onClick={() => {}} color="blueBordered">
            Export
          </ButtonNew> */}
        </div>
      </nav>
      <FilterTags />
      <Table
        tableName="monitoring"
        headerCells={getTableHeaderCells()}
        bodyRows={convertDataToMonitoringTableRows(data?.data)}
        dynamicNavHandler1={handleShowActionsModal}
        dynamicNavHandler2={handleShowStatsReportModal}
        isLoading={isFetching && data === undefined}
      />
      <Pagination meta={data?.meta} />
    </ContentWrapper>
  )
}
