import { ModalLayout } from "../../layouts/ModalLayout"
import { ReactComponent as IconCancel } from "../../assets/svg/icon-close.svg"
import styles from "./index.module.scss"
import { useAppSelector } from "../../store/hooks"
import { ApiTypes } from "../../types"
import { useSchedulePerformanceTestMutation } from "../../store/api/performanceTestApi"
import { useDispatch } from "react-redux"
import { showPerformanceTestConfirmModal, showPerformanceTestFailModal } from "../../store/modalSlise"
import { setModalContent } from "../../store/tempDataSlise"

export const PerformanceTestCancelModal = ({ onCancel }) => {
  const dispatch = useDispatch()
  const { devices } = useAppSelector((state) => state.tempData.selectedDevicesForPerformanceTest)
  const [triggerSchedulePerformanceTest] = useSchedulePerformanceTestMutation()

  const handleCancelPerformanceTest = async () => {
    const cancelData: ApiTypes.Req.SchedulePerformanceTest = {
      action: "cancel",
      label: "",
      devices: devices.map((device) => String(device.sn)),
    }

    try {
      onCancel()

      const res = await triggerSchedulePerformanceTest(cancelData)

      if (res?.error) {
        dispatch(
          setModalContent({
            title: "Unable to cancel the Performance Test",
            content: "There was an error while canceling the performance test. Please try again.",
          }),
        )
        dispatch(showPerformanceTestFailModal(true))
      } else {
        dispatch(
          setModalContent({
            title: "Successfully canceled",
            content: "The Performance Test(s) for the selected device(s) is cancelled.",
          }),
        )
        dispatch(showPerformanceTestConfirmModal(true))
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ModalLayout width="454px" onCancel={onCancel} onConfirm={handleCancelPerformanceTest} confirmButtonColor="red">
        <div className={styles.modalBody}>
          <div className={styles.iconWrapper}>
            <IconCancel />
          </div>
          <h3>Cancel Performance Test</h3>
          <p>Are you sure you want to cancel the Performance Test for the</p>
          <p>selected devices?</p>
        </div>
      </ModalLayout>
    </>
  )
}
