import { useState, useEffect } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { DoughnutChart } from "../../common/DoughnutChart"
import classNames from "classnames"
// import { Spinner } from "../../common/Spinner"

const initialState = {
  pending: 0,
  confirmed: 0,
  declined: 0,
  failed: 0,
}

export const WidgetConfirmationStatuses = () => {
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    setWidgetData({
      confirmed: 1,
      pending: 2,
      declined: 3,
      failed: 4,
    })
  }, [])

  return (
    <WidgetLayout title="Confirmation Statuses " className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          // [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <DoughnutChart values={Object.values(widgetData)} colors={["#43E0F8", "#FCD34D", "#94A3B8", "#EF4444"]} />
            <div className={styles.widgetMainValue}>
              <span className={styles.widgetNote}>Devices</span>
              <div className={styles.widgetValueWrapper}>{0}</div>
            </div>
          </div>
          <div className={styles.chartNotesWrapper}>
            <div className={classNames(styles.chartNote, styles.confirmed)}>
              <span>Confirmed</span>
              <span className={styles.chartNoteValue}>{widgetData?.confirmed}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.pending)}>
              <span>Pending</span>
              <span className={styles.chartNoteValue}>{widgetData?.pending}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.declined)}>
              <span>Declined</span>
              <span className={styles.chartNoteValue}>{widgetData?.declined}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.failed)}>
              <span>Failed</span>
              <span className={styles.chartNoteValue}>{widgetData?.failed}</span>
            </div>
          </div>
        </div>
        {/* {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )} */}
      </div>
    </WidgetLayout>
  )
}
