import styles from "../../../assets/scss/index.module.scss"
import logo from "../../../assets/svg/logo-white.svg"
import { Link } from "react-router-dom"
import { NavLink } from "../../common/NavLink"
import { UserMenu } from "../../common/UserMenu"
import classNames from "classnames"
import { useState, useEffect } from "react"
import { ReactComponent as IconOrders } from "../../../assets/svg/menu-orders.svg"
import { ReactComponent as IconDevice } from "../../../assets/svg/menu-device.svg"
import { Hamburger } from "../../common/Hamburger"
import { useLazyGetNewOrdersQuantityQuery } from "../../../store/api/ordersApi"

export const WerehouseTopbar = () => {
  const [triggerGetNewOrdersQuantity, { data }] = useLazyGetNewOrdersQuantityQuery()
  const [isSticky, setSticky] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const closeMenu = () => {
    setOpen(false)
  }

  const handleScroll = () => {
    const distance = window.scrollY

    if (distance > 0 && !isSticky) {
      setSticky(true)
    } else if (distance === 0 && isSticky) {
      setSticky(false)
    }
  }

  useEffect(() => {
    const setStickyOnScroll = () => handleScroll()

    window.addEventListener("scroll", setStickyOnScroll)

    return () => {
      window.removeEventListener("scroll", setStickyOnScroll)
    }
  }, [isSticky])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetNewOrdersQuantity()
      } catch (error) {
        console.error(error)
      }
    }

    const intervalId = setInterval(fetchData, 60000)
    fetchData()
    return () => clearInterval(intervalId)
  }, [])

  return (
    <header className={classNames(styles.topbar, { [styles.sticky]: isSticky })}>
      <Link className={styles.logoWrapper} to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>
      <nav className={classNames(styles.menuWrapper, { [styles.opened]: isOpen })}>
        <NavLink to="/wh-orders" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconOrders className={styles.menuIcon} />
          Orders
          {Boolean(data?.data?.quantity) && <span className={styles.redDot}>{data?.data?.quantity}</span>}
        </NavLink>
        <NavLink to="/wh-devices" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconDevice className={classNames(styles.menuIconStroke)} />
          Devices
        </NavLink>
      </nav>
      <Hamburger onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
      <UserMenu />
    </header>
  )
}
