import styles from "./index.module.scss"
import classNames from "classnames"

interface Props {
  type: string
}

export const StatusOfMessageReport = ({ type }: Props) => {
  let label = ""

  switch (type) {
    case "pending":
      label = "Pending"
      break

    case "failed":
      label = "Failed"
      break

    case "delivered":
      label = "Delivered"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.alertStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
