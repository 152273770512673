import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconDisableContractor } from "../../assets/svg/modal-icon-disable-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useAppSelector } from "../../store/hooks"
import { useLazyEditContractorQuery, useLazyGetContractorsQuery } from "../../store/api/contractorsApi"
import { toast } from "react-toastify"

interface Props {
  onCancel: () => void
}

export const DisableContractorModal = ({ onCancel }: Props) => {
  const contractor = useAppSelector((state) => state.tempData.contractor)
  const [triggerEditContractor, { isFetching }] = useLazyEditContractorQuery()
  const [triggerGetContractors] = useLazyGetContractorsQuery()

  const onDisable = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerEditContractor({
        data: {
          is_enable: false,
        },
        id: contractor?.id,
      } as any)

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Contractor has been successfully disabled!")
        triggerGetContractors({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="490px"
          cancelButtonText="Cancel"
          confirmButtonText="Disable"
          confirmButtonColor="orange"
          isFetching={isFetching}
          onConfirm={onDisable}
          onCancel={onCancel}
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconDisableContractor />
            <h3 className={styles.modalTitle}>Disable contractor</h3>
            <div className={styles.modalMessage}>Are you sure you want to disable the selected contractor?</div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
