import styles from "../../assets/scss/index.module.scss"
import { FormEvent, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconEditUser } from "../../assets/svg/modal-icon-edit-user.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { SelectStatic } from "../../components/form/SelectStatic"
import { UITypes } from "../../types"
import { ToggleButton } from "../../components/form/ToggleButton"
import { useAppSelector } from "../../store/hooks"
import { validationInitialState, onFormValidate, editUserSchema } from "../../utils/onValidate"
import { useLazyEditUserQuery, useLazyGetUsersQuery } from "../../store/api/adminApi"
import { toast } from "react-toastify"
import { transformString } from "../../utils/transformString"
import { checkRoleType } from "../../utils/checkRoleType"
import { InputPhone } from "../../components/form/InputPhone"

const inputValuesInitialState: UITypes.User = {
  fullName: "",
  email: "",
  phone: "",
  useRole: [],
  isDisable: undefined,
}

interface Props {
  onCancel: () => void
}

export const EditUserModal = ({ onCancel }: Props) => {
  const [triggerEditUser, { isFetching }] = useLazyEditUserQuery()
  const [triggerGetUsers] = useLazyGetUsersQuery()
  const editableUser = useAppSelector((state) => state.tempData.editableUser)
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const prepareData = () => {
    const data = {
      full_name: inputValues.fullName,
      phone: inputValues.phone,
      email: inputValues.email,
      is_enabled: !inputValues.isDisable,
    }

    return data
  }

  const sendRequest = async () => {
    try {
      const res: any = await triggerEditUser({ body: prepareData() as any, id: editableUser?.id! })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("User has been successfully edited!")
        setInputValues(inputValuesInitialState)
        triggerGetUsers({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, editUserSchema, setError)) {
      sendRequest()
    }
  }

  const onCancelClick = () => {
    onCancel()
  }

  useEffect(() => {
    setInputValues({
      fullName: editableUser?.full_name,
      email: editableUser?.email,
      phone: editableUser?.phone,
      useRole: [
        {
          value: editableUser?.role?.id as number,
          label: transformString(editableUser?.role?.type as string),
        },
      ],
      isDisable: editableUser?.is_enabled === "1" ? false : true,
    })
  }, [editableUser])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          isFetching={isFetching}
        >
          <ModalHeader title="Edit user">
            <IconEditUser />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Full name"
              placeholder="Enter full name"
              name="fullName"
              value={inputValues.fullName}
              onChange={onInputChange}
              errorMessage={error.field === "fullName" ? error.message : ""}
            />
            <InputNew
              label="Email"
              placeholder="Enter Email"
              name="email"
              disabled
              value={inputValues.email}
              onChange={onInputChange}
              errorMessage={error.field === "email" ? error.message : ""}
            />
            <InputPhone
              label="Phone number"
              placeholder="Enter phone"
              name="phone"
              value={inputValues.phone}
              onChange={(value) => setInputValues({ ...inputValues, ...{ phone: value } })}
              errorMessage={error.field === "phone" ? error.message : ""}
            />
            <SelectStatic label="Role" options={[]} name="userRole" values={inputValues.useRole} disabled />
            {checkRoleType(editableUser?.role?.id) !== "vendor-admin" && (
              <div className={styles.toggleFieldWrapper}>
                <ToggleButton
                  id="isDisable"
                  isReverse
                  name="isDisable"
                  isChecked={inputValues.isDisable}
                  onChange={onInputChange}
                >
                  Disable user
                </ToggleButton>
              </div>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
