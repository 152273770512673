import { useEffect, useState } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import classNames from "classnames"
import { Spinner } from "../../common/Spinner"
import { useLazyGetWarehouseWidgetInfoQuery } from "../../../store/api/vendorApi"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { setAdditionalRequestParams } from "../../../store/tempDataSlise"
import { v4 as uuidv4 } from "uuid"

export const WidgetWarehouseInformation = () => {
  const [triggerGetWarehouses, { isFetching, data }] = useLazyGetWarehouseWidgetInfoQuery()
  const dispatch = useAppDispatch()
  const requestParams = useAppSelector((state) => state.tempData.additionalRequestParams)

  useEffect(() => {
    triggerGetWarehouses(requestParams || {})
  }, [requestParams])

  useEffect(
    () => () => {
      dispatch(setAdditionalRequestParams(null))
    },
    [],
  )

  return (
    <WidgetLayout title="Warehouse Information" pagination={data?.meta} className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        {data?.data.map((raw) => {
          return (
            <div className={classNames(styles.widgetBlock)} key={uuidv4()}>
              <header className={classNames(styles.widgetBlockHeader)}>{raw.name}</header>
              <div className={classNames(styles.widgetBlockContent)}>
                <div className={classNames(styles.widgetBlockTotal)}>
                  <p>{raw.total_count}</p>
                  <p>DEVICES</p>
                </div>
                <div className={classNames(styles.widgetBlockAvailable)}>
                  <span>AVAILABLE</span>
                  <span>{raw.available_count}</span>
                </div>
                <div className={classNames(styles.widgetBlockPacked)}>
                  <span>PACKED</span>
                  <span>{raw.packed_count}</span>
                </div>
              </div>
            </div>
          )
        })}
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
