import { useDispatch } from "react-redux"
import classNames from "classnames"
import { ApiTypes, UITypes } from "../../../types"
import { useLazyGetPerformanceTestReportQuery } from "../../../store/api/performanceTestApi"
import styles from "./index.module.scss"
import { setPerformanceTestReport } from "../../../store/tempDataSlise"
import { showPerformanceTestReportModal } from "../../../store/modalSlise"

interface Props {
  type: UITypes.PerformanceTestStatus
  currentItem?: ApiTypes.Model.Device
}

export const StatusOfPerformanceTest = ({ type, currentItem }: Props) => {
  const dispatch = useDispatch()
  const [triggerGetPerformanceTestReport] = useLazyGetPerformanceTestReportQuery()

  if (!type) return null

  const handleTableRowClick = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!["passed", "issue"].includes(type) || !currentItem) {
      return
    }

    e.stopPropagation()

    const res = await triggerGetPerformanceTestReport(currentItem?.perftest.perftest_result_id)
    if (res.data) {
      dispatch(
        setPerformanceTestReport({
          ...res?.data,
          customer: currentItem?.homeowner_name,
          zip_code: currentItem?.zip?.code,
          where_installed_name: currentItem?.residence_type?.name,
        }),
      )
      dispatch(showPerformanceTestReportModal(true))
    }
  }

  return (
    <span
      className={classNames(styles.performanceTestStatus, styles[type], {
        [styles.clickable]: ["passed", "issue"].includes(type),
      })}
      onClick={handleTableRowClick}
    >
      {type}
    </span>
  )
}
