import styles from "./index.module.scss"
import classNames from "classnames"

interface Props {
  type: string
}

export const StatusOfWHOrder = ({ type }: Props) => {
  let label = ""

  switch (type) {
    case "new":
      label = "NEW"
      break

    case "pending":
      label = "PENDING"
      break

    case "denied":
      label = "DENIED"
      break

    case "confirmed":
      label = "CONFIRMED"
      break

    case "packed":
      label = "PACKED"
      break

    case "shipping":
      label = "SHIPPING"
      break

    case "archived":
      label = "ARCHIVED"
      break

    case "canceled":
      label = "CANCELED"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.alertStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
