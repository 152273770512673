import { useEffect, useState } from "react"
import { useLazyGetPerformanceTestWidgetDataQuery } from "../../../store/api/performanceTestApi"
import classNames from "classnames"
import { Spinner } from "../../common/Spinner"
import { BarChart } from "../../common/BarChart"
import styles from "./index.module.scss"

const initialState = {
  scheduled: 0,
  rescheduled: 0,
  passed: 0,
  issue: 0,
  running: 0,
}

export const WidgetPerformanceTest = () => {
  const [triggerGetPerformanceTestWidgetData, { isFetching }] = useLazyGetPerformanceTestWidgetDataQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchPerformanceTestWidgetData = async () => {
      try {
        const res = await triggerGetPerformanceTestWidgetData()
        if (res?.data) {
          setWidgetData({
            scheduled: res?.data?.scheduled,
            rescheduled: res?.data?.rescheduled,
            passed: res?.data?.passed,
            issue: res?.data?.issue,
            running: res?.data?.running,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchPerformanceTestWidgetData()
  }, [])

  return (
    <div className={classNames(styles.perfTestWidgetWrapper)}>
      <header className={styles.widgetHeader}>
        <h3 className={styles.widgetTitle}>Performance Test Statuses</h3>
      </header>
      <div className={styles.chartWrapper}>
        <BarChart
          items={[
            {
              color: "#43E0F8",
              value: widgetData.scheduled,
            },
            {
              color: "#3b82f6",
              value: widgetData.rescheduled,
            },
            {
              color: "#f59e0b",
              value: widgetData.running,
            },
            {
              color: "#22C55E",
              value: widgetData.passed,
            },
            {
              color: "#EF4444",
              value: widgetData.issue,
            },
          ]}
        />
        <div className={styles.indicatorsWrapper}>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#43E0F8",
              }}
            />
            Scheduled
            <i>{widgetData.scheduled}</i>
          </span>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#3B82F6",
              }}
            />
            Rescheduled
            <i>{widgetData.rescheduled}</i>
          </span>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#f59e0b",
              }}
            />
            Running
            <i>{widgetData.running}</i>
          </span>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#22C55E",
              }}
            />
            Passed
            <i>{widgetData.passed}</i>
          </span>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#EF4444",
              }}
            />
            Issue
            <i>{widgetData.issue}</i>
          </span>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
