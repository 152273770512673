import styles from "../../assets/scss/index.module.scss"
import { FormEvent, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconContractor } from "../../assets/svg/modal-icon-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { showAddContractorModal1, showAddContractorModal2 } from "../../store/modalSlise"
import { setCurrentZip, setContractor } from "../../store/tempDataSlise"
import { UITypes } from "../../types"
import { validationInitialState, onFormValidate, addContractor1Schema } from "../../utils/onValidate"
import { SelectDynamic } from "../../components/form/SelectDynamic"
import { useLazyGetCitiesQuery, useLazyGetZipCodesQuery, useLazyGetStatesQuery } from "../../store/api/dictionaryApi"
import { convertDataToOptions } from "../../utils/convertData"

const inputValuesInitialState: UITypes.Contractor = {
  name: "",
  brand: "",
  address1: "",
  address2: "",
  zip: [],
  state: [],
  city: [],
}

interface Props {
  onCancel: () => void
}

export const AddContractorModal1 = ({ onCancel }: Props) => {
  const contractor = useAppSelector((state) => state.tempData.contractor)
  const dispatch = useAppDispatch()
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState, ...contractor })
  const [error, setError] = useState(validationInitialState)
  const currentZip = useAppSelector((state) => state.tempData.currentZip)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()

    const data = {
      name: inputValues.name,
      brand: inputValues.brand,
      address1: inputValues.address1,
      address2: inputValues.address2,
      zip: inputValues.zip?.length ? inputValues.zip[0].value : "",
      state: inputValues.state?.length ? inputValues.state[0].value : "",
      city: inputValues.city?.length ? inputValues.city[0].value : "",
    }

    if (onFormValidate(data, addContractor1Schema, setError)) {
      dispatch(setContractor({ ...contractor, ...inputValues }))
      dispatch(showAddContractorModal1(false))
      dispatch(showAddContractorModal2(true))
    }
  }

  const onCancelClick = () => {
    dispatch(setCurrentZip(undefined))
    onCancel()
  }

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")

      if (city && state) {
        setInputValues({
          ...inputValues,
          ...{
            city,
            state,
          },
        })
      }
    }
  }, [currentZip])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Next"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          confirmButtonType="submit"
        >
          <ModalHeader title="Add Contractor" subtitle={contractor?.isServiceTitan ? "Step 1/3" : "Step 1/2"}>
            <IconContractor />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Company name"
              placeholder="Enter company name"
              name="name"
              value={inputValues.name}
              onChange={onInputChange}
              errorMessage={error.field === "name" ? error.message : ""}
            />
            <InputNew
              label="Brand name"
              placeholder="Enter brand name"
              name="brand"
              value={inputValues.brand}
              onChange={onInputChange}
              errorMessage={error.field === "brand" ? error.message : ""}
            />
            <InputNew
              label="Address 1"
              placeholder="Enter address "
              name="address1"
              value={inputValues.address1}
              onChange={onInputChange}
              errorMessage={error.field === "address1" ? error.message : ""}
            />
            <InputNew
              label="Address 2"
              placeholder="Enter address "
              name="address2"
              value={inputValues.address2}
              onChange={onInputChange}
              errorMessage={error.field === "address2" ? error.message : ""}
            />
            <div className={styles.formCellsWrapper}>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="Zip Code"
                  valueName="id"
                  labelName="code"
                  searchFieldName="code"
                  name="zip"
                  values={inputValues.zip}
                  useLazyQuery={useLazyGetZipCodesQuery}
                  placeholder="Enter Zip Code"
                  onChange={(value) => setInputValues({ ...inputValues, ...{ zip: value } })}
                  errorMessage={error.field === "zip" ? error.message : ""}
                />
              </div>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="State"
                  valueName="id"
                  labelName="name"
                  placeholder="Enter state"
                  name="state"
                  useLazyQuery={useLazyGetStatesQuery}
                  values={inputValues.state}
                  onChange={(value) => {
                    setInputValues({ ...inputValues, ...{ state: value } })
                  }}
                  errorMessage={error.field === "state" ? error.message : ""}
                />
              </div>
            </div>
            <SelectDynamic
              label="City"
              valueName="id"
              labelName="name"
              name="city"
              useLazyQuery={useLazyGetCitiesQuery}
              placeholder="Enter city"
              values={inputValues.city}
              onChange={(value) => setInputValues({ ...inputValues, ...{ city: value } })}
              errorMessage={error.field === "city" ? error.message : ""}
            />
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
