import { Tooltip as ReactTooltip } from "react-tooltip"
import { PropsWithChildren } from "react"
import styles from "./index.module.scss"
import "./tooltip.css"

interface Props extends PropsWithChildren {
  id: string
  text: string
  className?: string
}
export const Tooltip = ({ children, id, text, className }: Props) => {
  return (
    <div className={className}>
      <span data-tooltip-id={id} className={styles.tooltipText}>
        {text}
      </span>
      <ReactTooltip classNameArrow={styles.tooltipArrow} className={styles.tooltip} place="top-start" id={id}>
        {children}
      </ReactTooltip>
    </div>
  )
}
