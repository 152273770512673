import { useState, useMemo } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconNewOrder } from "../../assets/svg/modal-icon-new-order.svg"
import { ReactComponent as IconMinus } from "../../assets/svg/icon-minus.svg"
import { ReactComponent as IconPlus } from "../../assets/svg/icon-plus.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import styles from "./index.module.scss"
import { toast } from "react-toastify"
import { useLazyEditNewOrderQuery, useLazyGetOrdersQuery } from "../../store/api/ordersApi"
import { useAppSelector } from "../../store/hooks"

interface Props {
  onCancel: () => void
}

export const EditOrderModal = ({ onCancel }: Props) => {
  const [triggerEditNewOrder, { isFetching }] = useLazyEditNewOrderQuery()
  const [triggerGetOrders] = useLazyGetOrdersQuery()
  const currentOrder = useAppSelector((state) => state.tempData.currentOrder)
  const [quantity, setQuantity] = useState(currentOrder?.quantity!)

  const onInputChange = (value: number) => {
    setQuantity(value < 1 ? 1 : value)
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerEditNewOrder({
        data: { quantity: quantity },
        id: currentOrder?.id!,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Order has been successfully updated!")
        triggerGetOrders({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="570px" onConfirm={onSubmit} onCancel={onCancel} isFetching={isFetching}>
          <ModalHeader title="Edit order" subtitle="You can update the number of Nuve Thermostats.">
            <IconNewOrder />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.orderDetails}>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Contractor ID</span>
                <span className={styles.orderDetailsValue}>{currentOrder?.contractor_id}</span>
              </div>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Creation date</span>
                <span className={styles.orderDetailsValue}>{currentOrder?.create_date}</span>
              </div>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Company name</span>
                <span className={styles.orderDetailsValue}>{currentOrder?.company_name}</span>
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.deviceWrapper}>
              <div className={styles.deviceQtyWrapper}>
                <span className={styles.deviceName}>Nuve Thermostats</span>
                <span className={styles.deviceQty}>Qty: {quantity}</span>
              </div>
              <div className={styles.orderInputWrapper}>
                <span className={styles.orderInputButton} onClick={() => onInputChange(quantity - 1)}>
                  <IconMinus />
                </span>
                <input
                  value={quantity}
                  type="number"
                  className={styles.orderInput}
                  onChange={(event) => onInputChange(Number(event?.currentTarget?.value))}
                />
                <span className={styles.orderInputButton} onClick={() => onInputChange(quantity + 1)}>
                  <IconPlus />
                </span>
              </div>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
