import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"

export const profileApi = createApi({
  reducerPath: "profile",
  tagTypes: ["profile"],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getUserProfile: builder.query<{ data: ApiTypes.Model.UserProfile }, void>({
      query: () => {
        return {
          url: `/who-am-i`,
          method: "GET",
        }
      },
    }),
  }),
})

export const { useLazyGetUserProfileQuery } = profileApi

export const getTechnicians = profileApi.endpoints.getUserProfile
