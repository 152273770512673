import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"
import { ApiTypes, UITypes } from "../types"
import { transformText } from "./transformText"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"
import { transformString } from "./transformString"
import { parseDateStringToUTCString } from "./parseDateStringToUTCString"
import { reorderTabbleBodyCells } from "./reorderTabbleColumns"
import { parseSystemType } from "./parseSystemType"

export const convertStatusIDToOrderStatus = (id: number): UITypes.OrderStatus => {
  /*
    CASE New = 1;
    CASE Pending = 2;
    CASE Denied = 3;
    CASE Confirmed = 4;
    CASE Packed = 5;
    CASE Shipping = 6;
    CASE Archived (Fulfilled) = 7;
  */

  switch (id) {
    case 1:
      return "new"
    case 2:
      return "pending"
    case 3:
      return "denied"
    case 4:
      return "confirmed"
    case 5:
      return "packed"
    case 6:
      return "shipping"
    case 7:
      return "fulfilled"
    default:
      return ""
  }
}

export const convertStatusesToOptions = (data?: ApiTypes.Res.Status[]): UITypes.Option[] => {
  if (!data?.length) return []

  return data.map((item) => {
    return {
      value: String(item.id),
      label: item.name,
    }
  })
}

export const convertDataToOptions = (data: any, valueName: string, labelName: string): UITypes.Option[] => {
  if (!data?.data?.length) return []

  return data.data.map((item) => {
    return {
      value: item && item[valueName],
      label: item && item[labelName],
    }
  })
}

export const convertStringArrayToOptions = (data: string[]): UITypes.Option[] => [
  ...data.map((item) => ({
    value: item,
    label: item,
  })),
]

// TABLES

export const convertTechniciansToTableRows = (data?: ApiTypes.Model.Technician[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, full_name, username, installed_devices }) =>
      ({
        cells: [
          { value: id }, // Technician (ID number)
          { value: full_name, size: "large" }, // Full Name
          {
            value: username,
            size: "big",
          }, // Username
          { value: installed_devices }, // Installed Devices
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertVSMOrdersToTableRows = (
  data?: ApiTypes.Model.VSManagerOrder[],
  headerCells?: UITypes.TableHeaderCell[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []
  return data.map(
    ({
      id,
      create_date = "",
      quantity,
      status,
      email,
      phone_number,
      brand_name,
      company_name,
      warehouse,
      status_updated,
    }) =>
      ({
        cells: reorderTabbleBodyCells(
          [
            {
              type: "checkbox",
              name: "checkbox",
            }, // Checkbox
            {
              value: id,
              name: "id",
            }, // Order ID
            {
              value: status,
              type: "status",
              name: "orderStatus",
            }, // Status
            {
              value: company_name,
              name: "company_name",
            }, // Company Name
            {
              value: create_date ? dayjs(create_date).format("YYYY-MM-DD") : null,
              name: "create_date",
            }, // Creation Date
            {
              value: quantity,
              name: "quantity",
            }, // Quantity
            {
              value: warehouse,
              name: "warehouse",
            }, // Warehouse
            {
              value: phone_number,
              name: "phone_number",
            }, // Phone Number
            {
              value: email,
              name: "email",
            }, // Email
            {
              value: status_updated ? dayjs(status_updated).format("YYYY-MM-DD") : null,
              name: "status_updated",
            }, //
          ],
          headerCells,
        ),
        id,
        status,
        isEnable: status === "new" ? true : false,
      }) as UITypes.TableBodyRow,
  )
}

export const convertOrdersToTableRows = (data?: ApiTypes.Model.Order[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, create_date, quantity, warehouse, status }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox
          { value: id, size: "middle" }, // Order ID
          { value: dayjs(create_date).format("YYYY-MM-DD"), size: "middle" }, // Creation Date
          { value: quantity, size: "middle" }, // Quantity
          { value: status, type: "status" }, // Status
          { value: warehouse }, // Warehouse
        ],
        id,
        status: status,
      }) as UITypes.TableBodyRow,
  )
}

export const convertClientsToTableRows = (data?: ApiTypes.Model.Client[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, full_name, devices, email, phone_number, address1, zip, city, state, membership }) =>
      ({
        cells: [
          { value: email }, // Email
          {
            value: devices,
            size: "small",
          }, // Devices
          { value: phone_number }, // Phone
          { value: full_name }, // Full Name
          {
            value: id,
            size: "middle",
          }, // Customer ID
          {
            value: membership,
            size: "small",
          }, // Membership
        ],
        id,
        isEnable: devices ? true : false,
      }) as UITypes.TableBodyRow,
  )
}

export const convertContractorsToTableRows = (
  data?: ApiTypes.Model.Contractor[],
  headerCells?: UITypes.TableHeaderCell[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, name, brand, purchased_devices, installed_devices, inventory_devices, is_enabled, state }) =>
      ({
        cells: reorderTabbleBodyCells(
          [
            {
              name: "name",
              value: name,
            }, // Company name
            {
              name: "state",
              value: state?.name,
            }, // State
            {
              name: "brand",
              value: brand,
            }, // Brand Name
            {
              name: "purchased",
              value: purchased_devices,
            }, // Purchased Devices
            {
              name: "installed",
              value: installed_devices,
            }, // Installed Devices
            {
              name: "inventory",
              value: inventory_devices,
            }, // Inventory
            {
              name: "contractorStatus",
              value: is_enabled,
              type: "boolean",
            }, // Status
          ],
          headerCells,
        ),
        id: Number(id),
        isEnabled: is_enabled,
      }) as UITypes.TableBodyRow,
  )
}

export const convertAlertsToTableRows = (data?: ApiTypes.Model.Alert[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, sn, address1, city, state, date, status, type }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox
          { value: transformText(type) }, // Alerts
          { value: date }, // Date
          { value: sn }, // Device S/N
          {
            value: status,
            type: "alert-status",
          }, // Status
          { value: address1 }, // Address
          { value: city }, // City
          { value: state }, // State
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertMessageTemplatesToTableRows = (data?: ApiTypes.Model.MessageTemplate[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, title, body, created_at }) =>
      ({
        cells: [
          {
            value: title,
            // size: "big"
          }, // Title
          { value: dayjs(created_at).format("MM-DD-YYYY"), size: "small" }, // Create date
          {
            value: body,
            size: "extra-big",
            type: "markdown",
          }, // Message
          { value: id, size: "tiny" }, // ID
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertTemplatesLibraryToTableRows = (
  data?: ApiTypes.Model.TemplatesLibrary[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  const result = data?.map((group) => {
    const groupObject = {
      cells: [
        {
          value: group?.name,
          type: "group-separator",
          size: "big",
        },
        {
          value: "",
          type: "group-separator",
          size: "extra-big",
        },
        {
          value: "",
          size: "tiny",
          type: "group-separator",
        },
      ],
      id: group?.id + 1000,
      isEnable: false,
    }

    const itemsArray: UITypes.TableBodyRow[] = []

    if (group?.items?.length) {
      group?.items?.forEach((items) => {
        itemsArray.push({
          cells: [
            {
              value: items?.id,
              size: "tiny",
            },
            {
              value: items?.title,
              size: "big",
            },
            {
              value: items?.description,
              size: "extra-big",
              type: "markdown",
            },
          ],
          id: items?.id,
        })
      })
    }
    return [...[groupObject], ...itemsArray]
  })

  return result.flat(1) as UITypes.TableBodyRow[]
}

export const convertWarehousesToTableRows = (data?: ApiTypes.Model.Warehouse[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, name, address, phone_number, zip, state, devices_qty, managers }) =>
      ({
        cells: [
          {
            value: name,
          }, // Warehouse
          {
            value: devices_qty,
            size: "small",
          }, // Devices Qty
          {
            value: address,
          }, // Address
          {
            value: zip?.code,
            size: "small",
          }, // Zip code
          {
            value: state?.name,
            size: "small",
          }, // State
          {
            value: phone_number,
          }, // Phone
          {
            value: managers,
          }, // Manager
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDevicesToTableRows = (
  data?: ApiTypes.Model.Device[],
  headerCells?: UITypes.TableHeaderCell[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      homeowner_name,
      city,
      zip,
      state,
      membership,
      accessories,
      residence_type,
      hvac,
      system_type,
      activation_date,
      email,
      option,
    }) =>
      ({
        cells: reorderTabbleBodyCells(
          [
            {
              type: "checkbox",
              name: "checkbox",
            }, // Checkbox,
            {
              name: "email",
              value: email,
              size: "middle",
            }, // Email
            {
              name: "sn",
              value: sn,
              size: "small",
            }, // Device SN
            {
              name: "city",
              value: city?.name,
              size: "small",
            }, // City
            {
              name: "zip_code",
              value: zip?.code,
              size: "small",
            }, // Zip code
            {
              name: "state",
              value: state?.name,
              size: "small",
            }, // State
            {
              name: "option",
              value: option ? capitalizeFirstLetter(option).replace("_", "-") : "",
              size: "small",
              type: "opt-in-out",
            }, // Opt in/out
            {
              name: "activation_date",
              value: activation_date,
              size: "middle",
            }, // Activation date
            {
              name: "homeowner_name",
              value: homeowner_name,
              // size:"small"
            }, // Customer name
            {
              name: "membership",
              value: membership,
              size: "small",
            }, // Membership
            {
              name: "residence_type",
              value: residence_type?.name,
              size: "middle",
            }, // Residence type
            {
              name: "deviceSystemTypeStatus",
              value: system_type,
              size: "small",
            }, // System type
            {
              name: "deviceAccessoriesStatus",
              value: accessories,
              size: "small",
            }, // Accessories
            {
              name: "hvac",
              value: hvac,
              size: "small",
            }, // System age
          ],
          headerCells,
        ),
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDevicesToDashboardTableRows = (
  data?: ApiTypes.Model.Device[],
  headerCells?: UITypes.TableHeaderCell[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      homeowner_name,
      city,
      state,
      zip,
      address,
      membership,
      alerts,
      accessories,
      system_age,
      system_type,
      activation_date,
      phisical_status,
      work_status,
      hvac,
    }) =>
      ({
        cells: reorderTabbleBodyCells(
          [
            {
              type: "checkbox",
              name: "checkbox",
            }, // Checkbox,
            {
              value: sn,
              name: "sn",
              size: "small",
            }, // Device S/N
            {
              value: phisical_status,
              size: "small",
              type: "devices-physical-status",
              name: "contractorDevicePhysicalStatus",
            }, // Status
            {
              // title: "Work Status",
              size: "small",
              value: work_status,
              type: "devices-work-status",
              name: "contractorDeviceWorkStatus",
            },
            {
              value: homeowner_name,
              name: "homeowner_name",
              size: "middle",
            }, // Customer
            {
              value: zip?.code,
              name: "zip_code",
              size: "small",
            }, // Zip code
            {
              value: activation_date,
              size: "middle",
              name: "activation_date",
            }, // Activation date
            {
              value: `${address ? address : ""} ${state?.name ? state?.name : ""} ${city?.name ? city?.name : ""}`,
              name: "address",
            }, // Address
            {
              value: hvac,
              size: "small",
              name: "hvac",
            }, // hvac
            {
              value: alerts,
              size: "small",
              name: "devicesAlerts",
            }, // Alerts
            {
              value: membership,
              name: "devicesMembership",
              size: "small",
            }, // Membership
            {
              value: parseSystemType(system_type),
              name: "deviceSystemTypeStatus",
              size: "small",
            }, // System type
            {
              value: accessories,
              size: "small",
              name: "deviceAccessoriesStatus",
            }, // Accessories
          ],
          headerCells,
        ),
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDataToPerformanceTestTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map((device) => {
    const {
      id,
      email,
      sn,
      perftest,
      homeowner_name,
      city,
      state,
      zip,
      work_status,
      address,
      hvac,
      alerts,
      membership,
      system_type,
      accessories,
    } = device

    return {
      id,
      item: device,
      cells: [
        { type: "checkbox", disabled: perftest?.status?.toLowerCase() === "running" }, // Checkbox,
        {
          value: email,
          size: "middle",
        }, // Email
        {
          value: perftest?.status?.toLowerCase(),
          size: "small",
          type: "performance-test-status",
        }, // Performance test status
        {
          // title: "Work Status",
          size: "small",
          value: work_status,
          type: "devices-work-status",
        },
        {
          value: perftest?.label,
          size: "small",
          // type: "performance-test-label",
        }, // Label
        {
          value: city?.name,
          size: "small",
        }, // Status
        {
          value: zip?.code,
          size: "small",
        }, // Zip code
        {
          value: state?.name,
          size: "small",
        }, // Zip code
        {
          value: homeowner_name,
          size: "middle",
          type: "performance-test-homeowner",
        }, // Customer
        {
          value: sn,
          size: "small",
        }, // Device S/N
        {
          value: address || "",
        }, // Address
        {
          value: hvac,
          size: "small",
        }, // hvac
        {
          value: alerts,
          size: "small",
        }, // Alerts
        {
          value: membership,
          size: "small",
        }, // Membership
        {
          value: parseSystemType(system_type),
          size: "small",
        }, // System type
        {
          value: accessories,
          size: "small",
        }, // Accessories
      ],
    } as UITypes.TableBodyRow
  })
}

export const convertDataToPerformanceTestHistoryTableRows = (
  data?: ApiTypes.Model.PerformanceTestHistory[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ client, device, perftest }) =>
      ({
        cells: [
          {
            value: client?.email,
            size: "middle",
          }, // Email
          {
            value: perftest?.status?.toLowerCase(),
            size: "small",
            type: "performance-test-status",
          }, // Performance test status
          {
            value: dayjs(parseDateStringToUTCString(perftest.time)).format("YYYY-MM-DD"),
            size: "middle",
          }, // Performnace test date and time
          {
            value: perftest?.label,
            size: "small",
            // type: "performance-test-label",
          }, // Label
          {
            value: device?.city?.name,
            size: "small",
          }, // Status
          {
            value: device?.zip?.code,
            size: "small",
          }, // Zip code
          {
            value: device?.state?.name,
            size: "small",
          }, // Zip code
          {
            value: client?.homeowner_name,
            size: "middle",
            type: "performance-test-homeowner",
          }, // Customer
          {
            value: device?.sn,
            size: "small",
          }, // Device S/N
          {
            value: device?.activation_date,
            size: "middle",
          }, // Activation date
          {
            value: device?.address || "",
          }, // Address
        ],
        isRowClickable: true,
        id: perftest?.perftest_result_id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDataToMonitoringTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map((device) => {
    const {
      id,
      email,
      perftest,
      sn,
      homeowner_name,
      city,
      state,
      zip,
      work_status,
      name,
      address,
      hvac,
      alerts,
      membership,
      system_type,
      accessories,
    } = device

    const perfTestStatus = perftest?.status?.toLowerCase()

    return {
      id,
      item: device,
      cells: [
        {
          value: email,
          size: "middle",
        }, // Email
        {
          value: ["passed", "issue", "running"].includes(perfTestStatus) ? perfTestStatus : null,
          size: "small",
          type: "performance-test-status",
        }, // Performance test status
        {
          // title: "Work Status",
          size: "small",
          value: work_status,
          type: "devices-work-status",
        },
        {
          value: city?.name,
          size: "small",
        }, // Status
        {
          value: zip?.code,
          size: "small",
        }, // Zip code
        {
          value: state?.name,
          size: "small",
        }, // Zip code
        {
          value: homeowner_name,
          size: "middle",
          type: "performance-test-homeowner",
        }, // Customer
        {
          value: sn,
          size: "small",
        }, // Device S/N
        {
          value: name,
          size: "middle",
        },
        {
          value: address || "",
        }, // Address
        {
          value: hvac,
          size: "small",
        }, // hvac
        {
          value: alerts,
          size: "small",
        }, // Alerts
        {
          value: membership,
          size: "small",
        }, // Membership
        {
          value: parseSystemType(system_type),
          size: "small",
        }, // System type
        {
          value: accessories,
          size: "small",
        }, // Accessories
      ],
    } as UITypes.TableBodyRow
  })
}

export const convertMessagesHistoryToTableRows = (data?: ApiTypes.Model.MessageHitory[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, title, body, pending, failed, delivered, date }) =>
      ({
        cells: [
          {
            value: title,
          }, // Title
          {
            value: body,
            type: "markdown",
          }, // Message
          {
            value: date,
          }, // Date
          {
            value: delivered,
            size: "small",
            color: "blue",
          }, // Delivered
          {
            value: pending,
            size: "small",
            color: "orange",
          }, // Pending
          {
            value: failed,
            size: "small",
            color: "red",
          }, // Failed
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertWHManagerOrdersToTableRows = (data?: ApiTypes.Model.WHManagerOrder[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, contractor_id, order_quantity, status, company_name, brand_name }) =>
      ({
        cells: [
          {
            value: id,
            size: "middle",
          }, // Order number
          {
            value: status,
            size: "middle",
            type: "wh-order-status",
          }, // Status
          {
            value: company_name,
          }, // Company name
          {
            value: brand_name,
          }, // Brand name
          {
            value: order_quantity,
            size: "middle",
          }, // Order quantity
          {
            value: contractor_id,
            size: "middle",
          }, // Contractor ID
        ],
        status,
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertWHManagerDevicessToTableRows = (
  data?: ApiTypes.Model.WHManagerDevice[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, sn, input_date, phisical_statue }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox,
          {
            value: sn,
            size: "large",
          }, // Device S/N
          {
            value: input_date,
            size: "large",
          }, // Date of Input
          {
            value: phisical_statue,
            size: "large",
            type: "devices-physical-status",
          }, // Status
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertMessageReportToTableRows = (data?: ApiTypes.Model.MessageReport[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      device_sn,
      device_id,
      filter_history_id,
      client_name,
      city,
      zip_code,
      state,
      activation_date,
      membership,
      residence_type,
      installation_type,
      accessories,
      status,
      system_age,
      system_type,
      message_template_id,
    }) =>
      ({
        cells: [
          {
            // title: "Device S/N",
            size: "small",
            value: device_sn,
          },
          {
            // title: "Customer name",
            size: "middle",
            value: client_name,
          },
          {
            // title: "City",
            size: "small",
            value: city,
          },
          {
            // title: "Zip code",
            size: "small",
            value: zip_code,
          },
          {
            // title: "State",
            size: "small",
            value: state,
          },
          {
            // title: "Activation date",
            size: "middle",
            value: activation_date,
          },
          {
            // title: "Membership",
            size: "small",
            value: membership,
          }, // Membership
          {
            // title: "Residence type",
            size: "middle",
            value: residence_type,
          },
          {
            // title: "System type",
            size: "small",
            value: system_type,
          },
          {
            // title: "Accessories",
            size: "small",
            value: accessories,
          },
          {
            // title: "HVAC",
            size: "small",
            value: installation_type,
          },
          {
            // title: "Status",
            size: "small",
            value: status,
            type: "message-report-status",
          },
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertUsersToTableRows = (data?: ApiTypes.Model.User[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, email, full_name, phone, role, created_by, creation_date }) =>
      ({
        cells: [
          {
            // title: "Name",
            value: full_name,
            size: "middle",
          },
          {
            // title: "Role",
            value: transformString(role?.type),
            size: "middle",
          },
          {
            // title: "Email",
            value: email,
          },
          {
            // title: "Phone",
            value: phone,
          },
          {
            // title: "Creation date",
            value: creation_date,
          },
          {
            // title: "Created By",
            value: created_by,
          },
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertValidatedDevicesToState = (data: ApiTypes.Model.InvalideDevice[]): UITypes.InvalidDevice[] => {
  if (!data?.length) return []

  return data.map((item, index) => ({
    sn: item?.sn ? item.sn : "",
    row: item?.row ? item.row : (index += 1),
    uid: item?.uid ? item.uid : uuidv4(),
    errorMessage: item?.error_message ? item?.error_message : "",
  }))
}

export const convertVendorDevicesToTableRows = (
  data?: ApiTypes.Model.VendorDevice[],
  headerCells?: UITypes.TableHeaderCell[],
  callback?: (id: number) => void,
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      work_status,
      phisical_status,
      contractor,
      contractor_name,
      activation_date,
      red_flag,
      red_flag_type,
      homeowner,
      address,
      email,
      phone,
      installation_type,
      device_location,
    }) =>
      ({
        cells: reorderTabbleBodyCells(
          [
            {
              type: "checkbox",
              size: "tiny",
              name: "checkbox",
            },
            {
              // title: "S/N",
              size: "middle",
              value: sn,
              name: "sn",
              callback: () => callback && callback(id),
            },
            {
              // title: "Work Status",
              size: "small",
              value: work_status,
              name: "vendorDeviceWorkStatus",
              type: "devices-work-status",
            },
            {
              // title: "Physical status",
              size: "small",
              value: phisical_status,
              name: "vendorDevicePhysicalStatus",
              type: "devices-physical-status",
            },
            {
              // title: "Contractor name",
              size: "middle",
              name: "contractor_name",
              value: contractor_name,
            },
            {
              // title: "Activation date",
              size: "middle",
              name: "activation_date",
              value: activation_date,
            },
            {
              // title: "Red Flag",
              size: "small",
              name: "vendorDeviceRedFlag",
              value: `${red_flag ? "Yes" : "No"}`,
            },
            {
              // title: "Red Flag Type",
              size: "middle",
              name: "vendorDeviceRedFlagType",
              value: red_flag_type,
            },
            {
              // title: "Customer",
              size: "middle",
              name: "homeowner",
              value: homeowner,
            },
            {
              // title: "Address",
              name: "address",
              value: address,
            },
            {
              // title: "Email",
              name: "email",
              value: email,
              size: "middle",
            },
            {
              // title: "Phone",
              size: "middle",
              value: phone,
              name: "phone",
            },
            {
              // title: "hvac",
              value: installation_type,
              name: "hvac",
              size: "small",
            },
            {
              // title: "Device Location",
              value: device_location,
              name: "device_location",
            },
          ],
          headerCells,
        ),
        id: Number(id),
        isRedFlag: red_flag,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDeviceHistoryLog = (data: ApiTypes.Model.HistoryLog[]): UITypes.HistoryLog[] => {
  if (!data?.length) return []

  return data?.map((item) => ({
    status: item?.status,
    author: item?.author,
    lastUpdate: dayjs(item?.last_update).format("MM/DD/YYYY HH:mm"),
  }))
}

export const convertEditDevicesToState = (data: ApiTypes.Model.Device[]): UITypes.Device[] => {
  if (!data?.length) return []

  const checkInstallationType = (value) => {
    switch (value) {
      case "new":
        return [
          {
            value: "new",
            label: "New",
          },
        ]
      case "existing":
        return [
          {
            value: "existing",
            label: "Replacement",
          },
        ]
      default:
        return []
    }
  }

  return data?.map((item) => {
    return {
      _id: uuidv4(),
      id: item?.id ? item?.id : undefined,
      serialNumber: item?.sn ? item?.sn : "",
      address1: item?.address1 ? item?.address1 : "",
      address2: item?.address2 ? item?.address2 : "",
      zip: convertDataToOptions({ data: [item?.zip] }, "id", "code"),
      state: convertDataToOptions({ data: [item?.state] }, "id", "name"),
      city: convertDataToOptions({ data: [item?.city] }, "id", "name"),
      residentialType: convertDataToOptions({ data: [item?.residence_type] }, "id", "name"),
      deviceLocation: convertDataToOptions({ data: [item?.location] }, "id", "name"),
      deviceName: item?.name ? item?.name : "",
      installationType: checkInstallationType(item.installation_type),
    }
  })
}
