import styles from "./index.module.scss"
import { Doughnut } from "react-chartjs-2"
import { Chart, ArcElement } from "chart.js"
import classNames from "classnames"

Chart.register(ArcElement)

interface Props {
  values: [number, number]
  className?: string
  colors: string[]
}

export const RainbowChart = ({ className, values, colors }: Props) => {
  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 4,
        display: true,
        borderColor: "#fff",
        hoverBorderColor: "#fff",
      },
    ],
  }

  return (
    <div className={classNames(styles.rainbowWrapper, className)}>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          elements: {
            arc: {
              borderRadius: 10,
            },
          },
          rotation: -90,
          circumference: 180,
          cutout: "60%",
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  )
}
