import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes, UITypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"
import { prepareQueryParams } from "../../utils/prepareQueryParams"

export const performanceTestApi = createApi({
  reducerPath: "performanceTests",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["PerformanceTestsList"],
  endpoints: (builder) => ({
    getPerformanceTestWidgetData: builder.query<ApiTypes.Res.PerformanceTestWidgetData, void>({
      query: () => ({
        url: `/perftest/widget`,
        headers: { Accept: "application/json" },
      }),
    }),
    getPerformanceTests: builder.query<ApiTypes.Res.PerformanceTests, UITypes.Params>({
      query: (arg) => ({
        url: `/devices`,
        params: prepareQueryParams(arg),
        headers: { Accept: "application/json" },
      }),
      providesTags: ["PerformanceTestsList"],
    }),
    getPerformanceTestsHistory: builder.query<ApiTypes.Res.PerformanceTestHistory, UITypes.Params>({
      query: (arg) => ({
        url: `/perftest/perftests`,
        params: prepareQueryParams(arg),
        headers: { Accept: "application/json" },
      }),
    }),
    getPerformanceTestLabels: builder.query<ApiTypes.Res.PerformanceTestLabels, void>({
      query: () => ({
        url: `/perftest/labels`,
        headers: { Accept: "application/json" },
      }),
    }),
    schedulePerformanceTest: builder.mutation<void, ApiTypes.Req.SchedulePerformanceTest>({
      query: (data) => ({
        url: `/perftest/schedule`,
        method: "POST",
        body: data,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["PerformanceTestsList"],
    }),
    runDeviceCommand: builder.mutation<void, ApiTypes.Req.MonitoringDeviceCommand>({
      query: (data) => ({
        url: `/monitoring/setCommand`,
        method: "POST",
        body: data,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["PerformanceTestsList"],
    }),
    getPerformanceTestReport: builder.query<ApiTypes.Model.PerformanceTestReport, any>({
      query: (id) => ({
        url: `/perftest/device/${id}`,
        headers: { Accept: "application/json" },
      }),
    }),
  }),
})

export const getPerformanceTestWidgetData = performanceTestApi.endpoints.getPerformanceTestWidgetData

export const getPerformanceTests = performanceTestApi.endpoints.getPerformanceTests

export const getPerformanceTestsHistory = performanceTestApi.endpoints.getPerformanceTestsHistory

export const getPerformanceTestLabels = performanceTestApi.endpoints.getPerformanceTestLabels

export const getPerformanceTestReport = performanceTestApi.endpoints.getPerformanceTestReport

export const {
  useLazyGetPerformanceTestWidgetDataQuery,
  useLazyGetPerformanceTestsQuery,
  useLazyGetPerformanceTestsHistoryQuery,
  useLazyGetPerformanceTestLabelsQuery,
  useSchedulePerformanceTestMutation,
  useRunDeviceCommandMutation,
  useLazyGetPerformanceTestReportQuery,
} = performanceTestApi
