import styles from "./index.module.scss"
import { useState, useEffect } from "react"
import { InputNew } from "../../../components/form/InputNew"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { inputHandler } from "../../../utils/onInputChange"
import { validationInitialState, onFormValidate, warrantySchema } from "../../../utils/onValidate"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { showAlertWarrantyModal } from "../../../store/modalSlise"
import { setWarrantySNs } from "../../../store/tempDataSlise"
import { storageKeys } from "../../../utils/storageKeys"

const inputValuesInitialState = {
  oldSN: "",
  newSN: "",
}

export const Warranty = () => {
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)
  const dispatch = useAppDispatch()
  const warrantySNs = useAppSelector((state) => state.tempData.warrantySNs)

  const onSubmit = async (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, warrantySchema, setError)) {
      dispatch(setWarrantySNs(inputValues))
      dispatch(showAlertWarrantyModal(true))
    }
  }

  useEffect(() => {
    if (!warrantySNs) return
    setInputValues(warrantySNs)
  }, [warrantySNs])

  useEffect(() => {
    const deviceSN = sessionStorage.getItem(storageKeys.technicianSN)
    if (!deviceSN) return
    const scannedValue = JSON.parse(deviceSN)

    if (Array.isArray(scannedValue) && scannedValue?.length) {
      setInputValues({ ...inputValues, ...{ newSN: scannedValue[0] as string } })
    } else {
      setInputValues({ ...inputValues, ...{ newSN: scannedValue } })
    }
  }, [])

  return (
    <div className={styles.screenWrapper}>
      <div className={styles.warrantyWrapper}>
        <form className={styles.formWrapper} onSubmit={onSubmit}>
          <div className={styles.description}>
            To replace your damaged device under warranty, please enter serial number (S/N) in “Old S/N” box below. Once
            you entered correct S/N, click the “Replace” button to proceed with the replacement process.
          </div>
          <div>
            <InputNew
              label="Old S/N"
              placeholder="Input the replaced device's S/N"
              name="oldSN"
              value={inputValues?.oldSN}
              onChange={(event) => inputHandler(event, setInputValues)}
              errorMessage={error.field === "oldSN" ? error.message : ""}
            />
            <InputNew
              label="New S/N"
              name="newSN"
              value={inputValues?.newSN}
              disabled
              onChange={(event) => inputHandler(event, setInputValues)}
              errorMessage={error.field === "newSN" ? error.message : ""}
            />
            <div className={styles.buttonsWrapper}>
              <ButtonNew onClick={onSubmit} color="blue">
                Replace
              </ButtonNew>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
