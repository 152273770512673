import styles from "./index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { UITypes } from "../../../types"
import { toast } from "react-toastify"
import { WidgetVendorAlerts } from "../../../components/widgets/WidgetVendorAlerts"
import { WidgetVendorAlertStatuses } from "../../../components/widgets/WidgetVendorAlertStatuses"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useEffect, useState } from "react"
import { setRequestParams, setCurrentAlert } from "../../../store/tempDataSlise"
import { convertAlertsToTableRows } from "../../../utils/convertData"
import { showAlertsFreezeModal, showAlertsAcknowledgeModal, showAlertsUnfreezeModal } from "../../../store/modalSlise"
import { FilterTags } from "../../../components/common/FilterTags"
import { useLazyGetAdminAlertsQuery } from "../../../store/api/adminApi"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
  },
  {
    title: "Alerts",
    type: "select",
    name: "alertType",
  },
  {
    title: "Date",
    type: "sort-date",
    name: "date",
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
  },
  {
    title: "Status",
    type: "select",
    name: "alertStatus",
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address1",
  },
  {
    title: "City",
    type: "sort-search",
    name: "city",
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
  },
]

export const Alerts = () => {
  const dispatch = useAppDispatch()
  const [triggerGetAdminAlerts, { isFetching, data }] = useLazyGetAdminAlertsQuery()
  const currentAlert = useAppSelector((state) => state.tempData.currentAlert)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [isFirstRender, setFirstRender] = useState(true)

  const onSetCurrentAlert = (id?: number) => {
    const alertsList = data?.data

    if (!id || !alertsList?.length) {
      dispatch(setCurrentAlert(undefined))
    } else {
      const currentAlert = alertsList.filter((item) => item.id === id)[0]
      dispatch(setCurrentAlert(currentAlert))
    }
  }

  const onCheckboxChange = (id: number) => {
    onSetCurrentAlert(id === currentAlert?.id ? undefined : id)
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetAdminAlerts(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onAcknowledgeClick = () => {
    dispatch(showAlertsAcknowledgeModal(true))
  }

  const onFreezeClick = () => {
    dispatch(showAlertsFreezeModal(true))
  }

  const onUnfreezeClick = () => {
    dispatch(showAlertsUnfreezeModal(true))
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams, isFirstRender])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetVendorAlerts />
        <WidgetVendorAlertStatuses />
      </div>
      <ContentWrapper title="Alerts History">
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <div className={styles.tableNavButtonsWrapper}>
            {currentAlert ? (
              <ButtonNew color="blue" onClick={onAcknowledgeClick} className={classNames(styles.tableNavItem)}>
                Acknowledge
              </ButtonNew>
            ) : (
              <ButtonNew color="grey" disabled className={classNames(styles.tableNavItem)}>
                Acknowledge
              </ButtonNew>
            )}
            {currentAlert ? (
              currentAlert?.status === "frozen" ? (
                <ButtonNew onClick={onUnfreezeClick} className={styles.tableNavItem}>
                  Unfreeze
                </ButtonNew>
              ) : (
                <ButtonNew onClick={onFreezeClick} className={styles.tableNavItem}>
                  Freeze
                </ButtonNew>
              )
            ) : (
              <ButtonNew color="grey" disabled className={styles.tableNavItem}>
                Freeze
              </ButtonNew>
            )}
          </div>
        </nav>
        <FilterTags />
        <Table
          headerCells={tableHeaderCells}
          bodyRows={convertAlertsToTableRows(data?.data)}
          isLoading={isFetching}
          onCheckboxChange={onCheckboxChange}
          currentItemID={currentAlert?.id}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
