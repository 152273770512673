import styles from "./index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { UITypes } from "../../../types"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { toast } from "react-toastify"
import { setRequestParams, setCurrentTableColumns } from "../../../store/tempDataSlise"
import { useGetDevicesQuery } from "../../../store/api/devicesApi"
import { useLazyGetMessageTemplatesQuery, useLazyGenerateMessagesQuery } from "../../../store/api/contractorsApi"
import { convertDevicesToTableRows } from "../../../utils/convertData"
import { SelectDynamic } from "../../../components/form/SelectDynamic"
import { Spinner } from "../../../components/common/Spinner"
import { FilterTags } from "../../../components/common/FilterTags"
import { useLazyGetTableColumnsQuery } from "../../../store/api/dashboardApi"
import { reorderTableHeaderCells } from "../../../utils/reorderTabbleColumns"
import { showEditColumnsModal } from "../../../store/modalSlise"

export const MessageCenter = () => {
  const navigate = useNavigate()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const { data, isFetching } = useGetDevicesQuery(
    { ...requestParams, phisical_status: "installed" },
    {
      pollingInterval: 30000,
      skipPollingIfUnfocused: true,
    },
  )

  const [triggerGenerateMessages, { isFetching: isGenerateMessagesFetching }] = useLazyGenerateMessagesQuery()
  const [triggerGetTableColumns, { data: columnsData }] = useLazyGetTableColumnsQuery()
  const dispatch = useAppDispatch()
  const [templateID, setTemplateID] = useState<undefined | UITypes.Option[]>(undefined)
  const [deviceIDs, setDeviceIDs] = useState<number[]>([])
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const [isFirstRender, setFirstRender] = useState(true)

  const [tableHeaderCells, setTableHeaderCells] = useState<UITypes.TableHeaderCell[]>([
    {
      title: "Checkbox",
      name: "checkbox",
      type: "checkbox",
      id: 1,
    },
    {
      title: "Email",
      type: "sort-search",
      name: "email",
      size: "middle",
      id: 2,
    },
    {
      title: "Device S/N",
      type: "sort-search",
      name: "sn",
      size: "small",
      id: 3,
    },
    {
      title: "City",
      type: "sort-search",
      name: "city",
      size: "small",
      id: 4,
    },
    {
      title: "Zip code",
      type: "sort-search",
      name: "zip_code",
      size: "small",
      id: 5,
    },
    {
      title: "State",
      type: "sort-search",
      name: "state",
      size: "small",
      id: 6,
    },
    {
      title: "Opt in/out",
      type: "select",
      name: "option",
      size: "small",
      id: 7,
    },
    {
      title: "Activation date",
      type: "sort-date",
      name: "activation_date",
      size: "middle",
      id: 8,
    },
    {
      title: "Customer name",
      type: "sort-search",
      name: "homeowner_name",
      id: 9,
    },
    {
      title: "Membership",
      type: "sort-search",
      name: "membership",
      size: "small",
      id: 10,
    },
    {
      title: "Residence type",
      type: "sort-search",
      name: "residence_type",
      size: "middle",
      id: 11,
    },
    {
      title: "System type",
      type: "select",
      name: "deviceSystemTypeStatus",
      size: "small",
      id: 12,
    },
    {
      title: "Accessories",
      type: "select",
      name: "deviceAccessoriesStatus",
      size: "small",
      id: 13,
    },
    {
      title: "HVAC",
      type: "select",
      size: "small",
      name: "hvac",
      id: 14,
    },
  ])

  const toggleAllDevices = () => {
    if (isHeaderChecked) {
      setDeviceIDs([])
      setHeaderChecked(false)
    } else {
      const result = data?.data?.map((item) => item?.id)
      result && setDeviceIDs(result)
      setHeaderChecked(true)
    }
  }

  const onSelectDevice = (id?: number) => {
    if (deviceIDs?.some((item) => item === id)) {
      setDeviceIDs(deviceIDs?.filter((item) => item !== id))
    } else {
      setDeviceIDs([...deviceIDs, ...[id]] as number[])
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "contractor_messages_list",
        columns: tableHeaderCells,
      }),
    )
  }

  const onSendMessage = async () => {
    if (!templateID) return

    const data = {
      params: {
        message_template_id: templateID[0]?.value as number,
      },
      body: {
        device_ids: deviceIDs,
      },
    }

    try {
      const res: any = await triggerGenerateMessages(data)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success(
          "The messages will be sent to the selected group during 1 hour. Navigate to History page to check the status of the sent messages.",
        )
        setDeviceIDs([])
        setTemplateID(undefined)
        setHeaderChecked(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    triggerGetTableColumns({ list_name: "contractor_messages_list" })
  }, [isFirstRender])

  useEffect(() => {
    if (columnsData?.data?.config) {
      setTableHeaderCells(
        reorderTableHeaderCells(tableHeaderCells, columnsData?.data?.config).map((item) => ({
          ...item,
          ...(!item.label ? { label: item.title } : {}),
        })),
      )
    }
  }, [columnsData])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  return (
    <>
      <ContentWrapper title="Message Center">
        <nav className={styles.tableNavWrapper}>
          <div className={styles.leftNavWrapper}>
            <ButtonNew onClick={() => navigate("/messages/create-template")} color="blue">
              Create Template
            </ButtonNew>
            <SelectDynamic
              className={styles.selectWrapper}
              valueName="id"
              labelName="title"
              useLazyQuery={useLazyGetMessageTemplatesQuery}
              values={templateID}
              onChange={setTemplateID}
            />
            {templateID && deviceIDs?.length ? (
              <ButtonNew onClick={onSendMessage} color={"blue"}>
                {isGenerateMessagesFetching ? <Spinner /> : "Send"}
              </ButtonNew>
            ) : (
              <ButtonNew disabled color="grey">
                Send
              </ButtonNew>
            )}
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
              Edit columns
            </span>
            <ButtonNew className={styles.tableNavItem} onClick={() => navigate("/messages/history")}>
              History
            </ButtonNew>
            {/* <ButtonNew className={styles.tableNavItem} disabled>Export</ButtonNew> */}
          </div>
        </nav>
        <FilterTags />
        <Table
          headerCells={tableHeaderCells}
          areHeaderColumnsDynamic
          bodyRows={convertDevicesToTableRows(data?.data, tableHeaderCells)}
          isLoading={isFetching}
          onCheckboxChange={onSelectDevice}
          onHeaderCheckboxChange={toggleAllDevices}
          currentItemIDs={deviceIDs}
          isHeaderChecked={isHeaderChecked}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
