import classNames from "classnames"
import { ReactComponent as IconAir } from "../../assets/svg/modal-icon-air.svg"
import { ReactComponent as IconHumidity } from "../../assets/svg/modal-icon-humidity.svg"
import { ReactComponent as IconFan } from "../../assets/svg/modal-icon-fan.svg"
import { ReactComponent as IconSystem } from "../../assets/svg/modal-icon-system.svg"
import { ReactComponent as IconTemperature } from "../../assets/svg/modal-icon-temperature.svg"
import { useGetMonitoringLiveDataQuery } from "../../store/api/monitoringApi"
import parseCelsiusToFahrenheit from "../../utils/parseCelsiusToFahrenheit"
import { getRoundedTemperature } from "../../utils/getRoundedTemperature"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { ApiTypes } from "../../types"
import { parseSystemType } from "../../utils/parseSystemType"
import styles from "./index.module.scss"

interface Props {
  device: ApiTypes.Model.Device
}

const airQualityStates = {
  1: { label: "Good", className: styles.good },
  2: { label: "Fair", className: styles.fair },
  3: { label: "Bad", className: styles.bad },
}

const LiveDateView = ({ device }: Props) => {
  const { data } = useGetMonitoringLiveDataQuery(device.sn, {
    pollingInterval: 5000,
    skipPollingIfUnfocused: true,
  })

  const getTemperature = (temperature: number | undefined) => {
    if (temperature) {
      return `${getRoundedTemperature(parseCelsiusToFahrenheit(temperature), 1)}°F`
    }

    return "-"
  }

  const getHumidity = (humidity: number | undefined) => {
    if (humidity === undefined) return "-"

    return `${humidity}%`
  }

  const getAirQuality = (airQuality: number | undefined) => {
    if (airQuality) {
      return (
        <div className={classNames(styles.devicesDataText, airQualityStates[airQuality].className)}>
          {airQualityStates[airQuality].label}
        </div>
      )
    }

    return "-"
  }

  const getFunMode = () => {
    if (data?.set_fan === undefined) return "-"

    return data.set_fan === 0 ? "AUTO" : "ON"
  }

  const getSystemStage = () => {
    if ((data?.current_cooling_stage || 0) > 0) {
      return (
        <div className={classNames(styles.devicesDataText, styles.cooling)}>
          Cooling (Stage {data?.current_cooling_stage})
        </div>
      )
    }

    if ((data?.current_heating_stage || 0) > 0) {
      return (
        <div className={classNames(styles.devicesDataText, styles.heating)}>
          Heating (Stage {data?.current_heating_stage})
        </div>
      )
    }

    return "-"
  }

  const getSystemMode = () => {
    if (data?.mode_alias === undefined) return "-"

    return (
      <div
        className={classNames(styles.devicesDataText, {
          [styles.heating]: data.mode_alias === "heating",
          [styles.cooling]: data.mode_alias === "cooling",
        })}
      >
        {capitalizeFirstLetter(data.mode_alias)}
      </div>
    )
  }

  return (
    <>
      <section className={styles.devicesDataWrapper}>
        <div className={styles.modalSubtitleWrapper}>
          <IconTemperature className={styles.modalSubtitleIcon} />
          <h4 className={styles.modalSubtitle}>Temperature</h4>
        </div>
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Current temperature</h4>
            <div className={styles.devicesDataText}>{getTemperature(data?.current_temperature_embedded)}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Set temperature</h4>
            <div className={styles.devicesDataText}>{getTemperature(data?.set_temperature)}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Outdoor temperature</h4>
            <div className={styles.devicesDataText}>{getTemperature(data?.current_temperature_outdoor)}</div>
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <div className={styles.modalSubtitleWrapper}>
          <IconHumidity className={styles.modalSubtitleIcon} />
          <h4 className={styles.modalSubtitle}>Humidity</h4>
        </div>
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Set humidity</h4>
            <div className={styles.devicesDataText}>{getHumidity(data?.set_humidity)}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Current humidity</h4>
            <div className={styles.devicesDataText}>{getHumidity(data?.current_humidity_embedded)}</div>
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <div className={styles.modalSubtitleWrapper}>
          <IconAir className={styles.modalSubtitleIcon} />
          <h4 className={styles.modalSubtitle}>Air Quality</h4>
        </div>
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={styles.devicesDataItem}>
            <h4 className={styles.modalSubtitle}>Air Quality state</h4>
            {getAirQuality(data?.current_air_quality)}
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <div className={styles.modalSubtitleWrapper}>
          <IconSystem className={styles.modalSubtitleIcon} />
          <h4 className={styles.modalSubtitle}>System</h4>
        </div>
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Set system mode</h4>
            <div className={classNames(styles.devicesDataText, styles.blue)}>{getSystemMode()}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>System Current state</h4>
            <div className={classNames(styles.devicesDataText, styles.blue)}>{getSystemStage()}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>System type</h4>
            <div className={styles.devicesDataText}>{parseSystemType(device.system_type) || "-"}</div>
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <div className={styles.modalSubtitleWrapper}>
          <IconFan className={styles.modalSubtitleIcon} />
          <h4 className={styles.modalSubtitle}>Fan</h4>
        </div>
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={styles.devicesDataItem}>
            <h4 className={styles.modalSubtitle}>Fan mode</h4>
            <div className={styles.devicesDataText}>{getFunMode()}</div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LiveDateView
