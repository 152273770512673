import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes, UITypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"
import { prepareQueryParams } from "../../utils/prepareQueryParams"

export const devicesApi = createApi({
  reducerPath: "devices",
  tagTypes: ["devices"],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getDevices: builder.query<ApiTypes.Res.Devices, UITypes.Params>({
      query: (arg) => ({
        url: `/devices`,
        params: prepareQueryParams(arg),
        headers: { Accept: "application/json" },
      }),
    }),
  }),
})

export const { useLazyGetDevicesQuery, useGetDevicesQuery } = devicesApi

export const getDevices = devicesApi.endpoints.getDevices
