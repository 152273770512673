import { createPortal } from "react-dom"
import { ReactComponent as IconTerms } from "../../assets/svg/modal-icon-terms.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import styles from "./index.module.scss"
import classNames from "classnames"
import { useLazyAcceptTermsOfUseQuery } from "../../store/api/authApi"
import { useLazyGetUserProfileQuery } from "../../store/api/profileApi"
import { toast } from "react-toastify"
import { storageKeys } from "../../utils/storageKeys"
import { useAppDispatch } from "../../store/hooks"
import { setUserProfile } from "../../store/userSlise"
import { Link } from "react-router-dom"

interface Props {
  onCancel: () => void
}

export const TermOfUseModal = ({ onCancel }: Props) => {
  const [triggerAcceptTermsOfUs, { isFetching }] = useLazyAcceptTermsOfUseQuery()
  const [triggerGetProfile] = useLazyGetUserProfileQuery()
  const dispatch = useAppDispatch()

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerAcceptTermsOfUs()
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        getProfile()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getProfile = async () => {
    try {
      const res: any = await triggerGetProfile()
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        localStorage.setItem(storageKeys.userProfile, JSON.stringify(res?.data?.data))
        dispatch(setUserProfile(res?.data?.data))
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onLogOut = () => {
    onCancel()
    sessionStorage.clear()
    localStorage.clear()
    window.location.reload()
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          cancelButtonText="Log out"
          confirmButtonText="Agree"
          isFetching={isFetching}
          onConfirm={onSubmit}
          onCancel={onLogOut}
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconTerms />
            <h3 className={styles.modalTitle}>Term of Use & Privacy Policy</h3>
            <div className={styles.modalMessage}>
              I agree to the Privacy Policy and Terms of Use, which contain arbitration provisions waiving my right to a
              jury trial and my right to enforce this contract via class action.
              <div className={styles.modalLinksSection}>
                <Link className={styles.modalLink} to="http://unbouncepages.com/nuve-terms-of-use/">
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link className={styles.modalLink} to="http://unbouncepages.com/nuvehome-privacypolicy/">
                  Privacy Policy
                </Link>
                .
              </div>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
