import styles from "./index.module.scss"
import { PropsWithChildren } from "react"
import { Breadcrumbs } from "../Breadcrumbs"
import { UITypes } from "../../../types"

interface Props extends PropsWithChildren {
  title?: string
  breadcrumbs?: UITypes.Breadcrumb[]
}

export const ContentWrapper = ({ title, children, breadcrumbs }: Props) => {
  return (
    <main className={styles.contentWrapper}>
      {breadcrumbs && <Breadcrumbs list={breadcrumbs} />}
      <h2 className={styles.contentTitle}>{title}</h2>
      {children}
    </main>
  )
}
