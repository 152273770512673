import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconTransfer } from "../../assets/svg/modal-icon-trasfer.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useLazyGetDevicesQuery } from "../../store/api/devicesApi"
import { useLazyPutContractorsDevicesAsTrasferedQuery } from "../../store/api/contractorsApi"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { toast } from "react-toastify"
import { setSelectedDeviceIDs } from "../../store/tempDataSlise"

interface Props {
  onCancel: () => void
}

export const TransferModal = ({ onCancel }: Props) => {
  const [triggerPutContractorsDevicesAsTransfered, { isFetching }] = useLazyPutContractorsDevicesAsTrasferedQuery()
  const [triggerGetDevices] = useLazyGetDevicesQuery()
  const dispatch = useAppDispatch()
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerPutContractorsDevicesAsTransfered({ device_ids: selectedDeviceIDs })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetDevices(requestParams === null ? {} : requestParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconTransfer />
            <h3 className={styles.modalTitle}>Transfer</h3>
            <div className={styles.modalMessage}>
              By transferring the selected device(s) to the Vendor, you give away the responsibility for their future
              support. The transferred device(s) will be deducted from your Inventory and Total Devices. Are you sure
              you want to transfer the selected device(s)?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
