import { useState } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconNewOrder } from "../../assets/svg/modal-icon-new-order.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import styles from "./index.module.scss"
import dayjs from "dayjs"
import { toast } from "react-toastify"
import { useLazyCreateOrderQuery, useLazyGetOrdersQuery } from "../../store/api/ordersApi"
import { InputNumber } from "../../components/form/InputNumber"
import { useAppSelector } from "../../store/hooks"

interface Props {
  onCancel: () => void
}

export const NewOrderModal = ({ onCancel }: Props) => {
  const [triggerCreateOrder, { isFetching }] = useLazyCreateOrderQuery()
  const [triggerGetOrders] = useLazyGetOrdersQuery()
  const userProfile = useAppSelector((state) => state.user.userProfile)

  const [quantity, setQuantity] = useState(1)

  const onInputChange = (value: number) => {
    setQuantity(value < 1 ? 1 : value)
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerCreateOrder({
        quantity: quantity,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Order has been successfully added!")
        triggerGetOrders({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="570px" onConfirm={onSubmit} onCancel={onCancel} isFetching={isFetching}>
          <ModalHeader title="New order" subtitle="Please input the number of Nuve Thermostats you want to order.">
            <IconNewOrder />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.orderDetails}>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Contractor ID</span>
                <span className={styles.orderDetailsValue}>{userProfile?.contractor?.id}</span>
              </div>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Creation date</span>
                <span className={styles.orderDetailsValue}>{dayjs().format("MM-DD-YYYY")}</span>
              </div>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Company name</span>
                <span className={styles.orderDetailsValue}>{userProfile?.contractor?.name}</span>
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.deviceWrapper}>
              <div className={styles.deviceQtyWrapper}>
                <span className={styles.deviceName}>Nuve Thermostats</span>
                <span className={styles.deviceQty}>Qty: {quantity}</span>
              </div>
              <InputNumber onInputChange={onInputChange} value={quantity} />
            </div>
            <div className={styles.orderNote}>
              The recently placed order will be visible in the Orders list under the “NEW” status. You can monitor any
              changes to your order’s status through Orders list.
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
