import styles from "./index.module.scss"
import { InputNew } from "../../components/form/InputNew"
import { ButtonNew } from "../../components/form/ButtonNew"
import { InputFileNew } from "../../components/form/InputFileNew"
import classNames from "classnames"
import { SelectDynamic } from "../../components/form/SelectDynamic"
import { useLazyGetCitiesQuery, useLazyGetZipCodesQuery, useLazyGetStatesQuery } from "../../store/api/dictionaryApi"
import { useState, useEffect, FormEvent, useMemo } from "react"
import { onFormValidate, validationInitialState, changeContractorAdditionalInfo } from "../../utils/onValidate"
import {
  useLazyChangeContractorProfileQuery,
  useLazyGetContractorProfileQuery,
  getContractorProfile,
} from "../../store/api/contractorsApi"
import { toast } from "react-toastify"
import { Spinner } from "../../components/common/Spinner"
import { convertDataToOptions } from "../../utils/convertData"
import { useAppSelector } from "../../store/hooks"
import { UITypes } from "../../types"

const inputValuesInitialState: UITypes.AccountAdditionalInfo = {
  companyName: "",
  brandName: "",
  address1: "",
  address2: "",
  zip: [],
  state: [],
  city: [],
  schedulerUrl: "",
  url: "",
  logo: "",
}

interface Props {
  onCancel: () => void
}

export const AccountSettingsAdditionalInfo = ({ onCancel }: Props) => {
  const [triggerGetContractorProfile, { isFetching, data }] = useLazyGetContractorProfileQuery()
  const [triggerChangeContractorProfile, { isFetching: changeContratorIsFetching }] =
    useLazyChangeContractorProfileQuery()
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState })
  const [error, setError] = useState(validationInitialState)
  const currentZip = useAppSelector((state) => state.tempData.currentZip)
  const selectContractorProfile = useMemo(() => getContractorProfile.select(), [])
  const contractorProfile = useAppSelector(selectContractorProfile)
  const [imageFile, setImageFile] = useState()

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const sendRequest = async () => {
    const formData = new FormData()
    formData.append("additional[company_name]", inputValues.companyName)
    formData.append("additional[brand_name]", inputValues.brandName)
    formData.append("additional[address1]", inputValues.address1)
    formData.append("additional[address2]", inputValues.address2)
    formData.append("additional[scheduler_url]", inputValues.schedulerUrl)
    formData.append("additional[url]", inputValues.url)
    inputValues?.zip && formData.append("additional[zip]", inputValues?.zip[0]?.value as string)
    inputValues?.city && formData.append("additional[city]", inputValues?.city[0]?.value as string)
    inputValues?.state && formData.append("additional[state]", inputValues?.state[0]?.value as string)

    if (imageFile) {
      formData.append("additional[logo]", imageFile)
    }

    try {
      const res: any = await triggerChangeContractorProfile(formData as any)

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Account has been successfully updated!")
        setInputValues(inputValuesInitialState)
        onCancel()
        triggerGetContractorProfile()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, changeContractorAdditionalInfo, setError)) {
      sendRequest()
    }
  }

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")

      if (city && state) {
        setInputValues({
          ...inputValues,
          ...{
            city,
            state,
          },
        })
      }
    }
  }, [currentZip])

  useEffect(() => {
    const res = contractorProfile?.data ? contractorProfile?.data : data

    if (res) {
      setInputValues({
        companyName: res?.data?.additional?.company_name || "",
        brandName: res?.data?.additional?.brand_name || "",
        address1: res?.data?.additional?.address1 || "",
        address2: res?.data?.additional?.address2 || "",
        zip: res?.data?.additional?.zip
          ? convertDataToOptions({ data: [res?.data?.additional?.zip] }, "id", "code")
          : [],
        city: res?.data?.additional?.city
          ? convertDataToOptions({ data: [res?.data?.additional?.city] }, "id", "name")
          : [],
        state: res?.data?.additional?.state
          ? convertDataToOptions({ data: [res?.data?.additional?.state] }, "id", "name")
          : [],
        schedulerUrl: res?.data?.additional?.scheduler_url || "",
        url: res?.data?.additional?.url || "",
        logo: res?.data?.additional?.logo || "",
      })
    } else {
      const fetchData = async () => {
        try {
          await triggerGetContractorProfile()
        } catch (error) {
          console.error(error)
        }
      }
      fetchData()
    }
  }, [data, contractorProfile?.data])

  return (
    <>
      <div className={classNames(styles.modalContent, styles.settings)}>
        <InputFileNew
          label="Upload your logo"
          name="avatar"
          isLogo={true}
          imageUrl={inputValues?.logo}
          errorMessage={error.field === "avatar" ? error.message : ""}
          onSetFile={(files) => setImageFile(files[0] as any)}
        />
        {isFetching && (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <div className={styles.formCellsWrapper}>
          <div className={styles.formCell}>
            <InputNew
              label="Company name*"
              placeholder="Company name"
              name="companyName"
              value={inputValues.companyName}
              onChange={onInputChange}
              errorMessage={error.field === "companyName" ? error.message : ""}
            />
          </div>
          <div className={styles.formCell}>
            <InputNew
              label="Brand name*"
              placeholder="Brand name"
              name="brandName"
              value={inputValues.brandName}
              onChange={onInputChange}
              errorMessage={error.field === "brandName" ? error.message : ""}
            />
          </div>
        </div>
        <InputNew
          label="Address 1*"
          placeholder="Enter Address"
          name="address1"
          value={inputValues.address1}
          onChange={onInputChange}
          errorMessage={error.field === "address1" ? error.message : ""}
        />
        <InputNew
          label="Address 2"
          placeholder="Enter Address"
          name="address2"
          value={inputValues.address2}
          onChange={onInputChange}
          errorMessage={error.field === "address2" ? error.message : ""}
        />
        <div className={styles.formCellsWrapper}>
          <div className={styles.formCell}>
            <SelectDynamic
              label="Zip Code"
              valueName="id"
              labelName="code"
              searchFieldName="code"
              name="zip"
              placeholder="Enter Zip Code"
              values={inputValues.zip}
              useLazyQuery={useLazyGetZipCodesQuery}
              onChange={(value) => setInputValues({ ...inputValues, ...{ zip: value } })}
              errorMessage={error.field === "zip" ? error.message : ""}
            />
          </div>
          <div className={styles.formCell}>
            <SelectDynamic
              label="State"
              valueName="id"
              labelName="name"
              placeholder="Enter state"
              name="state"
              useLazyQuery={useLazyGetStatesQuery}
              values={inputValues.state}
              onChange={(value) => {
                setInputValues({ ...inputValues, ...{ state: value } })
              }}
              errorMessage={error.field === "state" ? error.message : ""}
            />
          </div>
        </div>
        <SelectDynamic
          label="City"
          valueName="id"
          labelName="name"
          name="city"
          useLazyQuery={useLazyGetCitiesQuery}
          placeholder="Enter city"
          values={inputValues.city}
          onChange={(value) => setInputValues({ ...inputValues, ...{ city: value } })}
          errorMessage={error.field === "city" ? error.message : ""}
        />
        <InputNew
          label="Web scheduler page URL*"
          placeholder="Web scheduler page URL"
          name="schedulerUrl"
          value={inputValues.schedulerUrl}
          onChange={onInputChange}
          errorMessage={error.field === "schedulerUrl" ? error.message : ""}
        />
        <InputNew
          label="URL*"
          placeholder="URL"
          name="url"
          value={inputValues.url}
          onChange={onInputChange}
          errorMessage={error.field === "url" ? error.message : ""}
        />
      </div>
      <footer className={classNames(styles.modalFooter, styles.wide)}>
        <ButtonNew onClick={onCancel} size="halfWidth" color="greyBordered">
          Cancel
        </ButtonNew>
        <ButtonNew onClick={onSubmit} size="halfWidth" type="submit" color="blue" disabled={changeContratorIsFetching}>
          {changeContratorIsFetching ? <Spinner /> : "Save"}
        </ButtonNew>
      </footer>
    </>
  )
}
