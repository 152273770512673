import styles from "./index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { ReactComponent as BackIcon } from "../../../assets/svg/icon-arrow-left.svg"
import { MessageForm } from "../../../components/form/MessageForm"
import MessageTemplatesList from "../../../components/common/MessageTemplatesList"
import {
  useLazyEditMessageTemplateQuery,
  useLazyGetMessageTemplatesQuery,
  getMessageTemplates,
} from "../../../store/api/contractorsApi"
import { UITypes, ApiTypes } from "../../../types"
import { useAppSelector } from "../../../store/hooks"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMemo, useState, useEffect } from "react"
import { toast } from "react-toastify"

export const MessageTemplateEdit = () => {
  const [triggerEditMessageTemplate, { isFetching }] = useLazyEditMessageTemplateQuery()
  const [triggerGetMessageTemplates] = useLazyGetMessageTemplatesQuery()
  const [currentTemplate, setCurrentTemplate] = useState<ApiTypes.Model.MessageTemplate | null>(null)
  const [searchParams] = useSearchParams()
  const templateId = searchParams.get("id")
  const selecttMessageTemplates = useMemo(() => getMessageTemplates.select(), [])
  const messageTemplates = useAppSelector(selecttMessageTemplates)
  const navigate = useNavigate()

  const trasformEditData = (): UITypes.MessageTemplate | undefined => {
    if (!currentTemplate) return undefined

    return {
      title: currentTemplate.title,
      message: currentTemplate.body,
      frequency: currentTemplate.repeat_every,
    }
  }

  const onSetCurrentTemplate = () => {
    const templatesList = messageTemplates?.data?.data
    if (!templateId || !templatesList?.length) {
      setCurrentTemplate(null)
    } else {
      const currentTemplate = templatesList.filter((item) => item.id === Number(templateId))[0]
      setCurrentTemplate(currentTemplate)
    }
  }

  const onEditTemplate = async (data: UITypes.MessageTemplate) => {
    try {
      const res: any = await triggerEditMessageTemplate({
        body: {
          title: data.title,
          body: data.message,
          repeat_every: data.frequency as ApiTypes.Model.MessageTemplateFrequency,
        },
        id: templateId!,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Message template has been successfully updated!")
        navigate("/messages/create-template")
        triggerGetMessageTemplates()
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    onSetCurrentTemplate()
  }, [messageTemplates, templateId])

  return (
    <>
      <ContentWrapper title="Edit message template">
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <ButtonNew onClick={() => navigate(-1)} color="greyBordered" className={styles.backButton}>
            <BackIcon />
            Back
          </ButtonNew>
        </nav>
        <div className={styles.contentWrapper}>
          <div className={styles.sectionWrapper}>
            <MessageForm isLoading={isFetching} onSave={onEditTemplate} editableTemplate={trasformEditData()} />
          </div>
          <div className={styles.sectionWrapper}>
            <MessageTemplatesList />
          </div>
        </div>
      </ContentWrapper>
    </>
  )
}
