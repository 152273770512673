import styles from "./index.module.scss"
import classNames from "classnames"

interface Props {
  type: string
}

export const StatusOfWHDevice = ({ type }: Props) => {
  let label = ""

  switch (type) {
    case "stocked":
      label = "STOCKED"
      break

    case "inventory":
      label = "INVENTORY"
      break

    case "installed":
      label = "INSTALLED"
      break

    case "missing":
      label = "MISSING"
      break

    case "production":
      label = "PRODUCTION"
      break

    case "warranty":
      label = "WARRANTY"
      break

    case "disposed":
      label = "DISPOSED"
      break

    case "damaged":
      label = "DAMAGED"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.alertStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
