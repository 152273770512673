import { useEffect } from "react"
import classNames from "classnames"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { showPerformanceTestCancelModal, showPerformanceTestActionsModal } from "../../../store/modalSlise"
import { Table } from "../../../components/table/Table"
import { convertDataToPerformanceTestTableRows } from "../../../utils/convertData"
import { useLazyGetPerformanceTestsQuery } from "../../../store/api/performanceTestApi"
import { WidgetPerformanceTest } from "../../../components/widgets/WidgetPerformanceTest"
import { setSelectedDevicesForPerformanceTest } from "../../../store/tempDataSlise"
import { ApiTypes, UITypes } from "../../../types"
import styles from "./index.module.scss"
import { Pagination } from "../../../components/common/Pagination"
import { FilterTags } from "../../../components/common/FilterTags"

const getTableHeaderCells = (): UITypes.TableHeaderCell[] => {
  return [
    {
      title: "",
    },
    {
      title: "Email",
      type: "sort-search",
      name: "email",
      size: "middle",
    },
    {
      title: "Status",
      type: "select",
      name: "contractorPerformanceTestStatus",
      size: "small",
    },
    {
      title: "Work status",
      type: "select",
      name: "contractorDeviceWorkStatus",
      size: "small",
    },
    {
      title: "Label",
      type: "sort-search",
      name: "perftest_label",
      size: "small",
    },
    {
      title: "City",
      type: "sort-search",
      name: "city",
      size: "small",
    },
    {
      title: "ZIP code",
      type: "sort-search",
      name: "zip_code",
      size: "small",
    },
    {
      title: "State",
      type: "sort-search",
      name: "state",
      size: "small",
    },
    {
      title: "Customer",
      type: "sort-search",
      name: "homeowner_name",
      size: "middle",
    },
    {
      title: "Device S/N",
      type: "sort-search",
      name: "sn",
      size: "small",
    },
    {
      title: "Address",
      type: "sort-search",
      name: "address",
    },
    {
      title: "HVAC",
      type: "select",
      size: "small",
      name: "hvac",
    },
    {
      title: "Alerts",
      type: "select",
      size: "small",
      name: "devicesAlerts",
    },
    {
      title: "Membership",
      type: "select",
      name: "devicesMembership",
      size: "small",
    },
    {
      title: "System type",
      type: "select",
      size: "small",
      name: "deviceSystemTypeStatus",
    },
    {
      title: "Accessories",
      type: "select",
      size: "small",
      name: "deviceAccessoriesStatus",
    },
  ]
}

export const PerformanceTest = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [triggerGetPerformanceTest, { isFetching, data }] = useLazyGetPerformanceTestsQuery()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const { devices } = useAppSelector((state) => state.tempData.selectedDevicesForPerformanceTest)

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetPerformanceTest({ phisical_status: "installed", ...params })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    dispatch(setSelectedDevicesForPerformanceTest({ devices: [] }))
  }, [])

  useEffect(() => {
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams])

  const handlePerformanceTestActions = () => {
    dispatch(showPerformanceTestActionsModal(true))
  }

  const handleCancelPerformanceTest = () => {
    dispatch(showPerformanceTestCancelModal(true))
  }

  const onSelectDeviceForPerformanceTest = (id?: number) => {
    if (devices?.some((item) => item.id === id)) {
      dispatch(setSelectedDevicesForPerformanceTest({ devices: devices?.filter((item) => item.id !== id) }))
    } else {
      const selectedItem = data?.data?.find((item) => item.id === id)
      dispatch(
        setSelectedDevicesForPerformanceTest({
          isImmediate: false,
          devices: [
            ...devices,
            ...[{ id, email: selectedItem?.email, sn: selectedItem?.sn, system_type: selectedItem?.system_type }],
          ] as ApiTypes.Model.PerformanceTestDevice[],
        }),
      )
    }
  }

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetPerformanceTest />
      </div>
      <ContentWrapper title="Performance Test">
        <nav className={styles.tableNavWrapper}>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew
              className={classNames(styles.tableNavItem, styles.startPerformanceButton)}
              type="submit"
              disabled={devices.length === 0}
              onClick={handlePerformanceTestActions}
            >
              Start Performance Test
            </ButtonNew>
            <ButtonNew
              className={styles.cancelButton}
              type="submit"
              disabled={devices.length === 0}
              onClick={handleCancelPerformanceTest}
            >
              CANCEL
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew className={styles.tableNavItem} onClick={() => navigate("/perf-test-history")}>
              History
            </ButtonNew>
          </div>
        </nav>
        <FilterTags />
        <Table
          headerCells={getTableHeaderCells()}
          bodyRows={convertDataToPerformanceTestTableRows(data?.data)}
          isLoading={isFetching}
          onCheckboxChange={onSelectDeviceForPerformanceTest}
          currentItemIDs={devices.map((item) => item.id)}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
