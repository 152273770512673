import { UITypes } from "../../../types"
import styles from "./index.module.scss"
import { useState, FormEvent, MutableRefObject } from "react"
import { InputNew } from "../InputNew"
import { ReactComponent as IconClear } from "../../../assets/svg/icon-input-sn-clear.svg"

interface Props extends UITypes.InvalidDevice {
  onDelete?: (id: string) => void
  onBlur?: (data: UITypes.InvalidDevice) => void
  autoFocus?: boolean
}

export const InputSN = ({ uid, sn, row, errorMessage, autoFocus, onBlur, onDelete }: Props) => {
  const [value, setValue] = useState(sn)

  const isValidValue = (value) => {
    const regex = /^\d{2}-\d{3}-\d{6}$/
    return regex.test(value)
  }

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value

    if (isValidValue(value)) {
      onBlur &&
        onBlur({
          uid,
          row,
          errorMessage,
          sn: value ? value : "",
        })
    }

    setValue(event?.currentTarget?.value)
  }

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputNumber}>{row}</div>
      <InputNew
        className={styles.inputSN}
        placeholder="Enter S/N"
        name="sn"
        autoFocus={autoFocus}
        value={value}
        onChange={onInputChange}
        errorMessage={errorMessage}
      />
      {onDelete && (
        <span className={styles.clearButton} onClick={() => onDelete(uid)}>
          <IconClear />
        </span>
      )}
    </div>
  )
}
