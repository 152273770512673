import { MutableRefObject, useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { useLocation } from "react-router-dom"
import "../../../assets/libs/react-datepicker.css"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { Table } from "../../../components/table/Table"
import { convertDataToPerformanceTestHistoryTableRows } from "../../../utils/convertData"
import {
  useLazyGetPerformanceTestReportQuery,
  useLazyGetPerformanceTestsHistoryQuery,
} from "../../../store/api/performanceTestApi"
import { setPerformanceTestReport, setRequestParams } from "../../../store/tempDataSlise"
import { UITypes } from "../../../types"
import { showPerformanceTestReportModal } from "../../../store/modalSlise"
import { Pagination } from "../../../components/common/Pagination"
import { toast } from "react-toastify"
import { perfTestHistoryFilters, getPerfTestDateRangeParams, setDateParam } from "./getPerfTestDateRangeParams"
import { useOutsideClick } from "../../../utils/useOutsideClick"
import { FilterTags } from "../../../components/common/FilterTags"
import styles from "./index.module.scss"

const getTableHeaderCells = (): UITypes.TableHeaderCell[] => {
  return [
    {
      title: "Email",
      type: "sort-search",
      name: "email",
      size: "middle",
    },
    {
      title: "Status",
      type: "select",
      name: "contractorPerformanceTestHistoryStatus",
      size: "small",
    },
    {
      title: "Test Date",
      name: "time",
      size: "middle",
      type: "sort",
    },
    {
      title: "Label",
      type: "sort-search",
      name: "label",
      size: "small",
    },
    {
      title: "City",
      type: "sort-search",
      name: "city",
      size: "small",
    },
    {
      title: "ZIP code",
      type: "sort-search",
      name: "zip_code",
      size: "small",
    },
    {
      title: "State",
      type: "sort-search",
      name: "state",
      size: "small",
    },
    {
      title: "Customer",
      type: "sort-search",
      name: "homeowner_name",
      size: "middle",
    },
    {
      title: "Device S/N",
      type: "sort-search",
      name: "sn",
      size: "small",
    },
    {
      title: "Activation date",
      type: "sort",
      name: "activation_date",
      size: "middle",
    },
    {
      title: "Address",
      type: "sort-search",
      name: "address",
    },
  ]
}

const testHistoryBreadcrumbs = [
  { label: "Performance Test", link: "/perf-test" },
  { label: "Performance Test History", link: "/perf-test-history" },
]

export const PerformanceTestHistory = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [triggerGetPerformanceTestsHistory, { isFetching, data }] = useLazyGetPerformanceTestsHistoryQuery()
  const [triggerGetPerformanceTestReport] = useLazyGetPerformanceTestReportQuery()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [activeDateFilter, setActiveDateFilter] = useState("all")
  const [isDateCustom, setIsDateCustom] = useState(false)
  const [customFilterDateRange, setCustomFilterDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  })
  const datePickerRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  useEffect(() => {
    sendRequest(
      !requestParams?.orderType
        ? {
            orderColumn: { value: "time" },
            orderType: { label: "Test Date", value: "DESC" },
          }
        : requestParams,
    )
  }, [requestParams])

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetPerformanceTestsHistory(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useOutsideClick(datePickerRef, () => setIsDateCustom(false))

  const handleTableRowClick = async (id: number) => {
    const currentDevice = data?.data?.find((item) => item.perftest.perftest_result_id === id)

    const res = await triggerGetPerformanceTestReport(id)
    if (res.data) {
      dispatch(
        setPerformanceTestReport({
          ...res?.data,
          customer: currentDevice?.client?.homeowner_name,
          zip_code: currentDevice?.device?.zip?.code,
          where_installed_name: currentDevice?.device?.where_installed_name,
        }),
      )
      dispatch(showPerformanceTestReportModal(true))
    }
  }

  const handleChangeDateFilter = (date: string) => {
    setActiveDateFilter(date)

    if (date === "custom" && !isDateCustom) {
      setIsDateCustom(true)
      return
    }

    dispatch(setRequestParams(getPerfTestDateRangeParams(date)))
  }

  const handleChangeCustomDate = (dates) => {
    const [start, end] = dates
    setCustomFilterDateRange({
      startDate: start,
      endDate: end,
    })
  }

  const handleCancelDateFilter = () => {
    setIsDateCustom(false)
    setCustomFilterDateRange({
      startDate: new Date(),
      endDate: null,
    })
  }

  const handleApplyDateFilter = () => {
    dispatch(setRequestParams(setDateParam(customFilterDateRange.startDate, customFilterDateRange.endDate)))
    setIsDateCustom(false)
  }

  return (
    <>
      <ContentWrapper title="Performance Test History" breadcrumbs={testHistoryBreadcrumbs}>
        <nav className={styles.tableNavWrapper}>
          <div className={styles.tableFiltersWrapper}>
            {perfTestHistoryFilters.map((filter) => (
              <div
                key={filter.id}
                className={`${styles.tableFilter} ${activeDateFilter === filter.value ? styles.active : ""}`}
                onClick={() => handleChangeDateFilter(filter.value)}
              >
                <span>{filter.label}</span>
              </div>
            ))}
            {isDateCustom && (
              <div ref={datePickerRef} className={styles.datePickerWrapper}>
                <DatePicker
                  wrapperClassName={styles.innerWrapper}
                  selected={customFilterDateRange.startDate}
                  onChange={handleChangeCustomDate}
                  startDate={customFilterDateRange.startDate}
                  endDate={customFilterDateRange.endDate}
                  selectsRange
                  inline
                />
                <div className={styles.dateButtonsWrapper}>
                  <ButtonNew color="greyBordered" type="submit" size="halfWidth" onClick={handleCancelDateFilter}>
                    Cancel
                  </ButtonNew>
                  <ButtonNew color="blue" type="submit" size="halfWidth" onClick={handleApplyDateFilter}>
                    Apply
                  </ButtonNew>
                </div>
              </div>
            )}
          </div>
          {/* <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew className={styles.tableNavItem}>Export</ButtonNew>
          </div> */}
        </nav>
        <FilterTags skippedTags={["result_date_start", "result_date_end"]} />
        <Table
          headerCells={getTableHeaderCells()}
          bodyRows={convertDataToPerformanceTestHistoryTableRows(data?.data)}
          isLoading={isFetching}
          onRowClick={(id) => handleTableRowClick(id)}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
