import styles from "./index.module.scss"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconEditUser } from "../../assets/svg/modal-icon-edit-columns.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { useEffect, useState } from "react"
import { UITypes, ApiTypes } from "../../types"
import { DADItem } from "../../components/common/DADItem"
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { useLazyUpdateTableColumnsQuery, useLazyGetTableColumnsQuery } from "../../store/api/dashboardApi"
import { toast } from "react-toastify"
import { useAppSelector } from "../../store/hooks"
import pick from "lodash.pick"

interface Props {
  onCancel: () => void
}

export const EditColumnsModal = ({ onCancel }: Props) => {
  const [items, setItems] = useState<UITypes.TableHeaderCell[]>([])
  const currentTableColumns = useAppSelector((state) => state.tempData.currentTableColumns)
  const [triggerUpdateTableColumnsQuery, { isFetching, data }] = useLazyUpdateTableColumnsQuery()
  const [triggerGetTableColumnsQuery] = useLazyGetTableColumnsQuery()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const onCancelClick = () => {
    onCancel()
  }

  function handleDragEnd(event) {
    if (!items?.length) return

    const { active, over } = event

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items?.findIndex((item) => item.id === active.id)
        const newIndex = items?.findIndex((item) => item.id === over.id)

        const newItems = arrayMove(items!, oldIndex!, newIndex!)
        return [...newItems] // создаем новый массив
      })
    }
  }

  const onVisibilityChange = (value: number) => {
    const result = items?.map((item) => {
      if (item?.id === value) {
        return { ...item, ...{ visibility: !item.visibility } }
      }
      return item
    })

    setItems(result)
  }

  const sendRequest = async () => {
    try {
      const res: any = await triggerUpdateTableColumnsQuery({
        body: {
          list_name: currentTableColumns?.table as ApiTypes.Model.PageTableName,
          config: items.map((item) => pick(item, ["label", "name", "visibility"])),
        },
      })

      if (res?.isSuccess) {
        toast.success("Table columns have been successfully updated!")
        triggerGetTableColumnsQuery({ list_name: currentTableColumns?.table as ApiTypes.Model.PageTableName })
        onCancel()
      }
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async (event) => {
    sendRequest()
  }

  useEffect(() => {
    if (currentTableColumns) {
      setItems(currentTableColumns.columns)
    }
  }, [currentTableColumns])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="760px"
          confirmButtonText="Save"
          buttonSize="auto"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          isFetching={isFetching}
        >
          <ModalHeader
            title="Edit columns"
            subtitle="Customize your view by hiding unnecessary columns and reordering them to suit your needs."
          >
            <IconEditUser />
          </ModalHeader>
          <div className={styles.draggableWrapper}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              {Boolean(items?.length) && (
                <SortableContext items={items.map((item) => item?.id) as any} strategy={verticalListSortingStrategy}>
                  {items?.map((item) => <DADItem key={item?.id} {...item} onVisibilityChange={onVisibilityChange} />)}
                </SortableContext>
              )}
            </DndContext>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
