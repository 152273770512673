import styles from "./index.module.scss"
import classNames from "classnames"
import { useState } from "react"
import { NavLink } from "../../common/NavLink"
import { ReactComponent as IconAdmin } from "../../../assets/svg/menu-admin.svg"
import { ReactComponent as IconMenuDown } from "../../../assets/svg/icon-menu-down.svg"
import { ReactComponent as IconUsers } from "../../../assets/svg/menu-icon-users.svg"

interface Props {
  onClick?: () => void
}

export const AdminSubmenu = ({ onClick }: Props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  let timeoutId

  const openDropdown = () => {
    clearTimeout(timeoutId)
    setDropdownVisible(true)
  }

  const closeDropdown = () => {
    timeoutId = setTimeout(() => {
      setDropdownVisible(false)
    }, 300)
  }

  return (
    <div className={styles.subMenuWrapper} onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
      <NavLink
        onClick={onClick}
        to="/admin"
        activeClassName={styles.active}
        className={classNames(styles.menuItem, {
          [styles.active]: isDropdownVisible,
        })}
      >
        <IconAdmin className={styles.menuIcon} />
        Admin
        <IconMenuDown className={classNames(styles.menuIconStroke)} />
      </NavLink>

      {isDropdownVisible && (
        <div className={styles.subMenuList} onMouseEnter={openDropdown}>
          <NavLink to="/admin/users" className={styles.subMenuItem} onClick={onClick}>
            <IconUsers className={styles.menuIcon} />
            Users List
          </NavLink>
        </div>
      )}
    </div>
  )
}
