import { useEffect, useState } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { RainbowChart } from "../../common/RainbowChart"
import classNames from "classnames"
// import { Spinner } from "../../common/Spinner"

const initialState = {
  totalDevices: 0,
  pausedDevices: 0,
  issueDevices: 0,
}

export const WidgetDiagnosticsStatuses = () => {
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    setWidgetData({
      totalDevices: 15,
      pausedDevices: 5,
      issueDevices: 10,
    })
  }, [])

  return (
    <WidgetLayout title="Diagnostics Statuses" className={styles.widgetMembership}>
      <div
        className={classNames(styles.widgetContent, {
          // [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <RainbowChart
            colors={["#22C55E", "#EF4444"]}
            values={[widgetData.pausedDevices, widgetData.issueDevices]}
            className={styles.chart}
          />
        </div>
        <div className={styles.widgetMainValue}>
          <span className={styles.widgetNote}>DEVICES</span>
          <div className={styles.widgetValueWrapper}>{widgetData.totalDevices}</div>
        </div>
        <footer className={styles.widgetFooter}>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.paused)}>Passed</span>
            <div className={styles.widgetValueWrapper}>{widgetData.pausedDevices}</div>
          </div>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.issue)}>Issue</span>
            <div className={styles.widgetValueWrapper}>{widgetData.issueDevices}</div>
          </div>
        </footer>
        {/* {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )} */}
      </div>
    </WidgetLayout>
  )
}
