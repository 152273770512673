import { useEffect, FormEvent, useState } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconTechnician } from "../../assets/svg/modal-icon-technician.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { ToggleButton } from "../../components/form/ToggleButton"
import styles from "./index.module.scss"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import isEqual from "lodash.isequal"
import { setEditableTechnician } from "../../store/tempDataSlise"
import { useLazyUpdateTechnicianQuery, useLazyGetTechniciansQuery } from "../../store/api/techniciansApi"
import { toast } from "react-toastify"
import { removeEmptyValues } from "../../utils/removeEmptyValues"
import { validationInitialState, onFormValidate, updateTechnicianSchema } from "../../utils/onValidate"

const inputValuesInitialState = {
  fullName: "",
  userName: "",
  password: "",
  isDisable: false,
}

interface Props {
  onCancel: () => void
}

export const EditTechnicianModal = ({ onCancel }: Props) => {
  const [triggerUpdateTechnician, { isFetching }] = useLazyUpdateTechnicianQuery()
  const [triggerGetTechnicians] = useLazyGetTechniciansQuery()
  const dispatch = useAppDispatch()
  const editableTechnician = useAppSelector((state) => state.tempData.editableTechnician)

  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, updateTechnicianSchema, setError)) {
      try {
        const res: any = await triggerUpdateTechnician({
          data: removeEmptyValues({
            full_name: inputValues.fullName,
            username: inputValues.userName,
            is_enabled: !inputValues.isDisable,
            password: inputValues.password,
            password_confirmation: inputValues.password,
          }),
          id: editableTechnician?.id!,
        })

        if (res?.error) {
          toast.error(res?.error?.data?.message)
        } else {
          toast.success("Technician has been successfully updated!")
          setInputValues(inputValuesInitialState)
          triggerGetTechnicians({})
          onCancel()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onCancelClick = () => {
    dispatch(setEditableTechnician(undefined))
    onCancel()
  }

  useEffect(() => {
    if (isEqual(inputValues, inputValuesInitialState) && editableTechnician) {
      setInputValues({
        fullName: editableTechnician.full_name,
        userName: editableTechnician.username,
        password: "",
        isDisable: !editableTechnician.is_enabled,
      })
    }
  }, [editableTechnician])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          onConfirm={onSubmit}
          isFetching={isFetching}
          onCancel={onCancelClick}
        >
          <ModalHeader title="Edit technician">
            <IconTechnician />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Full name"
              placeholder="Enter full name"
              name="fullName"
              value={inputValues.fullName}
              onChange={onInputChange}
              errorMessage={error.field === "fullName" ? error.message : ""}
            />
            <InputNew
              label="Username"
              placeholder="Enter username"
              name="userName"
              value={inputValues.userName}
              onChange={onInputChange}
              labelBottom="Please do not enter an email address in the Username field."
              errorMessage={error.field === "userName" ? error.message : ""}
            />
            <InputNew
              label="Password"
              placeholder="Enter password"
              inputType="password"
              name="password"
              value={inputValues.password}
              onChange={onInputChange}
              errorMessage={error.field === "password" ? error.message : ""}
            />
            <div className={styles.toggleFieldWrapper}>
              <ToggleButton
                id="isDisable"
                isReverse
                name="isDisable"
                isChecked={inputValues.isDisable}
                onChange={onInputChange}
              >
                Disable technician
              </ToggleButton>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
