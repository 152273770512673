import styles from "./index.module.scss"
import classNames from "classnames"
import { ReactComponent as IconEdit } from "../../../assets/svg/icon-edit.svg"
import { ReactComponent as IconDelete } from "../../../assets/svg/icon-delete.svg"
import { ReactComponent as IconEye } from "../../../assets/svg/icon-eye.svg"

interface Props {
  onEdit?: () => void
  onDelete?: () => void
  onView?: () => void
  dynamicNavHandler?: () => void
  DynamicNavIcon?: React.FunctionComponent | undefined
  customClassName?: string
}

export const TableRowNavigation = ({
  onEdit,
  onDelete,
  onView,
  dynamicNavHandler,
  customClassName,
  DynamicNavIcon,
}: Props) => {
  return (
    <div className={classNames(styles.rowNavWrapper, customClassName)}>
      <div className={styles.rowNavButtonsContainer}>
        {onView && (
          <span className={styles.rowNavButton} onClick={onView}>
            <IconEye />
          </span>
        )}
        {DynamicNavIcon && (
          <span className={styles.rowNavButton} onClick={dynamicNavHandler}>
            <DynamicNavIcon />
          </span>
        )}
        {onEdit && (
          <span className={styles.rowNavButton} onClick={onEdit}>
            <IconEdit />
          </span>
        )}
        {onDelete && (
          <span className={styles.rowNavButton} onClick={onDelete}>
            <IconDelete />
          </span>
        )}
      </div>
    </div>
  )
}
