import { useEffect, useState } from "react"

export const useDebouncedValue = (inputValue: string, delay: number, minLen = 5) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue)
  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputValue.length > minLen) {
        setDebouncedValue(inputValue)
      }
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [inputValue, delay])

  return debouncedValue
}
