import styles from "../../assets/scss/index.module.scss"
import { FormEvent, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconWarehouse } from "../../assets/svg/modal-icon-warehouse.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setCurrentZip } from "../../store/tempDataSlise"
import { UITypes } from "../../types"
import { validationInitialState, onFormValidate, addWarehouseSchema } from "../../utils/onValidate"
import { SelectDynamic } from "../../components/form/SelectDynamic"
import { useLazyGetCitiesQuery, useLazyGetZipCodesQuery, useLazyGetStatesQuery } from "../../store/api/dictionaryApi"
import { convertDataToOptions } from "../../utils/convertData"
import { useLazyAddWarehouseQuery, useLazyGetWarehouseListQuery } from "../../store/api/warehousesApi"
import { toast } from "react-toastify"
import { InputPhone } from "../../components/form/InputPhone"

const inputValuesInitialState: UITypes.Warehouse = {
  name: "",
  address: "",
  zip: [],
  state: [],
  city: [],
  phone: "",
}

interface Props {
  onCancel: () => void
}

export const AddWarehouseModal = ({ onCancel }: Props) => {
  const [triggerAddWarehouse, { isFetching }] = useLazyAddWarehouseQuery()
  const [triggerGetWarehouseList] = useLazyGetWarehouseListQuery()
  const dispatch = useAppDispatch()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)
  const currentZip = useAppSelector((state) => state.tempData.currentZip)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, addWarehouseSchema, setError)) {
      sendRequest(inputValues)
    }
  }

  const onCancelClick = () => {
    dispatch(setCurrentZip(undefined))
    onCancel()
  }

  const sendRequest = async (data) => {
    try {
      await triggerAddWarehouse({
        name: data.name,
        address: data.address,
        phone_number: data.phone,
        zip_id: data.zip[0]?.value,
        state_id: data.state[0]?.value,
        city_id: data.city[0]?.value,
      })
      toast.success("Warehouse has been successfully added!")
      setInputValues(inputValuesInitialState)
      dispatch(setCurrentZip(undefined))
      triggerGetWarehouseList({})
      onCancel()
    } catch (error) {
      console.error(error)
    }
  }

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")

      if (city && state) {
        setInputValues({
          ...inputValues,
          ...{
            city,
            state,
          },
        })
      }
    }
  }, [currentZip])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          confirmButtonType="submit"
          isFetching={isFetching}
        >
          <ModalHeader title="Add Warehouse">
            <IconWarehouse />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Warehouse name"
              placeholder="Enter warehouse name"
              name="name"
              value={inputValues.name}
              onChange={onInputChange}
              errorMessage={error.field === "name" ? error.message : ""}
            />
            <div className={styles.formCellsWrapper}>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="Zip Code"
                  valueName="id"
                  labelName="code"
                  searchFieldName="code"
                  name="zip"
                  values={inputValues.zip}
                  useLazyQuery={useLazyGetZipCodesQuery}
                  placeholder="Enter Zip Code"
                  onChange={(value) => setInputValues({ ...inputValues, ...{ zip: value } })}
                  errorMessage={error.field === "zip" ? error.message : ""}
                />
              </div>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="State"
                  valueName="id"
                  labelName="name"
                  placeholder="Enter state"
                  name="state"
                  useLazyQuery={useLazyGetStatesQuery}
                  values={inputValues.state}
                  onChange={(value) => {
                    setInputValues({ ...inputValues, ...{ state: value } })
                  }}
                  errorMessage={error.field === "state" ? error.message : ""}
                />
              </div>
            </div>
            <SelectDynamic
              label="City"
              valueName="id"
              labelName="name"
              name="city"
              useLazyQuery={useLazyGetCitiesQuery}
              placeholder="Enter city"
              values={inputValues.city}
              onChange={(value) => setInputValues({ ...inputValues, ...{ city: value } })}
              errorMessage={error.field === "city" ? error.message : ""}
            />
            <InputNew
              label="Address"
              placeholder="Enter address "
              name="address"
              value={inputValues.address}
              onChange={onInputChange}
              errorMessage={error.field === "address" ? error.message : ""}
            />
            <InputPhone
              label="Phone number"
              placeholder="Enter phone"
              name="phone"
              value={inputValues.phone}
              onChange={(value) => setInputValues({ ...inputValues, ...{ phone: value } })}
              errorMessage={error.field === "phone" ? error.message : ""}
            />
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
