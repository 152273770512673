import styles from "./index.module.scss"
import { ReactComponent as IconCloud } from "../../../assets/svg/modal-icon-cloud.svg"
import { useState, useRef, MutableRefObject } from "react"
import classNames from "classnames"
import { ReactComponent as IconPhoto } from "../../../assets/svg/icon-photo.svg"
import { getFileUrl } from "../../../utils/getFileUrl"

interface Props {
  onSetFile: (value: File[]) => void
  accept?: string
  imageUrl?: string
  value?: File[]
  name?: string
  label?: string
  defaultLink?: string
  errorMessage?: string
  isLogo?: boolean
}

export const InputFileNew = ({
  onSetFile,
  imageUrl,
  name,
  defaultLink,
  value,
  label,
  errorMessage,
  isLogo,
  accept,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<File[] | undefined>(value ? value : undefined)
  const uploadAvatarRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    setIsDragging(false)

    const files = e.dataTransfer.files
    setUploadedFiles(files)
  }

  const onInputChange = (event) => {
    const files = event?.target?.files
    onSetFile(files)
    setUploadedFiles(event?.target?.files as any)
  }

  return (
    <div className={styles.fieldWrapper}>
      {label && <div className={styles.fieldLabel}>{label}</div>}
      <div className={styles.field}>
        {uploadedFiles?.length ? (
          <label
            htmlFor="file"
            className={styles.uploadedImage}
            style={{ backgroundImage: `url(${uploadedFiles ? getFileUrl(uploadedFiles[0]) : defaultLink})` }}
          />
        ) : imageUrl ? (
          <label htmlFor="file" className={styles.uploadedImage} style={{ backgroundImage: `url(${imageUrl})` }} />
        ) : (
          <IconPhoto className={styles.icon} />
        )}
        <input
          type="file"
          id="file"
          name={name}
          accept="image/png,image/jpeg"
          className={styles.inputFile}
          onChange={onInputChange}
          ref={uploadAvatarRef}
        />
        <label
          htmlFor="file"
          className={classNames(styles.fileWrapper, {
            [styles.dragging]: isDragging,
            [styles.error]: errorMessage,
          })}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <IconCloud />
          {isLogo ? (
            <div className={styles.logoText}>
              Format: png
              <br />
              Aspect ratio 16:6
              <br />
              Max size: 750x281px
            </div>
          ) : (
            <div className={styles.labelText}>Choose a file</div>
          )}

          <span className={styles.uploudButton}>Upload</span>
        </label>
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
