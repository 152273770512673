import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { BarChart } from "../../common/BarChart"
import classNames from "classnames"
import { useState, useEffect } from "react"
import { useLazyGetContractorsWidgetQuery } from "../../../store/api/vendorApi"
import { Spinner } from "../../common/Spinner"

const initialState = {
  activeST: 0,
  total: 0,
}

export const WidgetSTAndMemberships = () => {
  const [widgetData, setWidgetData] = useState(initialState)
  const [triggerGetContractorsWidget, { isFetching, data }] = useLazyGetContractorsWidgetQuery()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetContractorsWidget()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      setWidgetData({
        activeST: data?.st_enabled,
        total: data?.total,
      })
    }
  }, [data])

  return (
    <WidgetLayout title="ServiceTitan" className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartItemWrapper}>
          <div className={styles.chartValuesWrapper}>
            <span className={classNames(styles.chartValuesTitle, styles.dark)}>
              Active Contractor with ServiceTitan
            </span>
            <span className={styles.chartValuesTitle}>
              {widgetData.activeST} ({Math.round((widgetData?.activeST / widgetData.total) * 100)}%)
            </span>
          </div>
          <BarChart
            className={styles.chart}
            items={[
              {
                value: widgetData.activeST,
                color: "#475569",
              },
              {
                value: widgetData.total - widgetData.activeST,
                color: "#E2E8F0",
              },
            ]}
          />
        </div>
        {/* <div className={styles.chartItemWrapper}>
          <div className={styles.chartValuesWrapper}>
            <span className={classNames(styles.chartValuesTitle, styles.blue)}>Devices with Memberships</span>
            <span className={styles.chartValuesTitle}>8 (50%)</span>
          </div>
          <BarChart
            className={styles.chart}
            items={[
              {
                value: widgetData.activeContractors,
                color: "#43E0F8",
              },
              {
                value: widgetData.inActiveContractors,
                color: "#E2E8F0",
              },
            ]}
          />
        </div> */}
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
