import styles from "./index.module.scss"
import { WidgetPhysicalStatus } from "../../../components/widgets/WidgetPhysicalStatus"
import { WidgetInstaledDevices } from "../../../components/widgets/WidgetInstaledDevices"
import { WidgetVendorAlerts } from "../../../components/widgets/WidgetVendorAlerts"
import { WidgetVendorAlertStatuses } from "../../../components/widgets/WidgetVendorAlertStatuses"
import { WidgetWarehouseInformation } from "../../../components/widgets/WidgetWarehouseInformation"
import { WidgetVendorOrderStatus } from "../../../components/widgets/WidgetVendorOrderStatus"
import { WidgetSTAndMemberships } from "../../../components/widgets/WidgetSTAndMemberships"
import { WidgetContractors } from "../../../components/widgets/WidgetContractors"
import classNames from "classnames"

export const Dashboard = () => {
  return (
    <div className={styles.widgetsWrapper}>
      <div className={styles.widgetsRowWrapper}>
        <h2 className={styles.widgetsRowTitle}>Orders</h2>
        <div className={styles.widgetsRow}>
          <WidgetWarehouseInformation />
          <WidgetVendorOrderStatus />
        </div>
      </div>
      <div className={styles.widgetsRowWrapper}>
        <h2 className={styles.widgetsRowTitle}>Devices</h2>
        <div className={styles.widgetsRow}>
          <WidgetPhysicalStatus />
          <WidgetInstaledDevices />
        </div>
      </div>
      <div className={styles.widgetsHalfRowWrapper}>
        <div className={classNames(styles.widgetsRowWrapper, styles.half)}>
          <h2 className={styles.widgetsRowTitle}>Contractors/Integration</h2>
          <div className={styles.widgetsRow}>
            <WidgetContractors />
            <br />
            <WidgetSTAndMemberships />
          </div>
        </div>
        <div className={classNames(styles.widgetsRowWrapper, styles.half)}>
          <h2 className={styles.widgetsRowTitle}>Alerts</h2>
          <div className={styles.widgetsRow}>
            <WidgetVendorAlerts />
            <br />
            <WidgetVendorAlertStatuses />
          </div>
        </div>
      </div>
    </div>
  )
}
