import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes, UITypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"
import { prepareQueryParams } from "../../utils/prepareQueryParams"

export const warehousesApi = createApi({
  reducerPath: "warehouses",
  tagTypes: ["warehouses"],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getWarehouseList: builder.query<ApiTypes.Res.Warehouses, UITypes.Params>({
      query: (arg) => {
        return {
          url: `/warehouses`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    addWarehouse: builder.query<void, ApiTypes.Req.CreateWarehouse>({
      query: (arg) => {
        return {
          url: `/warehouses`,
          method: "POST",
          params: prepareQueryParams(arg),
        }
      },
    }),
    editWarehouse: builder.query<void, { body: ApiTypes.Req.EditWarehouse; id: number }>({
      query: (data) => {
        return {
          url: `/warehouses/${data.id}`,
          method: "PUT",
          params: prepareQueryParams(data.body),
          headers: { Accept: "application/json" },
        }
      },
    }),
  }),
})

export const { useLazyGetWarehouseListQuery, useLazyAddWarehouseQuery, useLazyEditWarehouseQuery } = warehousesApi

export const getWarehouseList = warehousesApi.endpoints.getWarehouseList
