import styles from "../../../assets/scss/index.module.scss"
import { useState, useEffect } from "react"
import classNames from "classnames"

interface Props {
  placeholder?: string
  label?: string
  errorMessage?: string
  className?: string
  value?: string
  name?: string
  autoComplete?: string
  id?: string
  autoFocus?: boolean
  disabled?: boolean
  onChange?: (value) => void
  onFocus?: () => void
  onBlur?: () => void
}

export const InputPhone = ({
  placeholder,
  label,
  autoComplete,
  errorMessage,
  className,
  value,
  autoFocus,
  name,
  id,
  disabled,
  onChange,
  onFocus,
  onBlur,
}: Props) => {
  const handleInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, "")

    if (value.length > 10) {
      value = value.slice(0, 10)
    }

    const formattedPhone = formatPhoneNumber(value)
    onChange?.(formattedPhone)
  }

  const formatPhoneNumber = (value) => {
    if (value.length === 0) {
      return ""
    } else if (value.length <= 3) {
      return `(${value}`
    } else if (value.length <= 6) {
      return `(${value.slice(0, 3)})${value.slice(3)}`
    } else {
      return `(${value.slice(0, 3)})${value.slice(3, 6)}-${value.slice(6)}`
    }
  }

  return (
    <div className={classNames(styles.fieldWrapper, className)}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <div className={styles.field}>
        <input
          className={classNames(styles.inputNew, {
            [styles.error]: errorMessage,
          })}
          type={"text"}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          name={name}
          id={id}
          autoComplete={autoComplete}
          onMouseEnter={onFocus}
          onMouseLeave={onBlur}
          onChange={handleInputChange}
        />
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
