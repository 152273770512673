import styles from "./index.module.scss"
import { FormEvent, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconContractor } from "../../assets/svg/modal-icon-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setCurrentZip, setContractor } from "../../store/tempDataSlise"
import { showEditContractorModal1, showEditContractorModal2, showEditContractorModal3 } from "../../store/modalSlise"
import { ToggleButton } from "../../components/form/ToggleButton"
import { useLazyGetCitiesQuery, useLazyGetZipCodesQuery, useLazyGetStatesQuery } from "../../store/api/dictionaryApi"
import { SelectDynamic } from "../../components/form/SelectDynamic"
import { convertDataToOptions } from "../../utils/convertData"
import { editContractor1Schema, onFormValidate, validationInitialState } from "../../utils/onValidate"

interface Props {
  onCancel: () => void
}

export const EditContractorModal1 = ({ onCancel }: Props) => {
  const contractor = useAppSelector((state) => state.tempData.contractor)
  const dispatch = useAppDispatch()
  const [inputValues, setInputValues] = useState(contractor!)
  const [error, setError] = useState(validationInitialState)
  const currentZip = useAppSelector((state) => state.tempData.currentZip)
  const isSTChecked = contractor?.isSTChecked === "1"

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, editContractor1Schema, setError, true)) {
      dispatch(setContractor({ ...contractor, ...inputValues }))
      dispatch(showEditContractorModal1(false))
      dispatch(showEditContractorModal2(true))
    }
  }

  const onCancelClick = () => {
    onCancel()
    dispatch(setContractor(null))
    dispatch(setCurrentZip(undefined))
  }

  const onAddIntegrationClick = () => {
    dispatch(showEditContractorModal1(false))
    dispatch(showEditContractorModal3(true))
  }

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")

      if (city && state) {
        setInputValues({
          ...inputValues,
          ...{
            city,
            state,
          },
        })
      }
    }
  }, [currentZip])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Next"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          confirmButtonType="submit"
        >
          <ModalHeader title="Edit Contractor" subtitle={`Step ${isSTChecked ? "1/3" : "1/2"}`}>
            <IconContractor />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Company name"
              placeholder="Enter company name"
              name="name"
              value={inputValues.name}
              onChange={onInputChange}
              errorMessage={error.field === "name" ? error.message : ""}
            />
            <InputNew
              label="Brand name"
              placeholder="Enter brand name"
              name="brand"
              value={inputValues.brand}
              onChange={onInputChange}
              errorMessage={error.field === "brand" ? error.message : ""}
            />
            <InputNew
              label="Address 1"
              placeholder="Enter address "
              name="address1"
              value={inputValues.address1}
              onChange={onInputChange}
              errorMessage={error.field === "address1" ? error.message : ""}
            />
            <InputNew
              label="Address 2"
              placeholder="Enter address "
              name="address2"
              value={inputValues.address2}
              onChange={onInputChange}
              errorMessage={error.field === "address2" ? error.message : ""}
            />
            <div className={styles.formCellsWrapper}>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="Zip Code"
                  searchFieldName="code"
                  valueName="id"
                  labelName="code"
                  name="zip"
                  values={inputValues.zip}
                  useLazyQuery={useLazyGetZipCodesQuery}
                  placeholder="Enter Zip Code"
                  onChange={(value) => setInputValues({ ...inputValues, ...{ zip: value } })}
                  errorMessage={error.field === "zip" ? error.message : ""}
                />
              </div>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="State"
                  placeholder="Enter state"
                  name="state"
                  valueName="id"
                  labelName="name"
                  values={inputValues.state}
                  useLazyQuery={useLazyGetStatesQuery}
                  onChange={(value) => setInputValues({ ...inputValues, ...{ state: value } })}
                  errorMessage={error.field === "state" ? error.message : ""}
                />
              </div>
            </div>
            <SelectDynamic
              label="City"
              valueName="id"
              labelName="name"
              name="city"
              useLazyQuery={useLazyGetCitiesQuery}
              placeholder="Enter city"
              values={inputValues.city}
              onChange={(value) => setInputValues({ ...inputValues, ...{ city: value } })}
              errorMessage={error.field === "city" ? error.message : ""}
            />
            <div className={styles.modalLine}></div>
            <div className={styles.toggleFieldWrapper}>
              <ToggleButton id="isDisable" name="isDisable" isChecked={inputValues.isDisable} onChange={onInputChange}>
                Disable contractor
              </ToggleButton>
              {!isSTChecked && (
                <span onClick={onAddIntegrationClick} className={styles.addButton}>
                  Add Integration
                </span>
              )}
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
