import { useEffect, useState, useMemo } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import { Tooltip } from "../../common/Tooltip"
import { v4 as uuidv4 } from "uuid"
import styles from "./index.module.scss"
import { BarChart } from "../../common/BarChart"
import { CheckboxNew } from "../../form/CheckboxNew"
import classNames from "classnames"
import { toast } from "react-toastify"
import { Spinner } from "../../common/Spinner"
import { useLazyGetInventoryQuery } from "../../../store/api/widgetsApi"
import { showNewOrderModal, showAutoRestockModal } from "../../../store/modalSlise"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useNavigate } from "react-router-dom"
import { useLazyGetSettingsQuery, useLazySetAutoRestockQuery, getSettings } from "../../../store/api/contractorsApi"

const initialState = {
  sufficientDays: 0,
  inventoryDevices: 0,
  orderedDevices: 0,
}

export const WidgetInventory = () => {
  const [triggerGetInventory, { isFetching }] = useLazyGetInventoryQuery()
  const [triggerGetSettings, { isFetching: isGetSettingsFetching }] = useLazyGetSettingsQuery()
  const [triggerSetAutoRestock] = useLazySetAutoRestockQuery()
  const [widgetData, setWidgetData] = useState(initialState)
  const [isChecked, setChecked] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectSettings = useMemo(() => getSettings.select(), [])
  const settings = useAppSelector(selectSettings)

  const onNewClick = () => {
    dispatch(showNewOrderModal(true))
    navigate("/orders")
  }

  const onAutoRestock = async () => {
    if (isChecked) {
      try {
        await triggerSetAutoRestock({
          contractor_id: settings?.data?.data?.contractor_id!,
          devices_quantity: settings?.data?.data?.auto_restock?.devices_quantity!,
          is_enabled: 0,
        })
        toast.success("Auto restock has been successfully removed!")
        triggerGetSettings()
      } catch (error) {
        console.error(error)
      }
    } else {
      dispatch(showAutoRestockModal(true))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetInventory()
        if (res?.data) {
          setWidgetData({
            sufficientDays: res?.data?.data?.sufficient_days,
            inventoryDevices: res?.data?.data?.inventory_devices,
            orderedDevices: res?.data?.data?.ordered_devices,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetSettings()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const isEnabled = settings?.data?.data?.auto_restock?.is_enabled
    setChecked(isEnabled == 1 ? true : false)
  }, [settings?.data?.data])

  return (
    <WidgetLayout
      className={styles.widgetInventory}
      title="Inventory"
      buttonText="New order"
      onButtonClick={onNewClick}
    >
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <BarChart
          items={[
            {
              value: widgetData.orderedDevices,
              color: "#3B82F6",
            },
            {
              value: widgetData.inventoryDevices,
              color: "#BFDBFE",
            },
          ]}
        />
        <div className={styles.widgetMainValue}>
          <Tooltip id={uuidv4()} text="SUFFICIENT FOR">
            This indicator gauges sufficiency by averaging Nuve thermostats daily installations over the past 14 days.
            If, for example, the average rate is 10 and there are 150 devices in stock, the widget shows 'Sufficient for
            15 days,' indicating the estimated stock duration based on historical rates.
          </Tooltip>
          <div className={styles.widgetValueWrapper}>
            {widgetData.sufficientDays}
            <span className={styles.valueNote}> Days</span>
          </div>
        </div>
        <footer className={styles.widgetFooter}>
          <div className={styles.widgetFooterItem}>
            <Tooltip id={uuidv4()} text="In Inventory">
              Number of available devices in storage
            </Tooltip>
            <div className={styles.widgetValueWrapper}>{widgetData.inventoryDevices}</div>
          </div>
          <div className={styles.widgetFooterItem}>
            <Tooltip id={uuidv4()} text="Ordered">
              Number of ordered devices{" "}
            </Tooltip>
            <div className={styles.widgetValueWrapper}>{widgetData.orderedDevices}</div>
          </div>
          {/* <div className={styles.widgetFooterItem}>
            <Tooltip id={uuidv4()} text="Auto Restock">
              Feature to automatically generate orders when the number of devices in inventory falls below the threshold
              required for 15 days of normal operation.
            </Tooltip>
            <div className={styles.widgetValueWrapper}>
              <CheckboxNew isChecked={isChecked} id={uuidv4()} onChange={onAutoRestock} />
            </div>
          </div> */}
        </footer>
        {isFetching ||
          (isGetSettingsFetching && (
            <div className={styles.widgetLoader}>
              <Spinner />
            </div>
          ))}
      </div>
    </WidgetLayout>
  )
}
