import React, { useState, useEffect, FormEvent } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconNewOrder } from "../../assets/svg/modal-icon-new-order.svg"
import { ReactComponent as IconDelete } from "../../assets/svg/icon-delete.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import styles from "./index.module.scss"
import dayjs from "dayjs"
import { toast } from "react-toastify"
import {
  useCreateOrderQuery,
  useLazyCreateOrderQuery,
  useLazyGetOrderStatusWidgetQuery,
  useLazyGetVendorOrdersQuery,
} from "../../store/api/vendorApi"
import { InputNumber } from "../../components/form/InputNumber"
import { useLazyGetContractorsQuery } from "../../store/api/contractorsApi"
import { SelectDynamic } from "../../components/form/SelectDynamic"
import { UITypes } from "../../types"
import { useLazyGetWarehouseListQuery } from "../../store/api/warehousesApi"
import { ButtonNew } from "../../components/form/ButtonNew"
import { v4 as uuidv4 } from "uuid"

interface Props {
  onCancel: () => void
}

export const NewVendorOrderModal = ({ onCancel }: Props) => {
  const [triggerCreateOrder, { isFetching }] = useLazyCreateOrderQuery()
  const [triggerWarehouseWidgetInfo] = useLazyGetOrderStatusWidgetQuery()
  const [triggerOrderStatusWidgetInfo] = useLazyGetOrderStatusWidgetQuery()

  const [selectedContractor, setSelectedContractor] = useState<undefined | UITypes.Option[]>(undefined)
  const [selectedWarehouse, setSelectedWarehouse] = useState<undefined | UITypes.Option[]>(undefined)
  const [triggerGetOrders] = useLazyGetVendorOrdersQuery()
  const [quantity, setQuantity] = useState<number[]>([1])

  const onInputChange = (index: number, value: number) => {
    const temp = [...quantity]
    temp[index] = value
    setQuantity(temp)
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (!selectedContractor) toast.error("Please select contractor")
    else if (!selectedWarehouse) toast.error("Please select warehouse")
    else {
      try {
        Promise.all(
          quantity.map(async (value) => {
            const res: any = await triggerCreateOrder(
              {
                quantity: value,
                contractor_id: Number(selectedContractor[0].value),
                warehouse_id: Number(selectedWarehouse[0].value),
                temp: Math.floor(Math.random() * 1000).toString(),
              },
              false,
            )

            if (res?.error) {
              toast.error(res?.error?.data?.message)
              return false
            }
            return true
          }),
        ).then((values) => {
          if (!values.find((value) => value === false)) {
            toast.success("Order has been successfully added!")
            triggerGetOrders({})
            triggerWarehouseWidgetInfo()
            triggerOrderStatusWidgetInfo()
            onCancel()
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  // useEffect(() => {
  //   triggerGetContractors({})
  // }, [])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="570px"
          onConfirm={onSubmit}
          onCancel={onCancel}
          isFetching={isFetching}
          confirmButtonText="Save"
        >
          <ModalHeader title="New order" subtitle="Please input the number of Nuve Thermostats you want to order.">
            <IconNewOrder />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.orderDetails}>
              <div className={styles.orderDetailsRow}>
                <div className={styles.orderDetailsItem}>
                  <span className={styles.orderDetailsLabel}>Contractor ID</span>
                  <span className={styles.orderDetailsValue}>{selectedContractor && selectedContractor[0].value}</span>
                </div>
                <div className={styles.orderDetailsItem}>
                  <span className={styles.orderDetailsLabel}>Creation date</span>
                  <span className={styles.orderDetailsValue}>{dayjs().format("MM-DD-YYYY")}</span>
                </div>
              </div>
              <div className={styles.orderDetailsRow}>
                <div className={styles.orderDetailsItem}>
                  <span className={styles.orderDetailsLabel}>Company Name</span>
                  <SelectDynamic
                    valueName="id"
                    labelName="name"
                    name="contractors"
                    useLazyQuery={useLazyGetContractorsQuery}
                    placeholder="Company name"
                    onChange={setSelectedContractor}
                    className=""
                    dropdownHeight="150px"
                    queryParams={{ isEnabled: 1 }}
                  />
                </div>
                <div className={styles.orderDetailsItem}>
                  <span className={styles.orderDetailsLabel}>Warehouse</span>
                  <SelectDynamic
                    valueName="id"
                    labelName="name"
                    name="warehouses"
                    useLazyQuery={useLazyGetWarehouseListQuery}
                    placeholder="Warehouse"
                    onChange={setSelectedWarehouse}
                    className=""
                    dropdownHeight="150px"
                  />
                </div>
              </div>
            </div>
            {quantity.map((quant, index) => (
              <React.Fragment key={uuidv4()}>
                <div className={styles.separator} />
                <div className={styles.deviceWrapper}>
                  <div className={styles.deviceQtyWrapper}>
                    <span className={styles.deviceName}>Nuve Thermostats</span>
                    <span className={styles.deviceQty}>Qty: {quant}</span>
                  </div>
                  <InputNumber
                    onInputChange={(value) => {
                      onInputChange(index, value)
                    }}
                    value={quant}
                  />

                  {quantity.length > 1 && (
                    <span
                      className={styles.deviceQtyDelete}
                      onClick={() => {
                        const temp = [...quantity]
                        temp.splice(index, 1)
                        setQuantity(temp)
                      }}
                    >
                      <IconDelete />
                    </span>
                  )}
                </div>
              </React.Fragment>
            ))}

            <div className={styles.separator} />
            <div>
              <ButtonNew
                color="blue"
                onClick={() => {
                  const temp = [...quantity]
                  temp.push(1)
                  setQuantity(temp)
                }}
              >
                Add new
              </ButtonNew>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
