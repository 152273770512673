import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { useEffect, useState } from "react"
import { UITypes } from "../../../types"
import styles from "./index.module.scss"
import { WidgetWarehouse } from "../../../components/widgets/WidgetWarehouse"
import { WidgetOrders } from "../../../components/widgets/WidgetOrders"
import { useLazyGetWHOrdersQuery } from "../../../store/api/warehouseManagerApi"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { toast } from "react-toastify"
import { setRequestParams, setSelectedWHOrder } from "../../../store/tempDataSlise"
import { convertWHManagerOrdersToTableRows } from "../../../utils/convertData"
import { showShippingOrderModal, showPackingOrderModal } from "../../../store/modalSlise"
import { FilterTags } from "../../../components/common/FilterTags"
import classNames from "classnames"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Order number",
    type: "sort-search",
    size: "middle",
    name: "order_number",
  },
  {
    title: "Status",
    type: "select",
    size: "middle",
    name: "warehouseManagerOrderStatus",
  },
  {
    title: "Company name",
    type: "sort-search",
    name: "company_name",
  },
  {
    title: "Brand name",
    type: "sort-search",
    name: "brand_name",
  },
  {
    title: "Order quantity",
    type: "sort-search",
    size: "middle",
    name: "order_quantity",
  },
  {
    title: "Contractor ID",
    type: "sort-search",
    size: "middle",
    name: "contractor_id",
  },
]

export const Orders = () => {
  const [triggerGetWHOrders, { isFetching, data }] = useLazyGetWHOrdersQuery()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const dispatch = useAppDispatch()
  const [isFirstRender, setFirstRender] = useState(true)

  const sendRequest = async (params) => {
    try {
      const res: any = await triggerGetWHOrders(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams, isFirstRender])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  const onPackingClick = (id) => {
    const result = data?.data?.find((item) => item.id === id)
    dispatch(setSelectedWHOrder(result))
    dispatch(showPackingOrderModal(true))
  }

  const onShippingClick = (id) => {
    const result = data?.data?.find((item) => item.id === id)
    dispatch(setSelectedWHOrder(result))
    dispatch(showShippingOrderModal(true))
  }

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetOrders />
        <WidgetWarehouse />
      </div>
      <ContentWrapper title="Orders">
        <FilterTags />
        <Table
          tableName="wh-orders"
          headerCells={tableHeaderCells}
          isLoading={isFetching}
          bodyRows={convertWHManagerOrdersToTableRows(data?.data)}
          dynamicNavHandler1={onPackingClick}
          dynamicNavHandler2={onShippingClick}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
