import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"

export const widgetsApi = createApi({
  reducerPath: "widgets",
  tagTypes: ["widgets"],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getInventory: builder.query<{ data: ApiTypes.Res.InventoryWidget }, void>({
      query: () => ({
        url: `/dashboard/widgets/inventory`,
        method: "GET",
      }),
    }),
    getMonitoring: builder.query<{ data: ApiTypes.Res.MonitoringWidget }, void>({
      query: () => ({
        url: `/dashboard/widgets/monitoring`,
        method: "GET",
      }),
    }),
    getStatus: builder.query<{ data: ApiTypes.Res.StatusWidget }, void>({
      query: () => ({
        url: `/dashboard/widgets/status`,
        method: "GET",
      }),
    }),
  }),
})

export const { useLazyGetInventoryQuery, useLazyGetMonitoringQuery, useLazyGetStatusQuery } = widgetsApi
