import styles from "../../../assets/scss/index.module.scss"
import { useEffect, useState } from "react"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { showServiceTitanModal, showEditContractorModal1, showEditColumnsModal } from "../../../store/modalSlise"
import { setContractor, setRequestParams, setCurrentTableColumns } from "../../../store/tempDataSlise"
import { ApiTypes, UITypes } from "../../../types"
import { useLazyGetContractorsQuery, useLazyGetContractorQuery } from "../../../store/api/contractorsApi"
import { convertContractorsToTableRows, convertDataToOptions } from "../../../utils/convertData"
import { useLazyGetTableColumnsQuery } from "../../../store/api/dashboardApi"
import { toast } from "react-toastify"
import { FilterTags } from "../../../components/common/FilterTags"
import { reorderTableHeaderCells } from "../../../utils/reorderTabbleColumns"

export const Contractors = () => {
  const dispatch = useAppDispatch()
  const [triggerGetContractors, { isFetching, data }] = useLazyGetContractorsQuery()
  const [triggerGetContractor, { isFetching: isContractorFetching }] = useLazyGetContractorQuery()
  const [triggerGetTableColumns, { isFetching: isColumnsFetching, data: columnsData }] = useLazyGetTableColumnsQuery()

  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [isFirstRender, setFirstRender] = useState(true)

  const [tableHeaderCells, setTableHeaderCells] = useState<UITypes.TableHeaderCell[]>([
    {
      title: "Company name",
      type: "sort-search",
      name: "name",
      id: 1,
    },
    {
      title: "State",
      type: "sort-search",
      name: "state",
      id: 2,
    },
    {
      title: "Brand Name",
      type: "sort-search",
      name: "brand",
      id: 3,
    },
    {
      title: "Purchased Devices",
      type: "sort-search",
      name: "purchased",
      id: 4,
    },
    {
      title: "Installed Devices",
      type: "sort-search",
      name: "installed",
      id: 5,
    },
    {
      title: "Inventory",
      type: "sort-search",
      name: "inventory",
      id: 6,
    },
    {
      title: "Status",
      type: "select",
      name: "contractorStatus",
      id: 7,
    },
  ])

  const onAddClick = () => {
    dispatch(showServiceTitanModal(true))
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetContractors(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const parseApiDataToContractor = (contractor: ApiTypes.Model.Contractor) => ({
    id: contractor.id,
    name: contractor.name,
    brand: contractor.brand,
    address1: contractor.address1,
    address2: contractor.address2 || "",
    zip: convertDataToOptions({ data: [contractor.zip] }, "id", "code"),
    state: convertDataToOptions({ data: [contractor.state] }, "id", "name"),
    city: convertDataToOptions({ data: [contractor.city] }, "id", "name"),
    isDisable: !contractor.is_enabled,
    isSTChecked: contractor.is_st_checked,
    logo: contractor.logo,
    logo2String: contractor.logo_w2h1,
    url: contractor.url,
    schedule: contractor.schedule,
    fullName: contractor.full_name,
    phone: contractor.tel1,
    email: contractor.email,
    isCustom: !!contractor.is_custom,
    appStoreLink: contractor.app_store_link || "",
    googlePlayLink: contractor.google_play_link || "",
    phoneForSupport: !!contractor.phone_for_support,
    phoneForSupportCall: contractor.phone_for_support_call || "",
    phoneForSupportText: contractor.phone_for_support_text || "",
    stClientID: contractor.st_client_id,
    stClientSecret: contractor.st_client_secret,
    stAppKey: contractor.st_app_key,
    stTenant: contractor.st_tenant,
    memberships: contractor.memberships,
  })

  const onEditClick = async (id: number) => {
    try {
      const res = await triggerGetContractor(`${id}`)
      if (res.data?.data) {
        dispatch(setContractor(parseApiDataToContractor(res.data?.data)))
        dispatch(showEditContractorModal1(true))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "admin_contractors_list",
        columns: tableHeaderCells,
      }),
    )
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
    triggerGetTableColumns({ list_name: "admin_contractors_list" })
  }, [requestParams, isFirstRender])

  useEffect(() => {
    if (columnsData?.data?.config) {
      setTableHeaderCells(reorderTableHeaderCells(tableHeaderCells, columnsData?.data?.config))
    }
  }, [columnsData])

  // componentWillUnmount hook
  useEffect(() => {
    dispatch(setRequestParams({ isEnabled: { value: "1", label: "Status", type: "select" } }))

    return () => {
      dispatch(setRequestParams(null))
    }
  }, [])

  return (
    <>
      <ContentWrapper title="Contractors">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onAddClick} color="blue">
              Add contractor
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
              Edit columns
            </span>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <FilterTags />
        <Table
          tableName="contractors"
          areHeaderColumnsDynamic
          headerCells={tableHeaderCells}
          bodyRows={convertContractorsToTableRows(data?.data, tableHeaderCells)}
          isLoading={isFetching || isContractorFetching || isColumnsFetching}
          onEditClick={onEditClick}
          // dynamicNavHandler1={onArchive}
          // dynamicNavHandler2={onUnarchive}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
