import styles from "./index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconMissing } from "../../assets/svg/modal-icon-missing.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useLazyWarrantyQuery } from "../../store/api/techniciansApi"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { toast } from "react-toastify"
import { setWarrantySNs } from "../../store/tempDataSlise"
import { showAlertWarrantyModal } from "../../store/modalSlise"

interface Props {
  onCancel: () => void
}

export const AlertsWarrantyModal = ({ onCancel }: Props) => {
  const [triggerWarranty, { isFetching }] = useLazyWarrantyQuery()
  const dispatch = useAppDispatch()
  const warrantySNs = useAppSelector((state) => state.tempData.warrantySNs)

  const onSubmit = async (event) => {
    event.preventDefault()

    if (!warrantySNs) return
    try {
      const res: any = await triggerWarranty({
        old_sn: warrantySNs?.oldSN,
        new_sn: warrantySNs?.newSN,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        dispatch(
          setWarrantySNs({
            oldSN: "",
            newSN: "",
          }),
        )
        dispatch(showAlertWarrantyModal(false))
        toast.success("The new thermostat has successfully integrated into the system")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          isFetching={isFetching}
          onConfirm={onSubmit}
          onCancel={onCancel}
          confirmButtonText="OK"
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconMissing />
            <div className={classNames(styles.modalMessage, styles.contentWrapper)}>
              {/* Customer: [Full Name] */}
              {/* Address: [Address1 + Address 2] */}
              <br />
              Upon confirmation, the Customer’s damaged device will be marked as “Warranty” and its data will be cloned
              to the new replacement device.
              <br />
              <br />
              Are you sure you want to proceed with the replacement?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
