import styles from "./index.module.scss"
import { PropsWithChildren } from "react"
import classNames from "classnames"
import { UITypes } from "../../../types"

interface Props extends PropsWithChildren, UITypes.ButtonNew {}

export const ButtonNew = ({
  color = "blueBordered",
  size = "auto",
  children,
  type,
  disabled,
  className,
  text,
  onClick,
}: Props) => {
  const attributes = {
    type,
    disabled,
    onClick,
    className: classNames(className, styles.buttonNew, {
      [styles[color]]: color,
      [styles[size]]: size,
    }),
  }

  return type === "submit" ? (
    <button {...attributes}>
      {text} {children}
    </button>
  ) : (
    <span {...attributes}>
      {text} {children}
    </span>
  )
}
