import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconFreeze } from "../../assets/svg/modal-icon-freeze.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useLazyPostAlertFreezeQuery } from "../../store/api/alertsApi"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setCurrentAlert } from "../../store/tempDataSlise"
import { toast } from "react-toastify"
import {
  useLazyGetAdminAlertsQuery,
  useLazyGetVendorAlertAndDeviceWidgetQuery,
  useLazyGetVendorStatusesWidgetQuery,
} from "../../store/api/adminApi"

interface Props {
  onCancel?: () => void
}

export const AlertsFreezeModal = ({ onCancel }: Props) => {
  const [triggerAlertFreeze, { isFetching }] = useLazyPostAlertFreezeQuery()
  const [triggerGetVendorStatusesWidget] = useLazyGetVendorStatusesWidgetQuery()
  const [triggerGetVendorAlertAndDeviceWidget] = useLazyGetVendorAlertAndDeviceWidgetQuery()
  const [triggerGetAdminAlerts] = useLazyGetAdminAlertsQuery()
  const dispatch = useAppDispatch()
  const currentAlert = useAppSelector((state) => state.tempData.currentAlert)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerAlertFreeze(currentAlert?.id as number)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Alert has been successfully frozen!")
        dispatch(setCurrentAlert(undefined))
        triggerGetAdminAlerts(requestParams === null ? {} : requestParams)
        triggerGetVendorStatusesWidget()
        triggerGetVendorAlertAndDeviceWidget()
        onCancel && onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconFreeze />
            <h3 className={styles.modalTitle}>Freeze</h3>
            <div className={styles.modalMessage}>
              By freezing the alert, it will no longer be received. Are you sure you want to proceed with freezing this
              alert?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
