import styles from "./index.module.scss"
import { useState, useEffect } from "react"
import { UITypes } from "../../../types"
import { ReactComponent as IconTableSearch } from "../../../assets/svg/icon-table-search.svg"
import { ReactComponent as IconTableCross } from "../../../assets/svg/icon-table-cross.svg"
import { setRequestParams } from "../../../store/tempDataSlise"
import debounce from "lodash.debounce"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"

interface Props {
  name?: string
  label?: string
}

export const TableSearch = ({ name, label }: Props) => {
  const [isActive, setActive] = useState(false)
  const dispatch = useAppDispatch()
  const [value, setValue] = useState("")
  const requestParams = useAppSelector((state) => state.tempData.requestParams)

  const onClear = () => {
    setValue("")
    name && dispatch(setRequestParams({ [name]: null }))
    setActive(false)
  }

  const onInputChange = (value) => {
    name &&
      dispatch(
        setRequestParams({
          [name]: {
            value,
            label,
          } as UITypes.Param,
        }),
      )
  }

  useEffect(() => {
    if (name && requestParams?.[name] && requestParams[name]?.value && !value) {
      setValue(requestParams[name]?.value)
      setActive(true)
    } else if (requestParams && name && Object.hasOwn(requestParams, name) && requestParams[name] === null) {
      setValue("")
      setActive(false)
    }
  }, [requestParams])

  const setDebaunceSearchValue = debounce(onInputChange, 500)

  return (
    <div className={styles.tableSearchWrapper} onClick={() => {}}>
      {isActive ? (
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputSearch}
            placeholder="Search.."
            autoFocus
            defaultValue={value}
            onChange={(event) => setDebaunceSearchValue(event.currentTarget?.value)}
            name={name}
          />
          <span className={styles.iconClear} onClick={onClear}>
            <IconTableCross />
          </span>
        </div>
      ) : (
        <span className={styles.iconSearch} onClick={() => setActive(true)}>
          <IconTableSearch />
        </span>
      )}
    </div>
  )
}
