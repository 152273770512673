import styles from "./index.module.scss"
import { useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconPacking } from "../../assets/svg/modal-icon-packing.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { UITypes } from "../../types"
import { v4 as uuidv4 } from "uuid"
import { InputSN } from "../../components/form/InputSN"
import classNames from "classnames"
import { useAppSelector } from "../../store/hooks"
import { useLazyPackDevicesQuery, useLazyGetWHOrdersQuery } from "../../store/api/warehouseManagerApi"
import { convertValidatedDevicesToState } from "../../utils/convertData"
import { toast } from "react-toastify"

interface Props {
  onCancel: () => void
}

export const PackingDeviceModal = ({ onCancel }: Props) => {
  const [triggerPackDevices, { isFetching }] = useLazyPackDevicesQuery()
  const [triggerGetWHOrders] = useLazyGetWHOrdersQuery()
  const selectedWHOrder = useAppSelector((state) => state.tempData.selectedWHOrder)
  const [isNoDataError, setNoDataError] = useState(false)
  const [validatedDevices, setValidatedDevices] = useState<UITypes.InvalidDevice[]>([
    {
      uid: uuidv4(),
      sn: "",
      errorMessage: "",
    },
  ])

  const onItemDelete = (id: string) => {
    const result = validatedDevices?.filter((item) => item.uid !== id)
    setValidatedDevices(result)
  }

  const checkDuplicates = (devices: UITypes.InvalidDevice[], currentItem: UITypes.InvalidDevice) => {
    const snCount: Record<string, number> = {}

    devices.forEach((item) => {
      if (item.sn && item.uid !== currentItem.uid) {
        snCount[item.sn] = (snCount[item.sn] || 0) + 1
      }
    })

    const isDuplicate = currentItem.sn && snCount[currentItem.sn] > 0

    return devices.map((item) =>
      item.uid === currentItem.uid ? { ...item, errorMessage: isDuplicate ? `Duplicate sn: ${item.sn}` : "" } : item,
    )
  }

  const onItemBlur = (data: UITypes.InvalidDevice) => {
    const updatedDevices = validatedDevices?.map((item) => (item.uid === data.uid ? data : item))

    const result = checkDuplicates(updatedDevices || [], data)

    setValidatedDevices(result)
  }

  const sendRequest = async (devices) => {
    try {
      const res: any = await triggerPackDevices({ body: { serial_numbers: devices }, id: selectedWHOrder?.id! })
      if (res?.data?.data?.failed?.length) {
        setValidatedDevices(convertValidatedDevicesToState(res?.data?.data?.failed) as any)
      } else {
        triggerGetWHOrders({})
        toast.success("Successfully packed")
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const serialNumbers = []

    validatedDevices.forEach((item) => {
      if (item.sn) {
        serialNumbers.push(item.sn as never)
      }
    })

    if (!serialNumbers?.length) {
      setNoDataError(true)
      return
    }

    setNoDataError(false)
    sendRequest(serialNumbers)
  }

  // ADD LAST INPUT
  useEffect(() => {
    if (validatedDevices?.length === selectedWHOrder?.order_quantity) return
    if (validatedDevices[validatedDevices?.length - 1]?.sn != "") {
      setValidatedDevices([
        ...validatedDevices,
        ...[
          {
            uid: uuidv4(),
            sn: "",
            errorMessage: "",
          },
        ],
      ])
    }
  }, [validatedDevices])

  const checkSubmitAvailability = (): boolean => {
    if (validatedDevices?.length !== selectedWHOrder?.order_quantity) {
      return true
    }
    if (validatedDevices?.some((item) => item?.sn === "")) {
      return true
    }
    return false
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          buttonSize="auto"
          // onExport={() => {}}
          onCancel={onCancel}
          onConfirm={onSubmit}
          isDisabled={checkSubmitAvailability()}
          isFetching={isFetching}
        >
          <ModalHeader title="Packing">
            <IconPacking />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.dataWrapper}>
              <div className={styles.dataItem}>
                <h3 className={styles.dataItemTitle}>Contractor’s name</h3>
                <span className={styles.dataItemText}>{selectedWHOrder?.company_name}</span>
              </div>
              <div className={styles.dataItem}>
                <h3 className={styles.dataItemTitle}>Order Quantity</h3>
                <span className={styles.dataItemText}>{selectedWHOrder?.order_quantity}</span>
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={classNames(styles.dataWrapper, styles.devicesInfo)}>
              <div className={styles.devicesInfoItem}>
                <h3 className={styles.dataItemTitle}>Added Devices</h3>
                <span className={styles.dataItemText}>{validatedDevices?.length}</span>
              </div>
            </div>
            {Boolean(validatedDevices?.length) &&
              validatedDevices.map((item, index) => (
                <InputSN
                  {...{ ...item, ...{ row: index + 1 } }}
                  key={uuidv4()}
                  onBlur={onItemBlur}
                  onDelete={onItemDelete}
                  autoFocus={validatedDevices?.length == index + 1 ? true : false}
                />
              ))}
            {isNoDataError && (
              <div className={classNames(styles.errorMessage, styles.errorMessageFullWith)}>
                Please upload the file or add the S/N manually
              </div>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
