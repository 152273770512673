import styles from "../../../../assets/scss/index.module.scss"
import { Helmet } from "react-helmet"
import { Input } from "../../../../components/form/Input"
import { AuthForm } from "../../../../components/form/AuthForm"
import { Button } from "../../../../components/form/Button"
import { useState, FormEvent, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { onFormValidate, validationInitialState, changePassword } from "../../../../utils/onValidate"
import { useLazyResetPasswordQuery } from "../../../../store/api/authApi"
import { toast } from "react-toastify"
import { Spinner } from "../../../../components/common/Spinner"
import { storageKeys } from "../../../../utils/storageKeys"

const inputValuesInitialState = {
  newPassword: "",
  confirmationPassword: "",
}

export const ChangePassword = () => {
  const [triggerResetPassword, { isFetching, data }] = useLazyResetPasswordQuery()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get("token")
  const email = searchParams.get("email")

  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, changePassword, setError)) {
      try {
        const res: any = await triggerResetPassword({
          email: email!,
          token: token!,
          password: inputValues.newPassword,
          password_confirmation: inputValues.confirmationPassword,
        })

        if (res?.error?.data) {
          toast.error(`Server error: ${res?.error?.data?.message}`)
        }
      } catch (error: any) {
        toast.error(error?.message)
      }
    }
  }

  useEffect(() => {
    if (data?.token) {
      setInputValues(inputValuesInitialState)
      localStorage.setItem(storageKeys.token, data?.token)
      toast.success("Password was successfully changed")

      setTimeout(() => {
        navigate("/")
      }, 500)
    }
  }, [data?.token])

  return (
    <>
      <Helmet>
        <title>Nuve - Change Password</title>
      </Helmet>
      <div>
        <AuthForm subtitle="Must be at least 8 characters." title="Change password" onSubmit={onSubmit}>
          <Input
            label="New Password"
            placeholder="Enter password"
            inputType="password"
            name="newPassword"
            value={inputValues.newPassword}
            onChange={onInputChange}
            errorMessage={error.field === "newPassword" ? error.message : ""}
          />
          <Input
            label="Confirm new password"
            placeholder="Enter password"
            inputType="password"
            name="confirmationPassword"
            value={inputValues.confirmationPassword}
            onChange={onInputChange}
            errorMessage={error.field === "confirmationPassword" ? error.message : ""}
          />
          <div className={styles.authButtonsWrapper}>
            <Button onClick={onSubmit} type="submit" disabled={isFetching} color="colored" size="fullWidth">
              {isFetching ? <Spinner width={38} height={38} /> : "Submit"}
            </Button>
          </div>
        </AuthForm>
      </div>
    </>
  )
}
