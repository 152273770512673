import { ReactComponent as IconMinus } from "../../../assets/svg/icon-minus.svg"
import { ReactComponent as IconPlus } from "../../../assets/svg/icon-plus.svg"
import styles from "./index.module.scss"
import { useRef, useEffect } from "react"

interface Props {
  value: number
  onInputChange: (value: number) => void
}

export const InputNumber = ({ onInputChange, value }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  })

  return (
    <div className={styles.inputNumberWrapper}>
      <span className={styles.inputNumberButton} onClick={() => onInputChange(value - 1)}>
        <IconMinus />
      </span>
      <input
        value={value}
        ref={inputRef}
        type="number"
        className={styles.inputNumber}
        onChange={(event) => onInputChange(Number(event?.currentTarget?.value))}
      />
      <span className={styles.inputNumberButton} onClick={() => onInputChange(value + 1)}>
        <IconPlus />
      </span>
    </div>
  )
}
