import styles from "./index.module.scss"
import classNames from "classnames"
import { ReactComponent as IconHide } from "../../../assets/svg/eyeClosed.svg"
import { ReactComponent as IconShow } from "../../../assets/svg/eyeOpened.svg"
import { CSS } from "@dnd-kit/utilities"
import { useSortable } from "@dnd-kit/sortable"
import { UITypes } from "../../../types"

interface Props extends UITypes.TableHeaderCell {
  onVisibilityChange: (value: number) => void
}

export const DADItem = ({ id, name, title, visibility, onVisibilityChange }: Props) => {
  if (!id) return null

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const toggleVisibility = (e) => {
    e.stopPropagation()
    onVisibilityChange(id)
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classNames(styles.draggableItem, {
        [styles.dragging]: isDragging,
      })}
    >
      <span className={classNames({ [styles.hidden]: !visibility })}>{title || name}</span>
      <span className={styles.toggleHide} onPointerDown={toggleVisibility}>
        {visibility ? <IconShow /> : <IconHide />}
      </span>
    </div>
  )
}
