import styles from "./index.module.scss"
import { Button } from "../../form/Button"
import { useNavigate } from "react-router-dom"

export function NotFound() {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const goHome = () => {
    navigate("/")
  }

  return (
    <section className={styles.errorBoundaryWrapper}>
      <div className={styles.errorBoundary}>
        <h2 className={styles.errorBoundaryMessage}>
          <span>404</span>
          This page doesn't exist.
        </h2>
        <div className={styles.buttonsWrapper}>
          <Button onClick={goBack} size="halfWidth" color="white">
            Go Back
          </Button>
          <Button onClick={goHome} size="halfWidth" color="colored">
            Home page
          </Button>
        </div>
      </div>
    </section>
  )
}
