import { useEffect, useState } from "react"
import styles from "./index.module.scss"
import classNames from "classnames"
import { BarChart } from "../../common/BarChart"
import { useLazyGetVendorStatusesWidgetQuery } from "../../../store/api/adminApi"
import { Spinner } from "../../common/Spinner"

const initialState = {
  active: 0,
  frozen: 0,
  acknowledged: 0,
}

export const WidgetVendorAlertStatuses = () => {
  const [triggergetVendorStatusesWidget, { isFetching, data }] = useLazyGetVendorStatusesWidgetQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggergetVendorStatusesWidget()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      setWidgetData({
        active: data?.data?.active,
        frozen: data?.data?.frozen,
        acknowledged: data?.data?.acknowledged,
      })
    }
  }, [data])

  return (
    <div className={classNames(styles.widgetWrapper)}>
      <header className={styles.widgetHeader}>
        <h3 className={styles.widgetTitle}>Alerts Statuses</h3>
      </header>
      <div className={styles.chartWrapper}>
        <BarChart
          items={[
            {
              color: "#22C55E",
              value: widgetData.active,
            },
            {
              color: "#475569",
              value: widgetData.frozen,
            },
            {
              color: "#2DB2C8",
              value: widgetData.acknowledged,
            },
          ]}
        />
        <div className={styles.indicatorsWrapper}>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#22C55E",
              }}
            />
            Active
            <i>{widgetData.active}</i>
          </span>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#475569",
              }}
            />
            Frozen
            <i>{widgetData.frozen}</i>
          </span>
          <span className={styles.indicator}>
            <i
              className={styles.colorCircle}
              style={{
                backgroundColor: "#2DB2C8",
              }}
            />
            Acknowledged
            <i>{widgetData.acknowledged}</i>
          </span>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
