import React, { useState, useRef, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconNewOrder } from "../../assets/svg/modal-icon-new-order.svg"
import { ReactComponent as IconMinus } from "../../assets/svg/icon-minus.svg"
import { ReactComponent as IconPlus } from "../../assets/svg/icon-plus.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import styles from "./index.module.scss"
import { toast } from "react-toastify"
import { useAppSelector } from "../../store/hooks"
import dayjs from "dayjs"
import { SelectStatic } from "../../components/form/SelectStatic"
import { useLazyGetWarehouseListQuery } from "../../store/api/warehousesApi"
import { ApiTypes, UITypes } from "../../types"
import { ButtonNew } from "../../components/form/ButtonNew"
import { v4 as uuidv4 } from "uuid"
import { ReactComponent as IconDelete } from "../../assets/svg/icon-delete.svg"
import {
  useLazyCreateOrderQuery,
  useLazyEditOrderQuery,
  useLazyGetOrderStatusWidgetQuery,
  useLazyGetVendorOrdersQuery,
} from "../../store/api/vendorApi"

interface Props {
  onCancel: () => void
}

const statusOptions = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "confirmed",
    label: "Confirmed",
  },
  {
    value: "denied",
    label: "Denied",
  },
] as UITypes.Option[]

type SubOrder = {
  quantity: number
  status?: "pending" | "confirmed" | "denied"
  warehouse_id?: number
}

export const EditVendorOrderModal = ({ onCancel }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [triggerEditOrder] = useLazyEditOrderQuery()
  const [triggerGetOrders] = useLazyGetVendorOrdersQuery()
  const [triggerCreateOrder] = useLazyCreateOrderQuery()
  const [triggerWarehouseWidgetInfo] = useLazyGetOrderStatusWidgetQuery()
  const [triggerOrderStatusWidgetInfo] = useLazyGetOrderStatusWidgetQuery()
  const [triggerGetWarehouseList, { data: warehouseList, isFetching: isFetchingWarehouses }] =
    useLazyGetWarehouseListQuery()

  const currentOrder = useAppSelector((state) => state.tempData.currentOrder)
  const [newOrders, setNewOrders] = useState<SubOrder[]>([])
  const [originalQuantity, setOriginalQuantity] = useState(currentOrder?.quantity!)
  const [quantity, setQuantity] = useState(currentOrder?.quantity!)

  const [selectedWarehouse, setSelectedWarehouse] = useState<number | undefined>()
  const [selectedStatus, setSelectedStatus] = useState<ApiTypes.Model.OrderStatus | undefined>()
  const [warehouseOptions, setWarehouseOptions] = useState<UITypes.Option[]>([])

  useEffect(() => {
    if (currentOrder) {
      setSelectedStatus(currentOrder.status)
      setSelectedWarehouse(currentOrder.warehouse_id)
    }
  }, [currentOrder])

  useEffect(() => {
    triggerGetWarehouseList({})
  }, [])

  useEffect(() => {
    if (warehouseList) {
      const warehouses = [] as UITypes.Option[]
      warehouseList.data.forEach((warehouse) => {
        warehouses.push({
          value: warehouse.id,
          label: warehouse.name,
        } as UITypes.Option)
      })
      setWarehouseOptions(warehouses)
    }
  }, [warehouseList])

  const onInputChange = (index: number, value: number) => {
    if (value > originalQuantity - newOrders.length) value = originalQuantity - newOrders.length

    if (index >= 0) {
      const tempOrders = [...newOrders]
      let delta = value - tempOrders[index].quantity
      let origQuantity = quantity

      tempOrders[index].quantity = value
      const tempOrder = tempOrders[index]
      tempOrders.splice(index, 1)
      tempOrders.sort((a, b) => Math.sign(delta) * (b.quantity - a.quantity))

      while (delta != 0) {
        tempOrders.forEach((order, ind) => {
          if (delta < 0 || order.quantity > 1) {
            tempOrders[ind].quantity = order.quantity - Math.sign(delta)
            delta = delta - Math.sign(delta)
          }
        })
        if (delta < 0 || origQuantity > 1) {
          origQuantity = origQuantity - Math.sign(delta)
          delta = delta - Math.sign(delta)
        }
      }

      tempOrders.splice(index, 0, tempOrder)
      setNewOrders(tempOrders)
      setQuantity(origQuantity)
    } else {
      let delta = value - quantity
      setQuantity(value)

      if (newOrders.length > 0) {
        const tempOrders = [...newOrders]
        tempOrders.sort((a, b) => Math.sign(delta) * (b.quantity - a.quantity))
        while (delta != 0) {
          tempOrders.forEach((order, ind) => {
            if (delta < 0 || order.quantity > 1) {
              tempOrders[ind].quantity = order.quantity - Math.sign(delta)
              delta = delta - Math.sign(delta)
            }
          })
        }
        setNewOrders(tempOrders)
      }
    }
  }

  const splitOrder = () => {
    if (selectedStatus != "denied") {
      if (newOrders.length < 10 && Math.floor(originalQuantity / (newOrders.length + 2)) > 0) {
        const partQuantity = Math.floor(originalQuantity / (newOrders.length + 2))
        setQuantity(originalQuantity - (newOrders.length + 1) * partQuantity)

        const tempOrders = [] as SubOrder[]
        newOrders.forEach((order) => {
          tempOrders.push({ ...order, quantity: partQuantity })
        })
        tempOrders.push({
          quantity: partQuantity,
          status: "confirmed",
        })
        setNewOrders(tempOrders)
      }
    }
  }

  const removeRow = (index: number) => {
    const temp = [...newOrders]
    const q = temp[index].quantity
    temp.splice(index, 1)
    setNewOrders(temp)
    setQuantity(quantity + q)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    let errors = false
    if (currentOrder && warehouseList) {
      const warehouse = warehouseList.data.find((warehouse) => warehouse.id === selectedWarehouse)
      if (selectedStatus == "confirmed") {
        if (!warehouse || warehouse.devices_qty < quantity) {
          toast.error("Insufficient number of available devices")
          errors = true
        }
      }

      newOrders.forEach((item) => {
        const warehouse = warehouseList.data.find((warehouse) => item.warehouse_id === warehouse.id)
        if (!warehouse || warehouse.devices_qty < item.quantity) {
          toast.error("Insufficient number of available devices")
          errors = true
        }
      })

      if (!errors) {
        try {
          const res: any = await triggerEditOrder({
            id: currentOrder.id,
            data: {
              quantity: quantity,
              status: selectedStatus!,
              warehouse_id: selectedWarehouse!,
            },
          })

          if (res?.error) {
            toast.error(res?.error?.data?.message)
          } else {
            Promise.all(
              newOrders.map(async (order) => {
                const res: any = await triggerCreateOrder({
                  quantity: order.quantity,
                  contractor_id: currentOrder.contractor_id!,
                  warehouse_id: order.warehouse_id!,
                  status: order.status,
                  temp: Math.floor(Math.random() * 1000).toString(),
                })

                if (res?.error) {
                  toast.error(res?.error?.data?.message)
                  return false
                }
                return true
              }),
            ).then((values) => {
              if (!values.find((value) => value === false)) {
                toast.success("Order has been successfully updated!")
                triggerGetOrders({})
                triggerWarehouseWidgetInfo()
                triggerOrderStatusWidgetInfo()
                onCancel()
              }
            })
          }
        } catch (err) {
          console.error(err)
        }
      }
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="865px" onConfirm={onSubmit} onCancel={onCancel} isFetching={isFetchingWarehouses}>
          <ModalHeader
            title="Edit order"
            subtitle="Please select the status and the warehouse, or split the order into smaller parts if necessary."
          >
            <IconNewOrder />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.orderDetails}>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Order Number</span>
                <span className={styles.orderDetailsValue}>{currentOrder?.id}</span>
              </div>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Creation date</span>
                <span className={styles.orderDetailsValue}>
                  {dayjs(currentOrder?.create_date).format("MM-DD-YYYY")}
                </span>
              </div>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Brand name</span>
                <span className={styles.orderDetailsValue}>{currentOrder?.brand_name}</span>
              </div>
              <div className={styles.orderDetailsItem}>
                <span className={styles.orderDetailsLabel}>Company name</span>
                <span className={styles.orderDetailsValue}>{currentOrder?.company_name}</span>
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.deviceWrapper}>
              <div className={styles.deviceQtyWrapper}>
                <span className={styles.deviceName}>Nuve Thermostats</span>
                <span className={styles.deviceQty}>Qty: {quantity}</span>
              </div>
              <div className={styles.orderInputWrapper}>
                <span className={styles.orderInputButton} onClick={() => onInputChange(-1, Math.max(1, quantity - 1))}>
                  <IconMinus />
                </span>
                <input
                  value={quantity}
                  type="number"
                  className={styles.orderInput}
                  onChange={(event) => onInputChange(-1, Number(event?.currentTarget?.value))}
                />
                <span className={styles.orderInputButton} onClick={() => onInputChange(-1, quantity + 1)}>
                  <IconPlus />
                </span>
              </div>
              <div className={styles.orderInputWrapper}>
                <SelectStatic
                  name="statuses"
                  portal={document.body}
                  values={
                    statusOptions.find((status) => status.value === selectedStatus) &&
                    ([statusOptions.find((status) => status.value === selectedStatus)] as UITypes.Option[])
                  }
                  options={statusOptions}
                  onChange={(option) => {
                    option.length > 0 && setSelectedStatus(option[0].value as ApiTypes.Model.OrderStatus)
                  }}
                  minWidth="156px"
                />
              </div>
              <div className={styles.orderInputWrapper}>
                <SelectStatic
                  name="warehouses"
                  portal={document.body}
                  disabled={selectedStatus !== "confirmed"}
                  values={
                    warehouseOptions.find((warehouse) => warehouse.value === selectedWarehouse) &&
                    ([warehouseOptions.find((warehouse) => warehouse.value === selectedWarehouse)] as UITypes.Option[])
                  }
                  options={warehouseOptions}
                  onChange={(option) => {
                    option.length > 0 && setSelectedWarehouse(Number(option[0].value))
                  }}
                  minWidth="156px"
                />
              </div>
              <div className={styles.orderInputWrapper}>
                <ButtonNew disabled={quantity > 1 && newOrders.length < 10} color="greyBordered" onClick={splitOrder}>
                  Split
                </ButtonNew>
              </div>
            </div>

            {newOrders.map((order, index) => (
              <React.Fragment key={uuidv4()}>
                <div className={styles.separator} />
                <div className={styles.deviceWrapper}>
                  <div className={styles.deviceQtyWrapper}>
                    <span className={styles.deviceName}>Nuve Thermostats</span>
                    <span className={styles.deviceQty}>Qty: {order.quantity}</span>
                  </div>
                  <div className={styles.orderInputWrapper}>
                    <span
                      className={styles.orderInputButton}
                      onClick={() => {
                        onInputChange(index, Math.max(order.quantity - 1, 1))
                      }}
                    >
                      <IconMinus />
                    </span>
                    <input
                      value={order.quantity}
                      type="number"
                      className={styles.orderInput}
                      onChange={(event) => onInputChange(index, Number(event?.currentTarget?.value))}
                    />
                    <span
                      className={styles.orderInputButton}
                      onClick={() => {
                        onInputChange(index, Math.max(order.quantity + 1))
                      }}
                    >
                      <IconPlus />
                    </span>
                  </div>
                  <div className={styles.orderInputWrapper}>
                    <SelectStatic
                      name="statuses"
                      portal={document.body}
                      values={
                        statusOptions.find((status) => status.value === order.status) &&
                        ([statusOptions.find((status) => status.value === order.status)] as UITypes.Option[])
                      }
                      options={statusOptions}
                      onChange={(option) => {
                        if (option.length > 0) {
                          const tempOrders = [...newOrders]
                          tempOrders[index].status = option[0].value as any
                          setNewOrders(tempOrders)
                        }
                      }}
                      minWidth="156px"
                    />
                  </div>
                  <div className={styles.orderInputWrapper}>
                    <SelectStatic
                      name="warehouses"
                      portal={document.body}
                      values={
                        warehouseOptions.find((warehouse) => warehouse.value === order.warehouse_id) &&
                        ([
                          warehouseOptions.find((warehouse) => warehouse.value === order.warehouse_id),
                        ] as UITypes.Option[])
                      }
                      disabled={newOrders[index].status !== "confirmed"}
                      options={warehouseOptions}
                      onChange={(option) => {
                        if (option.length > 0) {
                          const tempOrders = [...newOrders]
                          tempOrders[index].warehouse_id = Number(option[0].value)
                          setNewOrders(tempOrders)
                        }
                      }}
                      minWidth="156px"
                    />
                  </div>
                  <span
                    className={styles.deviceQtyDelete}
                    onClick={() => {
                      removeRow(index)
                    }}
                  >
                    <IconDelete />
                  </span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
