import pickBy from "lodash/pickBy"

function toSnakeCase(str) {
  return str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
}

function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

function removeEmptyValues(obj) {
  const transformedObject = {}

  for (const key in obj) {
    if (hasOwnProperty(obj, key)) {
      const snakeCaseKey = toSnakeCase(key)
      const param = obj[key]

      if (Array.isArray(param)) {
        param.forEach((item, index) => {
          if (item != null && typeof item === "object" && hasOwnProperty(item, "value")) {
            transformedObject[`${snakeCaseKey}[${index}]`] = item.value
          } else {
            transformedObject[`${snakeCaseKey}[${index}]`] = item
          }
        })
      } else if (param != null && typeof param === "object" && hasOwnProperty(param, "value")) {
        transformedObject[snakeCaseKey] = param.value
      } else if (param != null) {
        transformedObject[snakeCaseKey] = param
      }
    }
  }

  return pickBy(transformedObject, (value) => value !== "")
}

export function prepareQueryParams(obj) {
  return removeEmptyValues(obj)
}
