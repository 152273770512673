import { PropsWithChildren, FormEvent } from "react"
import classNames from "classnames"
import { ReactComponent as CheckboxIcon } from "../../../assets/svg/checkbox-new.svg"
import styles from "./index.module.scss"

interface Props extends PropsWithChildren {
  id: string
  isChecked?: boolean
  name?: string
  isReverse?: boolean
  className?: string
  disabled?: boolean
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

export const CheckboxNew = ({ id, isChecked, isReverse, children, className, name, disabled, onChange }: Props) => {
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    }
  }

  const handleClick = (event: FormEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault
  }

  return (
    <div
      className={classNames(styles.checkboxNewWrapper, className, {
        [styles.reverse]: isReverse,
      })}
      onClick={handleClick}
    >
      <input
        name={name}
        type="checkbox"
        className={styles.checkboxNew}
        checked={isChecked}
        disabled={disabled}
        onChangeCapture={handleChange}
        id={id}
      />
      <label htmlFor={id} className={classNames(styles.checkboxNewLabel, { [styles.disabled]: disabled })}>
        <CheckboxIcon />
      </label>
      {children && <div className={styles.checkboxNewText}>{children}</div>}
    </div>
  )
}
