import styles from "./index.module.scss"
import { InputNew } from "../../components/form/InputNew"
import { ButtonNew } from "../../components/form/ButtonNew"
import { InputFileNew } from "../../components/form/InputFileNew"
import classNames from "classnames"
import { useState, useEffect, FormEvent, useMemo, Dispatch, SetStateAction } from "react"
import { onFormValidate, validationInitialState, changeContractorProfile } from "../../utils/onValidate"
import {
  useLazyChangeContractorProfileQuery,
  useLazyGetContractorProfileQuery,
  getContractorProfile,
} from "../../store/api/contractorsApi"
import { toast } from "react-toastify"
import { Spinner } from "../../components/common/Spinner"
import { useAppSelector } from "../../store/hooks"
import { InputPhone } from "../../components/form/InputPhone"
import { ContractorPhoneSupport } from "../components/contractorPhoneSupport"
import { UITypes } from "../../types"

const inputValuesInitialState = {
  fullName: "",
  email: "",
  phone1: "",
  image: "",
  phoneForSupport: true,
  phoneForSupportCall: "",
  phoneForSupportText: "",
}

export type UpdateAccount = typeof inputValuesInitialState

interface Props {
  onCancel: () => void
}

export const AccountSettingsProfile = ({ onCancel }: Props) => {
  const [triggerGetContractorProfile, { isFetching, data }] = useLazyGetContractorProfileQuery()
  const [triggerChangeContractorProfile, { isFetching: changeContratorIsFetching }] =
    useLazyChangeContractorProfileQuery()
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState })
  const [error, setError] = useState(validationInitialState)
  const selectContractorProfile = useMemo(() => getContractorProfile.select(), [])
  const contractorProfile = useAppSelector(selectContractorProfile)
  const [imageFile, setImageFile] = useState()

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
        ...(name === "phoneForSupport" && checked ? { phoneForSupportCall: "", phoneForSupportText: "" } : {}),
      }
    })
  }

  const sendRequest = async () => {
    const formData = new FormData()
    formData.append("profile[full_name]", inputValues.fullName)
    formData.append("profile[email]", inputValues.email)
    formData.append("profile[phone1]", inputValues.phone1)
    formData.append("profile[phone_for_support]", inputValues.phoneForSupport ? "1" : "0")
    formData.append("profile[phone_for_support_call]", inputValues.phoneForSupportCall)
    formData.append("profile[phone_for_support_text]", inputValues.phoneForSupportText)

    if (imageFile) {
      formData.append("profile[image]", imageFile)
    }

    try {
      const res: any = await triggerChangeContractorProfile(formData as any)

      if (res?.error) {
        toast.error(res?.error?.data?.message ? res?.error?.data?.message : "Server error")
      } else {
        toast.success("Account has been successfully updated!")
        setInputValues(inputValuesInitialState)
        onCancel()
        triggerGetContractorProfile()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, changeContractorProfile, setError)) {
      sendRequest()
    }
  }

  useEffect(() => {
    const res = contractorProfile?.data ? contractorProfile?.data : data
    if (res) {
      setInputValues({
        fullName: res?.data?.profile?.full_name || "",
        email: res?.data?.profile?.email || "",
        phone1: res?.data?.profile?.phone1 || "",
        phoneForSupport: res?.data?.profile?.phone_for_support || false,
        phoneForSupportCall: res?.data?.profile?.phone_for_support_call || "",
        phoneForSupportText: res?.data?.profile?.phone_for_support_text || "",
        image: res?.data?.profile?.image || "",
      })
    } else {
      const fetchData = async () => {
        try {
          await triggerGetContractorProfile()
        } catch (error) {
          console.error(error)
        }
      }
      fetchData()
    }
  }, [data, contractorProfile?.data])

  return (
    <>
      <div className={classNames(styles.modalContent, styles.settings)}>
        <InputFileNew
          label="Upload avatar picture"
          name="avatar"
          imageUrl={inputValues?.image}
          errorMessage={error.field === "avatar" ? error.message : ""}
          onSetFile={(files) => setImageFile(files[0] as any)}
        />
        {isFetching && (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <InputNew
          label="Full name*"
          placeholder="Full name"
          name="fullName"
          onChange={onInputChange}
          value={inputValues.fullName}
          errorMessage={error.field === "fullName" ? error.message : ""}
        />
        <InputNew
          label="Email*"
          placeholder="Enter company name"
          name="email"
          onChange={onInputChange}
          disabled={true}
          value={inputValues.email}
          errorMessage={error.field === "email" ? error.message : ""}
        />
        <InputPhone
          label="Phone number*"
          placeholder="Phone number"
          name="phone1"
          onChange={(value) => setInputValues({ ...inputValues, ...{ phone1: value } })}
          value={inputValues.phone1}
          errorMessage={error.field === "phone1" ? error.message : ""}
        />
        <ContractorPhoneSupport
          phoneForSupport={inputValues.phoneForSupport}
          phoneForSupportCall={inputValues.phoneForSupportCall}
          phoneForSupportText={inputValues.phoneForSupportText}
          inputValues={inputValues as unknown as UITypes.Contractor}
          error={error}
          onInputChange={onInputChange}
          setInputValues={setInputValues as Dispatch<SetStateAction<UITypes.Contractor>>}
        />
      </div>
      <footer className={styles.modalFooter}>
        <ButtonNew onClick={onCancel} size="halfWidth" color="greyBordered">
          Cancel
        </ButtonNew>
        <ButtonNew onClick={onSubmit} size="halfWidth" type="submit" color="blue" disabled={changeContratorIsFetching}>
          {changeContratorIsFetching ? <Spinner /> : "Save"}
        </ButtonNew>
      </footer>
    </>
  )
}
