import styles from "./index.module.scss"
import classNames from "classnames"
import { UITypes } from "../../../types"

interface Props {
  type: UITypes.OrderStatus | ""
}

export const StatusOfOrder = ({ type }: Props) => {
  let label = ""

  switch (type) {
    case "canceled":
      label = "Canceled"
      break

    case "confirmed":
      label = "Confirmed"
      break

    case "denied":
      label = "Denied"
      break

    case "fulfilled":
      label = "Fulfilled"
      break

    case "new":
      label = "New"
      break

    case "packed":
      label = "Packed"
      break

    case "pending":
      label = "Pending"
      break

    case "shipping":
      label = "Shipping"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.orderStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
