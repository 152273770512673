import classNames from "classnames"
import styles from "./index.module.scss"
import { Tooltip } from "react-tooltip"
import { useRef } from "react"

interface Props {
  option: string
}

export const OptedInOut = ({ option }: Props) => {
  const element = useRef(null)

  return (
    <>
      <Tooltip id="optInOut" ref={element} classNameArrow={styles.tooltipArrow} className={styles.tooltip}>
        {option === "Opted-in" ? (
          <p>The customer has agreed to receive marketing messages sent from the Messaging Center.</p>
        ) : (
          <p>The customer has chosen not to receive any marketing messages from the Messaging Center.</p>
        )}
      </Tooltip>
      <span data-tooltip-id={"optInOut"} className={classNames(styles.optInOutStatus, styles[option])}>
        {option}
      </span>
    </>
  )
}
