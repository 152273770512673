import styles from "./index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { UITypes } from "../../../types"
import { useLazyGetMessageReportQuery, useLazyGetMessageTemplateQuery } from "../../../store/api/contractorsApi"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { setRequestParams } from "../../../store/tempDataSlise"
import { useEffect, useState, useRef } from "react"
import { toast } from "react-toastify"
import { ReactComponent as DateIcon } from "../../../assets/svg/message-report-date.svg"
import { ReactComponent as MessageIcon } from "../../../assets/svg/message-report-message.svg"
import { ReactComponent as TitleIcon } from "../../../assets/svg/message-report-title.svg"
import { convertMessageReportToTableRows } from "../../../utils/convertData"
import dayjs from "dayjs"
import { useSearchParams } from "react-router-dom"
import { FilterTags } from "../../../components/common/FilterTags"
import {
  headingsPlugin,
  listsPlugin,
  tablePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
} from "@mdxeditor/editor"

// TABLE HEADER

const getTableHeaderCells = (): UITypes.TableHeaderCell[] => {
  return [
    {
      title: "Device S/N",
      type: "sort-search",
      name: "device_sn",
      size: "small",
    },
    {
      title: "Customer name",
      type: "sort-search",
      size: "middle",
      name: "client_name",
    },
    {
      title: "City",
      type: "sort-search",
      size: "small",
      name: "city",
    },
    {
      title: "Zip code",
      type: "sort-search",
      size: "small",
      name: "zip",
    },
    {
      title: "State",
      type: "sort-search",
      size: "small",
      name: "state",
    },
    {
      title: "Activation date",
      type: "sort-date",
      size: "middle",
      name: "activation_date",
    },
    {
      title: "Membership",
      type: "sort-search",
      size: "small",
      name: "membership",
    },
    {
      title: "Residence type",
      type: "sort-search",
      size: "middle",
      name: "residence_type",
    },
    {
      title: "System type",
      type: "sort-search",
      size: "small",
      name: "system_type",
    },
    {
      title: "Accessories",
      type: "select",
      size: "small",
      name: "deviceAccessoriesStatus",
    },
    {
      title: "HVAC",
      type: "select",
      size: "small",
      name: "hvac",
    },
    {
      title: "Status",
      type: "sort-search",
      size: "small",
      name: "status",
    },
  ]
}

export const MessageReport = () => {
  const [triggerGetMessageReport, { isFetching, data }] = useLazyGetMessageReportQuery()
  const [triggerGetMessageTemplate, { data: messageTemplate }] = useLazyGetMessageTemplateQuery()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const [isFirstRender, setFirstRender] = useState(true)
  const editorRef = useRef<MDXEditorMethods>(null)

  const sendRequest = async (params: UITypes.Params) => {
    if (!id) return

    try {
      const res: any = await triggerGetMessageReport({ id, params })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSetMarkdown = (markdown) => {
    if (editorRef.current) {
      editorRef.current.setMarkdown(markdown)
    }
  }

  useEffect(() => {
    if (messageTemplate?.data?.body) {
      handleSetMarkdown(messageTemplate?.data?.body)
    }
  }, [messageTemplate?.data?.body])

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams, isFirstRender])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  useEffect(() => {
    if (data?.data[0]?.message_template_id) {
      triggerGetMessageTemplate(data?.data[0]?.message_template_id)
    }
  }, [data?.data[0]?.message_template_id])

  return (
    <>
      <ContentWrapper
        title={messageTemplate?.data?.title}
        breadcrumbs={[
          {
            label: "Message center",
            link: "/messages/center",
          },
          {
            label: "Message history",
            link: "/messages/history",
          },
          {
            label: messageTemplate?.data?.title ? messageTemplate?.data?.title : "",
          },
        ]}
      >
        <nav className={styles.tableNavWrapper}>
          <div className={styles.detailsWrapper}>
            <div className={styles.detailsItem}>
              <h3 className={styles.detailsTitle}>
                <DateIcon className={styles.detailsIcon} />
                Date
              </h3>
              <div className={styles.detailsText}>{dayjs(messageTemplate?.data?.created_at).format("MM-DD-YYYY")}</div>
            </div>
            <div className={styles.detailsItem}>
              <h3 className={styles.detailsTitle}>
                <TitleIcon className={styles.detailsIcon} />
                Title
              </h3>
              <div className={styles.detailsText}>{messageTemplate?.data?.title}</div>
            </div>
            <div className={styles.detailsItem}>
              <h3 className={styles.detailsTitle}>
                <MessageIcon className={styles.detailsIcon} />
                Message
              </h3>
              <div className={styles.detailsText}>
                <MDXEditor
                  className={styles.markdownWrapper}
                  markdown={messageTemplate?.data?.body || ("" as string)}
                  readOnly={true}
                  ref={editorRef}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    tablePlugin(),
                    thematicBreakPlugin(),
                    markdownShortcutPlugin(),
                  ]}
                />
              </div>
            </div>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            {/* <ButtonNew className={styles.tableNavItem} disabled>Export</ButtonNew> */}
          </div>
        </nav>
        <FilterTags />
        <Table
          headerCells={getTableHeaderCells()}
          isLoading={isFetching}
          bodyRows={convertMessageReportToTableRows(data?.data)}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
