import styles from "./index.module.scss"
import { ReactComponent as IconSort } from "../../../assets/svg/icon-sort.svg"
import { ReactComponent as IconSortActive } from "../../../assets/svg/icon-sort-active.svg"
import classNames from "classnames"
import { setRequestParams } from "../../../store/tempDataSlise"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"

interface Props {
  label: string
  name?: string
}

export const TableSort = ({ label, name }: Props) => {
  const dispatch = useAppDispatch()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)

  const renderCurrentIcon = () => {
    if (requestParams?.orderType?.value === "ASC" && requestParams?.orderColumn?.value === name) {
      return <IconSortActive />
    }
    if (requestParams?.orderType?.value === "DESC" && requestParams?.orderColumn?.value === name) {
      return <IconSortActive className={styles.desc} />
    } else return <IconSort />
  }

  const checkCorrentType = () => {
    if (requestParams?.orderColumn?.value !== name) {
      return "ASC"
    }
    if (!requestParams?.orderType?.value && requestParams?.orderColumn?.value === name) {
      return "ASC"
    }
    if (requestParams?.orderType?.value === "ASC" && requestParams?.orderColumn?.value === name) {
      return "DESC"
    }
    if (requestParams?.orderType?.value === "DESC" && requestParams?.orderColumn?.value === name) {
      return ""
    }
  }

  const checkIsActive = () => {
    if (!requestParams?.orderColumn) return false

    return requestParams?.orderColumn?.value === name && requestParams?.orderType !== null
  }

  const onSort = () => {
    if (requestParams?.orderType?.value === "DESC" && requestParams?.orderColumn?.value === name) {
      dispatch(
        setRequestParams({
          orderType: null,
          orderColumn: null,
        }),
      )
    } else {
      dispatch(
        setRequestParams({
          orderType: {
            label: label,
            value: checkCorrentType(),
          },
          orderColumn: {
            value: name,
          },
        }),
      )
    }
  }

  return (
    <span
      className={classNames(styles.tableSort, {
        [styles.active]: checkIsActive(),
      })}
      onClick={onSort}
    >
      {label}
      {renderCurrentIcon()}
    </span>
  )
}
