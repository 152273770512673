import styles from "./index.module.scss"
import { v4 as uuidv4 } from "uuid"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { setRequestParams } from "../../../store/tempDataSlise"
import { removeEmptyValues } from "../../../utils/removeEmptyValues"
import { transformText } from "../../../utils/transformText"
import { UITypes } from "../../../types"
import classNames from "classnames"

interface Props {
  additionalTags?: UITypes.AdditionalTag[]
  clearAdditionalTags?: () => void
  skippedTags?: string[]
}

export const FilterTags = ({ additionalTags, clearAdditionalTags, skippedTags = [] }: Props) => {
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const dispatch = useAppDispatch()

  const renderAdditionalTags = () => {
    if (!additionalTags?.length) return null

    return additionalTags?.map((item) => (
      <li className={styles.filterTag} key={uuidv4()}>
        <span className={styles.filterTagName}>{item?.label}</span>
        {item?.values?.map((value, index) => {
          return index <= 2 ? (
            <span key={uuidv4()} className={classNames(styles.filterTagValues, styles.additionalTag)}>
              {value}
            </span>
          ) : index === 3 ? (
            <span key={uuidv4()} className={classNames(styles.filterTagValues)}>
              +{item?.values?.length - index}
            </span>
          ) : (
            ""
          )
        })}
        {clearAdditionalTags && <span onClick={clearAdditionalTags} className={styles.filterTagClose}></span>}
      </li>
    ))
  }

  const convertParamsToArray = () => {
    if (!requestParams) return

    const result = []

    Object.entries(removeEmptyValues(requestParams)).forEach(([key, requestParam]) => {
      const param: any = requestParam

      if (key === "orderColumn") return

      if (param?.value === "") return

      if (param?.type === "checkbox") return

      if (param?.type === "pagination") return

      if (skippedTags.includes(key)) return

      if (key === "red_flag") {
        return result.push({
          ...param,
          ...{
            key,
            value: param?.value === "1" ? "Yes" : "No",
          },
        } as never)
      }

      if (key === "isEnabled") {
        return result.push({
          ...param,
          ...{
            key,
            value: param?.value === "1" ? "Enabled" : "Disabled",
          },
        } as never)
      } else result.push({ ...(param as UITypes.Param), ...{ key } } as never)
    })

    return result
  }

  const onFilterItemClear = (key?: string) => {
    if (!key) return

    if (key === "orderType") {
      dispatch(
        setRequestParams({
          ...requestParams,
          ...{
            orderColumn: null,
            orderType: null,
          },
        }),
      )
    } else {
      dispatch(setRequestParams({ ...requestParams, ...{ [key]: null } }))
    }
  }

  if (convertParamsToArray()?.length || additionalTags?.length) {
    return (
      <div className={styles.filterTagsWrapper}>
        <h3 className={styles.filterTitle}>Selected filter(s)</h3>
        <ul className={styles.filterTagsList}>
          {renderAdditionalTags()}
          {convertParamsToArray()?.map(
            (item: UITypes.Param) =>
              item?.label && (
                <li className={styles.filterTag} key={uuidv4()}>
                  <span className={styles.filterTagName}>{item?.label}</span>
                  <span className={styles.filterTagValues}>
                    {item?.type === "select" ? transformText(item?.value) : item?.value}
                  </span>
                  <span onClick={() => onFilterItemClear(item?.key)} className={styles.filterTagClose}></span>
                </li>
              ),
          )}
        </ul>
      </div>
    )
  } else {
    return null
  }
}
