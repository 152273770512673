import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"
import { prepareQueryParams } from "../../utils/prepareQueryParams"

export const monitoringApi = createApi({
  reducerPath: "monitoring",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getMonitoringLiveData: builder.query<ApiTypes.Res.MonitoringLiveData, any>({
      query: (sn: string) => ({
        url: `/monitoring/getLiveData`,
        headers: { Accept: "application/json" },
        method: "GET",
        params: prepareQueryParams({ sn }),
      }),
    }),
    getMonitoringHistoricalData: builder.query<ApiTypes.Res.MonitoringHistoricalData, any>({
      query: ({
        sn,
        interval_in_minutes,
        start_date_time,
        end_date_time,
      }: {
        sn: string
        interval_in_minutes: number
        start_date_time: string
        end_date_time: string
      }) => ({
        url: `/monitoring/getHistoricalData`,
        headers: { Accept: "application/json" },
        method: "GET",
        params: prepareQueryParams({ sn, interval_in_minutes, start_date_time, end_date_time }),
      }),
    }),
  }),
})

export const { useLazyGetMonitoringHistoricalDataQuery, useGetMonitoringLiveDataQuery } = monitoringApi
