import styles from "./index.module.scss"
import { PropsWithChildren } from "react"
import { ButtonNew } from "../../components/form/ButtonNew"
import { UITypes } from "../../types"
import { Spinner } from "../../components/common/Spinner"
import classNames from "classnames"

interface Props extends PropsWithChildren {
  width: string
  confirmButtonColor?: UITypes.ButtonColor
  confirmButtonText?: string
  cancelButtonText?: string
  buttonSize?: UITypes.ButtonSize
  isFetching?: boolean
  isWithoutFooter?: boolean
  isDisabled?: boolean
  isDrawer?: boolean
  confirmButtonType?: "submit"
  onExport?: () => void
  onCancel?: () => void
  onClose?: () => void
  onConfirm?: (event) => void
}

export const ModalLayout = ({
  children,
  width,
  isFetching,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  confirmButtonColor = "blue",
  confirmButtonType,
  isWithoutFooter,
  buttonSize,
  isDisabled,
  isDrawer,
  onConfirm,
  onCancel,
  onClose,
  onExport,
}: Props) => {
  return (
    <>
      <form
        onSubmit={onConfirm}
        className={classNames(styles.layout, {
          [styles.modal]: !isDrawer,
          [styles.drawer]: isDrawer,
        })}
        style={{ maxWidth: `${width}` }}
      >
        <span className={styles.closeIcon} onClick={onClose ? onClose : onCancel} />
        {children}
        {!isWithoutFooter && (
          <footer
            className={classNames(styles.modalFooter, {
              [styles.buttonsAutoWidth]: buttonSize === "auto",
            })}
          >
            {onExport && (
              <ButtonNew onClick={onExport} size="auto" color="blueBordered" className={styles.additionalButton}>
                Export
              </ButtonNew>
            )}
            {onCancel && (
              <ButtonNew onClick={onCancel} size={buttonSize ? buttonSize : "halfWidth"} color="greyBordered">
                {cancelButtonText}
              </ButtonNew>
            )}
            {onConfirm && (
              <ButtonNew
                onClick={onConfirm}
                size={buttonSize ? buttonSize : "halfWidth"}
                type={confirmButtonType}
                disabled={isDisabled || isFetching}
                color={confirmButtonColor}
              >
                {isFetching ? <Spinner /> : confirmButtonText}
              </ButtonNew>
            )}
          </footer>
        )}
      </form>
      <div className={styles.viewport} onClick={onClose ? onClose : onCancel}></div>
    </>
  )
}
