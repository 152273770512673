import { FormEvent } from "react"
import { ToggleButton } from "../../../components/form/ToggleButton"
import { InputNew } from "../../../components/form/InputNew"
import styles from "./index.module.scss"

interface ContractorCustomAppProps {
  customAppEnabled: boolean
  appStoreLink?: string | null
  googlePlayLink?: string | null
  error: { field: string; message: string }
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  onInputBlur: (event: FormEvent<HTMLInputElement>) => void
}

export const ContractorCustomApp: React.FC<ContractorCustomAppProps> = ({
  customAppEnabled,
  appStoreLink,
  googlePlayLink,
  error,
  onInputChange,
  onInputBlur,
}) => {
  return (
    <div>
      <div className={styles.toggleFieldWrapper}>
        <ToggleButton id="isCustom" name="isCustom" isChecked={customAppEnabled} onChange={onInputChange}>
          Custom App
        </ToggleButton>
      </div>
      {customAppEnabled && (
        <div className={styles.customAppLinkWrapper}>
          <InputNew
            label="App Store link"
            placeholder="Please paste the App Store link here"
            name="appStoreLink"
            value={appStoreLink || ""}
            onChange={onInputChange}
            onBlur={onInputBlur}
            errorMessage={error.field === "appStoreLink" ? error.message : ""}
          />
          <InputNew
            label="Google Play link"
            placeholder="Please paste the Google Play link here"
            name="googlePlayLink"
            value={googlePlayLink || ""}
            onChange={onInputChange}
            onBlur={onInputBlur}
            errorMessage={error.field === "googlePlayLink" ? error.message : ""}
          />
        </div>
      )}
    </div>
  )
}
