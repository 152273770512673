import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconMissing } from "../../assets/svg/modal-icon-missing.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import {
  useLazyPutDevicesAsMissingQuery,
  useLazyGetAdminDevicesQuery,
  useLazyGetPhysicalStatusesWidgetQuery,
  useLazyGetInstalledDevicesWidgetQuery,
} from "../../store/api/adminApi"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setSelectedDeviceIDs } from "../../store/tempDataSlise"
import { toast } from "react-toastify"

interface Props {
  onCancel: () => void
}

export const MissingModalVendor = ({ onCancel }: Props) => {
  const [triggerPutDevicesAsMissing, { isFetching }] = useLazyPutDevicesAsMissingQuery()
  const [triggerGetPhysicalStatusesWidget] = useLazyGetPhysicalStatusesWidgetQuery()
  const [triggerGetInstalledDEvicesWidget] = useLazyGetInstalledDevicesWidgetQuery()
  const [triggerGetAdminDevices] = useLazyGetAdminDevicesQuery()
  const dispatch = useAppDispatch()
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerPutDevicesAsMissing({ device_ids: selectedDeviceIDs })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetAdminDevices(requestParams === null ? {} : requestParams)
        triggerGetPhysicalStatusesWidget()
        triggerGetInstalledDEvicesWidget()
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconMissing />
            <h3 className={styles.modalTitle}>Mark as missing</h3>
            <div className={styles.modalMessage}>
              By changing the status of the selected device(s) to “Missing”, you confirm that these device(s) are no
              longer in your Stock. Are you sure you want to change the status of the selected device(s)?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
