import { useEffect, useState } from "react"
import { Tooltip } from "../../common/Tooltip"
import styles from "./index.module.scss"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"
import { useLazyGetAlertTypesWidgetQuery, useLazyGetAlertAndDeviceWidgetQuery } from "../../../store/api/alertsApi"
import { Spinner } from "../../common/Spinner"

const initialState = {
  airQuality: 0,
  ambientSensorMalfunction: 0,
  fanMalfunction: 0,
  humiditySensorMalfunction: 0,
  offline: 0,
  temperature: 0,
  temperatureSensorMalfunction: 0,

  enrolledDevicesCount: 0,
  devicesWithActiveAlerts: 0,
  alertsCount: 0,
}

export const WidgetAlerts = () => {
  const [triggerGetAlertTypesWidget, { isFetching }] = useLazyGetAlertTypesWidgetQuery()
  const [triggerGetAlertsAndDeviceWidget] = useLazyGetAlertAndDeviceWidgetQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [res1, res2] = await Promise.all([triggerGetAlertTypesWidget(), triggerGetAlertsAndDeviceWidget()])

        let updatedData = { ...widgetData }

        if (res1?.data?.data) {
          updatedData = {
            ...updatedData,
            airQuality: res1.data.data?.air_quality,
            ambientSensorMalfunction: res1.data.data?.ambient_sensor_malfunction,
            fanMalfunction: res1.data.data?.fan_malfunction,
            humiditySensorMalfunction: res1.data.data?.humidity_sensor_malfunction,
            offline: res1.data.data?.offline,
            temperature: res1.data.data?.temperature,
            temperatureSensorMalfunction: res1.data.data?.temperature_sensor_malfunction,
          }
        }

        if (res2?.data?.data) {
          updatedData = {
            ...updatedData,
            enrolledDevicesCount: res2.data.data?.enrolled_devices_count,
            devicesWithActiveAlerts: res2.data.data?.devices_with_active_alerts,
            alertsCount: res2.data.data?.alerts_count,
          }
        }

        setWidgetData(updatedData)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={classNames(styles.alertsWidgetWrapper)}>
      <header className={styles.widgetHeader}>
        <h3 className={styles.widgetTitle}>Alerts</h3>
        <div className="">
          <span className={styles.summaryIndicators}>
            <i>{widgetData.enrolledDevicesCount}</i> Total Enrolled Devices
          </span>
          <span className={styles.summaryIndicators}>
            <i>{widgetData.devicesWithActiveAlerts}</i> Devices With Alerts
          </span>
          <span className={styles.summaryIndicators}>
            <i>{widgetData.alertsCount}</i> Alerts
          </span>
        </div>
      </header>
      <div className={styles.dataWrapper}>
        <div className={styles.indicatorsWrapper}>
          <span className={styles.indicators}>
            <Tooltip id={uuidv4()} text="Offline">
              Device was continuously offline for 2 days.
            </Tooltip>
            <i>{widgetData.offline}</i>
          </span>
          <span className={styles.indicators}>
            <Tooltip id={uuidv4()} text="Temperature">
              System failed to reach the set temperature
            </Tooltip>
            <i>{widgetData.temperature}</i>
          </span>

          <span className={styles.indicators}>
            Air Quality Sensor Malfunction <i>{widgetData.airQuality}</i>
          </span>
          <span className={styles.indicators}>
            Ambient Sensor Malfunction <i>{widgetData.ambientSensorMalfunction}</i>
          </span>
          <span className={styles.indicators}>
            Fan Malfunction <i>{widgetData.fanMalfunction}</i>
          </span>
          <span className={styles.indicators}>
            Humidity Sensor Malfunction <i>{widgetData.humiditySensorMalfunction}</i>
          </span>
          <span className={styles.indicators}>
            Temperature Sensor Malfunction <i>{widgetData.temperatureSensorMalfunction}</i>
          </span>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
