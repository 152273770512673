import { DeleteModal } from "../DeleteModal"
import { toast } from "react-toastify"
import { useLazyDeleteNewOrderQuery, useLazyGetOrdersQuery } from "../../store/api/ordersApi"
import { useAppSelector } from "../../store/hooks"
import { useLazyGetVendorOrdersQuery } from "../../store/api/vendorApi"

interface Props {
  onCancel: () => void
}

export const DeleteOrderModal = ({ onCancel }: Props) => {
  const [triggerDeleteNewOrder, { isFetching }] = useLazyDeleteNewOrderQuery()
  const [triggerGetOrders] = useLazyGetOrdersQuery()
  const [triggerGetVendorOrder] = useLazyGetVendorOrdersQuery()
  const currentOrder = useAppSelector((state) => state.tempData.currentOrder)

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerDeleteNewOrder(currentOrder?.id!)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Order has been successfully deleted!")
        triggerGetOrders({})
        triggerGetVendorOrder({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DeleteModal
      title="Delete order"
      message="Are you sure you want to delete the selected order(s)?"
      isFetching={isFetching}
      onCancel={onCancel}
      onConfirm={onSubmit}
    />
  )
}
