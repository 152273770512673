import { v4 as uuidv4 } from "uuid"
import { FormEvent } from "react"
import styles from "./index.module.scss"
import { CheckboxNew } from "../../../components/form/CheckboxNew"
import { InputPhone } from "../../../components/form/InputPhone"
import { UITypes } from "../../../types"

interface ContractorPhoneSupportProps {
  phoneForSupport?: boolean
  phoneForSupportCall?: string
  phoneForSupportText?: string
  inputValues: UITypes.Contractor
  error: { field: string; message: string }
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  setInputValues: (value: UITypes.Contractor) => void
}

export const ContractorPhoneSupport = ({
  phoneForSupport,
  phoneForSupportCall,
  phoneForSupportText,
  inputValues,
  error,
  onInputChange,
  setInputValues,
}: ContractorPhoneSupportProps) => {
  return (
    <>
      <div className={styles.phoneCheckboxWrapper}>
        <CheckboxNew id={uuidv4()} name="phoneForSupport" onChange={onInputChange} isChecked={phoneForSupport}>
          Use this number also for Support purposes (get calls and texts)
        </CheckboxNew>
      </div>
      {!phoneForSupport && (
        <div className={styles.supportInputsWrapper}>
          <InputPhone
            label="Phone number for Support purposes (Call)"
            placeholder="Enter phone"
            name="phoneForSupportCall"
            value={phoneForSupportCall || ""}
            onChange={(value) => setInputValues({ ...inputValues, ...{ phoneForSupportCall: value } })}
            errorMessage={error.field === "phoneForSupportCall" ? error.message : ""}
          />
          <InputPhone
            label="Phone number for Support purposes (Text)"
            placeholder="Enter phone"
            name="phoneForSupportText"
            value={phoneForSupportText || ""}
            onChange={(value) => setInputValues({ ...inputValues, ...{ phoneForSupportText: value } })}
            errorMessage={error.field === "phoneForSupportText" ? error.message : ""}
          />
        </div>
      )}
    </>
  )
}
