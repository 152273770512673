import "react-toastify/dist/ReactToastify.css"
import "@mdxeditor/editor/style.css"
import "./assets/scss/globals.scss"
import { useEffect } from "react"
import { useAppSelector, useAppDispatch } from "./store/hooks"
import { showTermsOfUseModal } from "./store/modalSlise"
import { Route, Routes, Navigate, Outlet, useSearchParams, useLocation, useNavigate } from "react-router-dom"
import { AuthLayout } from "./layouts/AuthLayout"
import { MainLayout } from "./layouts/MainLayout"
import { SignIn } from "./pages/common/Auth/SignIn"
import { ForgotPassword } from "./pages/common/Auth/ForgotPassword"
import { ChangePassword } from "./pages/common/Auth/ChangePassword"
import { CreatePassword } from "./pages/common/Auth/CreatePassword"
import { PrivateRoute } from "./components/common/PrivateRoute"
import { NotFound } from "./components/common/NotFound"
import { Orders } from "./pages/contractor/Orders"
import { Technicians } from "./pages/contractor/Technicians"
import { Dashboard } from "./pages/contractor/Dashboard"
import { RedirectPage } from "./pages/common/RedirectPage"
import { AddCustomers } from "./pages/technician/AddCustomers"
import { AddDevice } from "./pages/technician/AddDevice"
import { Customers } from "./pages/contractor/Customers"
import { Contractors } from "./pages/vendor/Contractors"
import { Alerts } from "./pages/contractor/Alerts"
import { MessageTemplateCreate } from "./pages/contractor/MessageTemplateCreate"
import { MessageTemplateEdit } from "./pages/contractor/MessageTemplateEdit"
import { MessageTemplateAdd } from "./pages/contractor/MessageTemplateAdd"
import { MessageCenter } from "./pages/contractor/MessageCenter"
import { MessageHistory } from "./pages/contractor/MessageHistory"
import { Orders as WHOrders } from "./pages/warehouse/Orders"
import { Devices as WHDevices } from "./pages/warehouse/Devices"
import { WarehouseList } from "./pages/vendor/WarehouseList"
import { MessageReport } from "./pages/contractor/MessageReport"
import { Users } from "./pages/vendor/Users"
import { Orders as VOrders } from "./pages/vendor/Orders"
import { Modals } from "./modals"
import { checkRoleType } from "./utils/checkRoleType"
import { Devices as VendorDevices } from "./pages/vendor/Devices"
import { Alerts as VendorAlerts } from "./pages/vendor/Alerts"
import { Dashboard as VendorDashboard } from "./pages/vendor/Dashboard"
import { Warranty } from "./pages/technician/Waranty"
import { storageKeys } from "./utils/storageKeys"
import { Diagnostics } from "./pages/contractor/Diagnostics"
import { Monitoring } from "./pages/contractor/Monitoring"
import { PerformanceTest } from "./pages/contractor/PerformanceTest"
import { PerformanceTestHistory } from "./pages/contractor/PerformanceTestHistory"

export const App = () => {
  const isUserAuthorized = useAppSelector((state) => state.user.isUserAuthorized)
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isUserAuthorized && userProfile?.terms !== true) {
      dispatch(showTermsOfUseModal(true))
    }
  }, [isUserAuthorized])

  // FOR NOT AUTHORIZED TECHNICIAN ONLY
  useEffect(() => {
    if (checkRoleType(userProfile?.type) === "contractor-technician") return
    if (location?.pathname !== "/add-device/customer") return

    const deviceSN = searchParams.getAll("sn")

    if (deviceSN?.length) {
      sessionStorage.setItem(storageKeys.technicianSN, JSON.stringify(deviceSN.length === 1 ? deviceSN[0] : deviceSN))
    }

    navigate("auth/sign-in")
  }, [location])

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
            <Modals />
          </>
        }
      >
        <Route path="auth" element={<AuthLayout />}>
          <Route path="sign-in" element={<SignIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="create-password" element={<CreatePassword />} />
        </Route>

        {/* CONTRACTOR */}
        {checkRoleType(userProfile?.type) === "contractor-manager" && (
          <Route element={<PrivateRoute />}>
            <Route
              path="dashboard"
              element={
                <MainLayout>
                  <Dashboard />
                </MainLayout>
              }
            />
            <Route
              path="orders"
              element={
                <MainLayout>
                  <Orders />
                </MainLayout>
              }
            ></Route>
            <Route
              path="technicians"
              element={
                <MainLayout>
                  <Technicians />
                </MainLayout>
              }
            />
            <Route
              path="customers"
              element={
                <MainLayout>
                  <Customers />
                </MainLayout>
              }
            />
            <Route
              path="alerts"
              element={
                <MainLayout>
                  <Alerts />
                </MainLayout>
              }
            />
            <Route
              path="perf-test"
              element={
                <MainLayout>
                  <PerformanceTest />
                </MainLayout>
              }
            />
            <Route
              path="perf-test-history"
              element={
                <MainLayout>
                  <PerformanceTestHistory />
                </MainLayout>
              }
            />

            <Route
              path="diagnostics"
              element={
                <MainLayout>
                  <Diagnostics />
                </MainLayout>
              }
            />
            <Route
              path="monitoring"
              element={
                <MainLayout>
                  <Monitoring />
                </MainLayout>
              }
            />
            <Route path="messages" element={<Navigate to="center" replace />} />
            <Route path="messages/*">
              <Route
                path="center"
                element={
                  <MainLayout>
                    <MessageCenter />
                  </MainLayout>
                }
              />
              <Route
                path="history"
                element={
                  <MainLayout>
                    <MessageHistory />
                  </MainLayout>
                }
              />
              <Route
                path="report"
                element={
                  <MainLayout>
                    <MessageReport />
                  </MainLayout>
                }
              />
              <Route
                path="create-template"
                element={
                  <MainLayout>
                    <MessageTemplateCreate />
                  </MainLayout>
                }
              />
              <Route
                path="edit-template"
                element={
                  <MainLayout>
                    <MessageTemplateEdit />
                  </MainLayout>
                }
              />
              <Route
                path="add-template"
                element={
                  <MainLayout>
                    <MessageTemplateAdd />
                  </MainLayout>
                }
              />
            </Route>
          </Route>
        )}

        {/* TECHNICIAN */}
        {checkRoleType(userProfile?.type) === "contractor-technician" && (
          <Route element={<PrivateRoute />}>
            <Route path="add-device" element={<Navigate to="customer" replace />} />
            <Route path="add-device/*">
              <Route
                path="customer"
                element={
                  <MainLayout>
                    <AddCustomers />
                  </MainLayout>
                }
              />
              <Route
                path="details"
                element={
                  <MainLayout>
                    <AddDevice />
                  </MainLayout>
                }
              />
            </Route>
            <Route
              path="warranty"
              element={
                <MainLayout>
                  <Warranty />
                </MainLayout>
              }
            />
          </Route>
        )}

        {/* VENDOR ADMIN */}
        {checkRoleType(userProfile?.type) === "vendor-admin" && (
          <Route element={<PrivateRoute />}>
            <Route
              path="contractors"
              element={
                <MainLayout>
                  <Contractors />
                </MainLayout>
              }
            />
            <Route
              path="warehouse-list"
              element={
                <MainLayout>
                  <WarehouseList />
                </MainLayout>
              }
            />
            <Route
              path="orders"
              element={
                <MainLayout>
                  <VOrders />
                </MainLayout>
              }
            ></Route>
            <Route
              path="devices"
              element={
                <MainLayout>
                  <VendorDevices />
                </MainLayout>
              }
            />
            <Route
              path="alerts"
              element={
                <MainLayout>
                  <VendorAlerts />
                </MainLayout>
              }
            />
            <Route
              path="dashboard"
              element={
                <MainLayout>
                  <VendorDashboard />
                </MainLayout>
              }
            />
            <Route path="admin/*">
              <Route
                path="users"
                element={
                  <MainLayout>
                    <Users />
                  </MainLayout>
                }
              />
            </Route>
            <Route path="admin" element={<Navigate to="users" replace />} />
          </Route>
        )}

        {/* SALES MANAGER */}
        {checkRoleType(userProfile?.type) === "sales-manager" && (
          <Route element={<PrivateRoute />}>
            <Route
              path="contractors"
              element={
                <MainLayout>
                  <Contractors />
                </MainLayout>
              }
            />
            <Route
              path="orders"
              element={
                <MainLayout>
                  <VOrders />
                </MainLayout>
              }
            ></Route>
            <Route path="admin" element={<Navigate to="users" replace />} />
            <Route
              path="orders"
              element={
                <MainLayout>
                  <VOrders />
                </MainLayout>
              }
            ></Route>
            <Route
              path="devices"
              element={
                <MainLayout>
                  <VendorDevices />
                </MainLayout>
              }
            />
            <Route
              path="alerts"
              element={
                <MainLayout>
                  <VendorAlerts />
                </MainLayout>
              }
            />
            <Route
              path="dashboard"
              element={
                <MainLayout>
                  <VendorDashboard />
                </MainLayout>
              }
            />
          </Route>
        )}

        {/* WAREHOUSE MANAGER */}
        {checkRoleType(userProfile?.type) === "warehouse-manager" && (
          <Route element={<PrivateRoute />}>
            <Route
              path="wh-orders"
              element={
                <MainLayout>
                  <WHOrders />
                </MainLayout>
              }
            />
            <Route
              path="wh-devices"
              element={
                <MainLayout>
                  <WHDevices />
                </MainLayout>
              }
            />
          </Route>
        )}

        <Route path="/" element={<RedirectPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
