import { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { hexToRGBA } from "../../../utils/hexToRGBA"

const LIB_NAME = "react-dropdown-select"

export const getByPath = (object, path) => {
  if (!path) {
    return
  }

  return path.split(".").reduce((acc, value) => acc[value], object)
}

interface Props {
  props: any
  state: any
  methods: any
  item: any
  itemIndex: any
}

const Item = ({ props, state, methods, item, itemIndex }: Props) => {
  const itemRef = useRef(null as HTMLElement | null)

  useEffect(() => {
    if (itemRef.current && !props.multi && props.keepSelectedInList && methods.isSelected(item)) {
      itemRef.current.scrollIntoView({ block: "nearest", inline: "start" })
    }
  }, [])

  useEffect(() => {
    if (state.cursor === itemIndex) {
      itemRef.current && itemRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
    }
  }, [state.cursor, itemIndex])

  if (!props.keepSelectedInList && methods.isSelected(item)) {
    return null
  }

  return (
    <ItemComponent
      role="option"
      ref={itemRef}
      aria-selected={methods.isSelected(item)}
      aria-disabled={item.disabled}
      aria-label={getByPath(item, props.labelField)}
      disabled={item.disabled}
      key={`${getByPath(item, props.valueField)}${getByPath(item, props.labelField)}`}
      tabIndex={-1}
      className={`${LIB_NAME}-item ${
        methods.isSelected(item) ? `${LIB_NAME}-item-selected` : ""
      } ${state.cursor === itemIndex ? `${LIB_NAME}-item-active` : ""} ${
        item.disabled ? `${LIB_NAME}-item-disabled` : ""
      }`}
      onClick={item.disabled ? undefined : () => methods.addItem(item)}
      onKeyPress={item.disabled ? undefined : () => methods.addItem(item)}
      color={props.color}
    >
      {getByPath(item, props.labelField)} {item.disabled && <ins>{props.disabledLabel}</ins>}
    </ItemComponent>
  )
}

const ItemComponent = styled.span<{ disabled: boolean }>`
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #fff;

  &.${LIB_NAME}-item-active {
    border-bottom: 1px solid #fff;
    ${({ disabled, color }) => !disabled && color && `background: ${hexToRGBA(color, 0.1)};`}
  }

  :hover,
  :focus {
    background: ${({ color }) => color && hexToRGBA(color, 0.1)};
    outline: none;
  }

  &.${LIB_NAME}-item-selected {
    ${({ disabled, color }) =>
      disabled
        ? `
    background: #f2f2f2;
    color: #ccc;
    `
        : `
    background: ${color};
    color: #fff;
    border-bottom: 1px solid #fff;
    `}
  }

  ${({ disabled }) =>
    disabled
      ? `
    background: #f2f2f2;
    color: #ccc;

    ins {
      text-decoration: none;
      border:1px solid #ccc;
      border-radius: 2px;
      padding: 0px 3px;
      font-size: x-small;
      text-transform: uppercase;
    }
    `
      : ""}
`

export default Item
