import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconMissing } from "../../assets/svg/modal-icon-missing.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"

interface Props {
  onCancel: () => void
}

export const ShippingWarningModal = ({ onCancel }: Props) => {
  const onSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          // isFetching={isFetching}
          buttonSize="fullWidth"
          onConfirm={onSubmit}
          confirmButtonText="Got it"
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconMissing />
            <div className={styles.modalMessage}>
              Sorry, we don't have enough devices in the warehouse for packing at the moment. Please contact the
              Administrator for further assistance.
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
