import styles from "./index.module.scss"
import { FormEvent, useState } from "react"
import classNames from "classnames"

interface Props {
  inputType?: "password" | "text"
  placeholder?: string
  label?: string
  errorMessage?: string
  className?: string
  value?: string
  name?: string
  autoComplete?: string
  id?: string
  autoFocus?: boolean
  disabled?: boolean
  labelBottom?: string
  onChange?: (event: FormEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onBlur?: (event: FormEvent<HTMLInputElement>) => void
  onMouseLeave?: () => void
}

export const InputNew = ({
  inputType = "text",
  placeholder,
  label,
  autoComplete,
  errorMessage,
  className,
  value,
  autoFocus,
  name,
  id,
  labelBottom,
  disabled,
  onMouseLeave,
  onChange,
  onFocus,
  onBlur,
}: Props) => {
  const [isPasswordShown, togglePassword] = useState(true)

  return (
    <div className={classNames(styles.fieldWrapper, className)}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <div className={styles.field}>
        <input
          className={classNames(styles.inputNew, {
            [styles.error]: errorMessage,
          })}
          type={inputType === "password" ? (isPasswordShown ? "password" : "text") : "text"}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          name={name}
          id={id}
          autoComplete={autoComplete}
          onMouseEnter={onFocus}
          onBlur={onBlur}
          onMouseLeave={onMouseLeave}
          onChange={onChange}
        />
        {inputType === "password" && value && (
          <span
            className={classNames(styles.iconButton, {
              [styles.showIcon]: isPasswordShown,
              [styles.hideIcon]: !isPasswordShown,
            })}
            onClick={() => togglePassword(!isPasswordShown)}
          />
        )}
      </div>
      {labelBottom && <div className={styles.fieldLabelBottom}>{labelBottom}</div>}
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
