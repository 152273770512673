import { useDispatch } from "react-redux"
import { ModalLayout } from "../../layouts/ModalLayout"
import { useAppSelector } from "../../store/hooks"
import { ReactComponent as IconAlerts } from "../../assets/svg/menu-alerts.svg"
import { PerformanceTestSelectedDevices } from "../components/performanceTestSelectedDevices"
import { setModalContent } from "../../store/tempDataSlise"
import styles from "./index.module.scss"

export const PerformanceTestFailModal = ({ onCancel }) => {
  const dispatch = useDispatch()
  const modalContent = useAppSelector((state) => state.tempData.modalContent)

  const handleCancel = () => {
    onCancel()
    dispatch(setModalContent())
  }

  return (
    <>
      <ModalLayout width="524px" onCancel={handleCancel} cancelButtonText="Close" buttonSize="fullWidth">
        <div className={styles.modalBody}>
          <div className={styles.iconWrapper}>
            <IconAlerts />
          </div>
          <h3>{modalContent?.title}</h3>
          <p>{modalContent?.content}</p>
        </div>
        {modalContent?.devices?.length && <PerformanceTestSelectedDevices devices={modalContent.devices} />}
      </ModalLayout>
    </>
  )
}
