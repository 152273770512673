import { useEffect, useState } from "react"
import styles from "./index.module.scss"
import classNames from "classnames"
import {
  useLazyGetVendorAlertAndDeviceWidgetQuery,
  useLazyGetVendorAlertsTypeWidgetQuery,
} from "../../../store/api/adminApi"
import { Spinner } from "../../common/Spinner"

const alertsAndDeviceInitialState = {
  enrolledDevicesCount: 0,
  devicesWithActiveAlerts: 0,
  alertsCount: 0,
}

const alertsTypeInitialState = {
  airQuality: 0,
  ambientSensorMalfunction: 0,
  fanMalfunction: 0,
  humiditySensorMalfunction: 0,
  offline: 0,
  temperature: 0,
  temperatureSensorMalfunction: 0,
}

export const WidgetVendorAlerts = () => {
  const [triggerGetVendorAlertsAndDeviceWidget, { isFetching }] = useLazyGetVendorAlertAndDeviceWidgetQuery()
  const [triggerGetVendorAlertsTypeWidget] = useLazyGetVendorAlertsTypeWidgetQuery()
  const [alertsAndDevice, setAlertsAndDevice] = useState(alertsAndDeviceInitialState)
  const [alertsType, setAlertsType] = useState(alertsTypeInitialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetVendorAlertsAndDeviceWidget()
        if (res?.data) {
          setAlertsAndDevice({
            enrolledDevicesCount: res?.data?.data?.enrolled_devices_count,
            devicesWithActiveAlerts: res?.data?.data?.devices_with_active_alerts,
            alertsCount: res?.data?.data?.alerts_count,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetVendorAlertsTypeWidget()
        if (res?.data) {
          setAlertsType({
            airQuality: res?.data?.data?.air_quality,
            ambientSensorMalfunction: res?.data?.data?.ambient_sensor_malfunction,
            fanMalfunction: res?.data?.data?.fan_malfunction,
            humiditySensorMalfunction: res?.data?.data?.humidity_sensor_malfunction,
            offline: res?.data?.data?.offline,
            temperature: res?.data?.data?.temperature,
            temperatureSensorMalfunction: res?.data?.data?.temperature_sensor_malfunction,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={classNames(styles.widgetWrapper)}>
      <header className={styles.widgetHeader}>
        <h3 className={styles.widgetTitle}>Alerts</h3>
        <div className="">
          <span className={styles.summaryIndicators}>
            <i>{alertsAndDevice.enrolledDevicesCount}</i> Total Enrolled Devices
          </span>
          <span className={styles.summaryIndicators}>
            <i>{alertsAndDevice.devicesWithActiveAlerts}</i> Devices With Alerts
          </span>
          <span className={styles.summaryIndicators}>
            <i>{alertsAndDevice.alertsCount}</i> Alerts
          </span>
        </div>
      </header>
      <div className={styles.dataWrapper}>
        <h4 className={styles.alertWidgetSubtitle}>Embedded Sensors Malfunctions</h4>
        <div className={styles.indicatorsWrapper}>
          <div className={styles.indicatorWrapper}>
            <span className={styles.indicatorValue}>{alertsType.temperatureSensorMalfunction}</span>
            <span className={styles.indicator}>Temperature</span>
          </div>
          <div className={styles.indicatorWrapper}>
            <span className={styles.indicatorValue}>{alertsType.humiditySensorMalfunction}</span>
            <span className={styles.indicator}>Humidity</span>
          </div>
          <div className={styles.indicatorWrapper}>
            <span className={styles.indicatorValue}>{alertsType.airQuality}</span>
            <span className={styles.indicator}>Air Quality</span>
          </div>
          <div className={styles.indicatorWrapper}>
            <span className={styles.indicatorValue}>{alertsType.ambientSensorMalfunction}</span>
            <span className={styles.indicator}>Ambient</span>
          </div>
          <div className={styles.indicatorWrapper}>
            <span className={styles.indicatorValue}>{alertsType.fanMalfunction}</span>
            <span className={styles.indicator}>Fan</span>
          </div>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
