import styles from "./index.module.scss"
import { InputNew } from "../../../components/form/InputNew"
import { ButtonNew } from "../../../components/form/ButtonNew"
import logo from "../../../assets/svg/logo-blue.svg"
import { InputJobID } from "../../../components/form/InputJobID"
import { useState, useEffect } from "react"
import { UITypes } from "../../../types"
import { useNavigate, useSearchParams } from "react-router-dom"
import { validationInitialState, onFormValidate, addCustomerSchema } from "../../../utils/onValidate"
import { inputHandler } from "../../../utils/onInputChange"
import { setHomeowner } from "../../../store/tempDataSlise"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { storageKeys } from "../../../utils/storageKeys"
import { useLazyCheckJobIDQuery } from "../../../store/api/techniciansApi"
import { toast } from "react-toastify"
import { useLazyGetClientsQuery } from "../../../store/api/clientApi"
import { useDebouncedValue } from "../../../utils/useDebounce"
import { InputPhone } from "../../../components/form/InputPhone"

const inputValuesInitialState: UITypes.Homeowner = {
  fullName: "",
  phone: "",
  email: "",
  jobId: "",
}

export const AddCustomers = () => {
  const [triggerCheckJobID, { isFetching, data }] = useLazyCheckJobIDQuery()
  const [triggerGetClients] = useLazyGetClientsQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [dataPopulatedFromST, setDataPopulatedFromST] = useState(true)
  const [searchParams] = useSearchParams()
  const homeowner = useAppSelector((state) => state.tempData.homeowner)
  const [jobIDStatus, setJobIDStatus] = useState<UITypes.JobIDStautus>("skip")
  const [inputValues, setInputValues] = useState(
    homeowner
      ? {
          fullName: homeowner?.fullName,
          phone: homeowner?.phone,
          email: homeowner?.email,
          jobId: homeowner?.jobId,
        }
      : inputValuesInitialState,
  )
  const [error, setError] = useState(validationInitialState)
  const debouncedCustomerEmail = useDebouncedValue(inputValues.email, 500)

  const onSubmit = (event) => {
    event.preventDefault()

    const result = {
      ...inputValues,
      ...{
        state: data?.data?.state || homeowner?.state,
        city: data?.data?.city || homeowner?.city,
        zip: data?.data?.zip || homeowner?.zip,
        address1: data?.data?.address1 || homeowner?.address1,
        address2: data?.data?.address2 || homeowner?.address2,
      },
    }

    if (onFormValidate(inputValues, addCustomerSchema, setError)) {
      sessionStorage.setItem(storageKeys.technicianHomeowner, JSON.stringify(result))
      dispatch(setHomeowner(result))
      navigate("/add-device/details")
    }
  }

  const sendRequest = (params: any) => {
    if (!params.email || !params.email.includes("@") || dataPopulatedFromST) return
    try {
      triggerGetClients(params).then((res: any) => {
        if (res?.error) {
          toast.error(res?.error?.data?.message)
        }

        if (res?.data?.data) {
          setInputValues({
            ...inputValues,
            ...{
              fullName: res?.data?.data[0]?.full_name || "",
              phone: res?.data?.data[0]?.phone_number || "",
            },
          })
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const deviceSN = searchParams.getAll("sn")
    if (deviceSN?.length) {
      sessionStorage.setItem(storageKeys.technicianSN, JSON.stringify(deviceSN))
    }
  }, [])

  useEffect(() => {
    sendRequest({
      email: debouncedCustomerEmail,
    })
  }, [debouncedCustomerEmail])

  useEffect(() => {
    if (jobIDStatus === "unskip") return
    if (inputValues?.jobId && jobIDStatus == "error") return
    if (homeowner?.jobId && homeowner?.jobId === inputValues?.jobId) {
      setJobIDStatus("pass")
    } else if (inputValues?.jobId && homeowner?.jobId !== inputValues?.jobId) {
      setJobIDStatus("check")
    } else {
      setJobIDStatus("skip")
    }
  }, [inputValues.jobId, homeowner, jobIDStatus])

  const jobIDHandler = () => {
    if (jobIDStatus === "error") {
      setJobIDStatus("check")
    }
    if (jobIDStatus === "check") {
      checkJobID()
    }
    if (jobIDStatus === "unskip") {
      setJobIDStatus("check")
    }
    if (jobIDStatus === "skip") {
      setJobIDStatus("unskip")
    }
  }

  const checkJobID = async () => {
    try {
      const res: any = await triggerCheckJobID(inputValues.jobId!)

      if (res?.data) {
        dispatch(setHomeowner({ ...res?.data?.data, ...{ jobId: inputValues.jobId } }))
        setJobIDStatus("pass")
        setDataPopulatedFromST(true)
        setInputValues({
          ...inputValues,
          ...{
            fullName: res?.data?.data?.full_name || "",
            phone: res?.data?.data?.phone || "",
            email: res?.data?.data?.email || "",
          },
        })
      }
      if (res?.error) {
        setJobIDStatus("error")
        toast.error(res?.error?.data?.message || "Server error")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkFieldsAvailability = () => {
    if (jobIDStatus === "unskip") return false
    if (jobIDStatus === "pass") return false
    else return true
  }

  const handleEmailChange = (event) => {
    inputHandler(event, setInputValues)
    setDataPopulatedFromST(false)
  }

  return (
    <div className={styles.screenWrapper}>
      <form className={styles.formWrapper} onSubmit={onSubmit}>
        <h2 className={styles.formTitle}>Add Customer’s details</h2>
        <InputJobID
          status={isFetching ? "loading" : jobIDStatus}
          label="Job ID"
          name="jobId"
          value={inputValues.jobId}
          placeholder="Enter Job ID"
          onChange={(event) => inputHandler(event, setInputValues)}
          buttonHandler={jobIDHandler}
        />
        <InputNew
          label="Email address"
          placeholder="Enter email address"
          name="email"
          disabled={checkFieldsAvailability()}
          value={inputValues.email}
          onChange={handleEmailChange}
          errorMessage={error.field === "email" ? error.message : ""}
        />
        <InputNew
          label="Full name"
          placeholder="Enter full name"
          name="fullName"
          disabled={checkFieldsAvailability()}
          value={inputValues.fullName}
          onChange={(event) => inputHandler(event, setInputValues)}
          errorMessage={error.field === "fullName" ? error.message : ""}
        />
        <InputPhone
          label="Phone number"
          placeholder="Enter phone number"
          name="phone"
          disabled={checkFieldsAvailability()}
          value={inputValues.phone}
          onChange={(value) => setInputValues({ ...inputValues, ...{ phone: value } })}
          errorMessage={error.field === "phone" ? error.message : ""}
        />
        <ButtonNew type="submit" size="fullWidth" color="blue" onClick={onSubmit}>
          Continue
        </ButtonNew>
        <div className={styles.logoWrapper}>
          <img src={logo} alt="logo" />
        </div>
      </form>
    </div>
  )
}
