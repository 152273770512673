import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconDelete } from "../../assets/svg/modal-icon-delete.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"

interface Props {
  title?: string
  message?: string
  isFetching?: boolean
  confirmButtonText?: string
  onCancel?: () => void
  onConfirm?: (event) => void
}

export const DeleteModal = ({ onCancel, onConfirm, title, message, isFetching, confirmButtonText }: Props) => {
  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonColor="red"
          isFetching={isFetching}
          confirmButtonText={confirmButtonText}
          onConfirm={onConfirm}
          onCancel={onCancel}
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconDelete />
            <h3 className={styles.modalTitle}>{title}</h3>
            <div className={styles.modalMessage}>{message}</div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
