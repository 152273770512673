import { useEffect, useState } from "react"
import classNames from "classnames"
import { SelectStatic } from "../../components/form/SelectStatic"
import { ApiTypes, UITypes } from "../../types"
import { dropDownOptions, getMonitoringDateRangeParams } from "./getMonitoringDateRangeParams"
import TemperatureLineChart from "./TemperatureLineChart"
import TemperatureBarChart from "./TemperatureBarChart"
import AirQualityChart from "./AirQualityChart"
import HumidityChart from "./HumidityChart"
import { useLazyGetMonitoringHistoricalDataQuery } from "../../store/api/monitoringApi"
import { Spinner } from "../../components/common/Spinner"
import { fillMissingHistoricalData } from "../../utils/chartUtils"
import styles from "./index.module.scss"

interface Props {
  deviceSn: string
}

const TabSettings = [
  { name: "Temperature", value: "temperature" },
  { name: "Air Quality", value: "airQuality" },
  { name: "Humidity", value: "humidity" },
]

export const ChartSettings = {
  lastHour: { ticksCount: 15, dataCountsDivider: 1 },
  last6Hours: { ticksCount: 12, dataCountsDivider: 20 },
  last24Hours: { ticksCount: 24, dataCountsDivider: 6 },
  yesterday: { ticksCount: 24, dataCountsDivider: 6 },
  last3Days: { ticksCount: 3, dataCountsDivider: 48 },
  last7Days: { ticksCount: 7, dataCountsDivider: 24 },
  last30Days: { ticksCount: 30, dataCountsDivider: 6 },
}

export const StatsReportChartsWrapper = ({ deviceSn }: Props) => {
  const [activeTab, setActiveTab] = useState(TabSettings[0].value)
  const [selectedTime, setSelectedTime] = useState<UITypes.Option[]>([dropDownOptions[0]])
  const [isSingleDataPoint, setIsSingleDataPoint] = useState(false)
  const { startDateString, endDateString, startDateTime, intervalInMinutes, itemsCount } = getMonitoringDateRangeParams(
    selectedTime[0].value,
  )
  const [triggerGetMonitoringHistoricalData, { isFetching }] = useLazyGetMonitoringHistoricalDataQuery()
  const [data, setData] = useState<ApiTypes.Res.MonitoringHistoricalDataBucket[] | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetMonitoringHistoricalData(
          {
            sn: deviceSn,
            interval_in_minutes: intervalInMinutes,
            start_date_time: startDateString,
            end_date_time: endDateString,
          },
          true,
        )
        if (res) {
          const data = fillMissingHistoricalData(
            res?.data?.aggregations?.["date_histogram#aggregation_over_time"]?.buckets || [],
            startDateTime,
            intervalInMinutes,
            itemsCount,
          )
          setData(data)
          setIsSingleDataPoint(res?.data?.aggregations?.["date_histogram#aggregation_over_time"]?.buckets?.length === 1)
        }
      } catch (error) {
        console.error(error)
        setData([])
        setIsSingleDataPoint(false)
      }
    }

    fetchData()
  }, [deviceSn, selectedTime[0].value])

  const renderCharts = () => {
    if (isFetching || data == null) return <Spinner />

    if (activeTab === "temperature") {
      return (
        <div>
          <TemperatureLineChart
            selectedTime={selectedTime[0].value}
            historicalData={data}
            isSingleDataPoint={isSingleDataPoint}
          />
          {data.length > 0 && <TemperatureBarChart selectedTime={selectedTime[0].value} historicalData={data} />}
        </div>
      )
    }

    if (activeTab === "airQuality") {
      return (
        <AirQualityChart
          selectedTime={selectedTime[0].value}
          historicalData={data}
          isSingleDataPoint={isSingleDataPoint}
        />
      )
    }
    isSingleDataPoint

    return (
      <HumidityChart selectedTime={selectedTime[0].value} historicalData={data} isSingleDataPoint={isSingleDataPoint} />
    )
  }

  return (
    <div className={classNames(styles.chartsWrapper)}>
      <div className={styles.tabButtonsWrapper}>
        {TabSettings.map((tab) => (
          <span
            key={tab.value}
            className={classNames(styles.tabButton, { [styles.active]: activeTab === tab.value })}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.name}
          </span>
        ))}
      </div>
      <div className={styles.dropdownWrapper}>
        <SelectStatic
          values={selectedTime}
          onChange={setSelectedTime}
          portal={document.body}
          options={dropDownOptions}
        />
      </div>
      {renderCharts()}
    </div>
  )
}
