import styles from "../../../../assets/scss/index.module.scss"
import { Helmet } from "react-helmet"
import { Input } from "../../../../components/form/Input"
import { AuthForm } from "../../../../components/form/AuthForm"
import { Button } from "../../../../components/form/Button"
import { useState, FormEvent } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { onFormValidate, validationInitialState, changePassword } from "../../../../utils/onValidate"
import { useLazyCreatePasswordQuery } from "../../../../store/api/authApi"
import { toast } from "react-toastify"
import { Spinner } from "../../../../components/common/Spinner"

const inputValuesInitialState = {
  newPassword: "",
  confirmationPassword: "",
}

export const CreatePassword = () => {
  const [triggerCreatePassword, { isFetching }] = useLazyCreatePasswordQuery()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)
  const token = searchParams.get("token")

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, changePassword, setError)) {
      try {
        const res: any = await triggerCreatePassword({
          token: token!,
          password: inputValues.newPassword,
          password_confirmation: inputValues.confirmationPassword,
        })

        if (res?.isError) {
          toast.error(res?.error?.data?.message || "Server error")
        }

        if (res?.isSuccess) {
          toast.success(`Password was created successfully!`)
          navigate("/")
        }
      } catch (error: any) {
        toast.error(error?.message)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Nuve - Create Password</title>
      </Helmet>
      <div>
        <AuthForm
          subtitle="The password should be at least 8 characters long, include upper and lower case characters, and contain at least one number."
          title="Create password"
          onSubmit={onSubmit}
        >
          <Input
            label="New Password"
            placeholder="Enter password"
            inputType="password"
            name="newPassword"
            value={inputValues.newPassword}
            onChange={onInputChange}
            errorMessage={error.field === "newPassword" ? error.message : ""}
          />
          <Input
            label="Confirm new password"
            placeholder="Enter password"
            inputType="password"
            name="confirmationPassword"
            value={inputValues.confirmationPassword}
            onChange={onInputChange}
            errorMessage={error.field === "confirmationPassword" ? error.message : ""}
          />
          <div className={styles.authButtonsWrapper}>
            <Button onClick={onSubmit} type="submit" disabled={isFetching} color="colored" size="fullWidth">
              {isFetching ? <Spinner width={38} height={38} /> : "Submit"}
            </Button>
          </div>
        </AuthForm>
      </div>
    </>
  )
}
