import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ApiTypes } from "../types"
import { storageKeys } from "../utils/storageKeys"

export interface ModalsState {
  isUserAuthorized: boolean
  userProfile: ApiTypes.Model.UserProfile | null
}

const token = localStorage.getItem(storageKeys.token)
const userProfile = localStorage.getItem(storageKeys.userProfile)

const initialState: ModalsState = {
  isUserAuthorized: token ? true : false,
  userProfile: userProfile ? JSON.parse(userProfile) : null,
}

export const userSlise = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isUserAuthorized = action.payload
    },
    setUserProfile: (state, action: PayloadAction<ApiTypes.Model.UserProfile>) => {
      state.userProfile = action.payload
    },
  },
})

export const { setUserAuthorized, setUserProfile } = userSlise.actions

export default userSlise.reducer
