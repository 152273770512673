import styles from "./index.module.scss"
import { useState, useEffect } from "react"
import classNames from "classnames"
import { ReactComponent as IconLeft } from "../../../assets/svg/icon-left.svg"
import { ReactComponent as IconRight } from "../../../assets/svg/icon-right.svg"
import { PaginationDropdown } from "../PaginationDropdown"
import { ApiTypes } from "../../../types"
import { v4 as uuidv4 } from "uuid"
import { useAppDispatch } from "../../../store/hooks"
import { setRequestParams } from "../../../store/tempDataSlise"

interface Props {
  meta?: ApiTypes.Model.Pagination
}

export const Pagination = ({ meta }: Props) => {
  const dispatch = useAppDispatch()

  const [data, setData] = useState({
    currentPage: 0,
    from: 0,
    lastPage: 0,
    perPage: 15,
  })

  const onPageChange = (value: number) => {
    dispatch(
      setRequestParams({
        page: {
          value,
          label: "Page",
          type: "pagination",
        },
      }),
    )
  }

  const onPerPageChange = (value: number) => {
    if (data.perPage < value) {
      dispatch(
        setRequestParams({
          perPage: {
            value,
            label: "Per page",
            type: "pagination",
          },
          page: {
            value: 1,
            label: "Page",
            type: "pagination",
          },
        }),
      )
    } else {
      dispatch(
        setRequestParams({
          perPage: {
            value,
            label: "Per page",
            type: "pagination",
          },
        }),
      )
    }
  }

  const renderItems = (items?: ApiTypes.Model.PaginationLink[]) => {
    if (!items?.length) return null

    return items.map((item, index) => {
      if (index === 0) {
        const isAvailable = Boolean(data.currentPage > 1)

        return (
          <span
            key={uuidv4()}
            className={classNames(styles.pageNumber, { [styles.disabled]: !isAvailable })}
            onClick={() => isAvailable && onPageChange(data.currentPage - 1)}
          >
            <IconLeft />
          </span>
        )
      }

      if (index !== 0 && index !== items.length - 1) {
        return (
          <span
            key={uuidv4()}
            onClick={() => Number(item.label) != data.currentPage && onPageChange(Number(item.label))}
            className={classNames(styles.pageNumber, { [styles.active]: Number(item.label) == data.currentPage })}
          >
            {item.label}
          </span>
        )
      }

      if (index === items.length - 1) {
        const isAvailable = Boolean(data.currentPage < data.lastPage)

        return (
          <span
            key={uuidv4()}
            className={classNames(styles.pageNumber, { [styles.disabled]: !isAvailable })}
            onClick={() => isAvailable && onPageChange(data.currentPage + 1)}
          >
            <IconRight />
          </span>
        )
      }
    })
  }

  useEffect(() => {
    if (!meta) return

    const { current_page, from, last_page, per_page } = meta

    setData({
      currentPage: current_page,
      lastPage: last_page,
      perPage: per_page,
      from,
    })

    if (current_page > last_page) {
      onPageChange(last_page)
    }
  }, [meta])

  return (
    <div className={styles.paginationWrapper}>
      <div className={styles.paginationText}>
        Page {data.currentPage} of {data.lastPage}
      </div>
      <div className={styles.pageNumberList}>{renderItems(meta?.links)}</div>
      <PaginationDropdown onSelect={onPerPageChange} currentNumber={data.perPage} numbersOfPage={[50, 30, 15, 5]} />
    </div>
  )
}
