import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { UITypes } from "../../../types"
import styles from "./index.module.scss"
import { WidgetPhysicalStatus } from "../../../components/widgets/WidgetPhysicalStatus"
import { WidgetInstaledDevices } from "../../../components/widgets/WidgetInstaledDevices"
import { ButtonNew } from "../../../components/form/ButtonNew"
import classNames from "classnames"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import uniqby from "lodash.uniqby"
import {
  showDamageDeviceModalForVendor,
  showMissingModalForVendor,
  showTransferDeviceModal,
  showReturnToWarehouseModal,
  showWarrantyModal,
  showDeviceHistoryLogModal,
  showUndamageDeviceModalForVendor,
  showEditColumnsModal,
} from "../../../store/modalSlise"
import { useState, useEffect } from "react"
import { useLazyGetAdminDevicesQuery } from "../../../store/api/adminApi"
import { FilterTags } from "../../../components/common/FilterTags"
import { toast } from "react-toastify"
import {
  setRequestParams,
  setDeviceHistoryLogID,
  setSelectedDeviceIDs,
  setSelectedDevices,
  setCurrentTableColumns,
} from "../../../store/tempDataSlise"
import { convertVendorDevicesToTableRows } from "../../../utils/convertData"
import { useLazyGetTableColumnsQuery } from "../../../store/api/dashboardApi"
import { reorderTableHeaderCells } from "../../../utils/reorderTabbleColumns"

export const Devices = () => {
  const dispatch = useAppDispatch()
  const [triggerGetAdminDevices, { isFetching, data }] = useLazyGetAdminDevicesQuery()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const selectedDevices = useAppSelector((state) => state.tempData.selectedDevices)
  const [isFirstRender, setFirstRender] = useState(true)
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const [triggerGetTableColumns, { isFetching: isColumnsFetching, data: columnsData }] = useLazyGetTableColumnsQuery()
  const [tableHeaderCells, setTableHeaderCells] = useState<UITypes.TableHeaderCell[]>([
    {
      title: "",
      type: "checkbox",
      size: "tiny",
      name: "checkbox",
      id: 1,
    },
    {
      title: "S/N",
      type: "sort-search",
      size: "middle",
      name: "sn",
      id: 2,
    },
    {
      title: "Work Status",
      type: "select",
      size: "small",
      name: "vendorDeviceWorkStatus",
      id: 3,
    },
    {
      title: "Physical status",
      type: "select",
      size: "small",
      name: "vendorDevicePhysicalStatus",
      id: 4,
    },
    {
      title: "Contractor",
      type: "sort-search",
      size: "middle",
      name: "contractor_name",
      id: 5,
    },
    {
      title: "Activation date",
      type: "sort-date",
      size: "middle",
      name: "activation_date",
      id: 6,
    },
    {
      title: "Red Flag",
      size: "small",
      type: "select",
      name: "vendorDeviceRedFlag",
      id: 7,
    },
    {
      title: "Red Flag Type",
      type: "select",
      size: "middle",
      name: "vendorDeviceRedFlagType",
      id: 8,
    },
    {
      title: "Customer",
      type: "sort-search",
      size: "middle",
      name: "homeowner",
      id: 9,
    },
    {
      title: "Address",
      type: "sort-search",
      name: "address",
      id: 10,
    },
    {
      title: "Email",
      type: "sort-search",
      size: "middle",
      name: "email",
      id: 11,
    },
    {
      title: "Phone",
      type: "sort-search",
      size: "middle",
      name: "phone",
      id: 12,
    },
    {
      title: "HVAC",
      type: "select",
      size: "small",
      name: "hvac",
      id: 13,
    },
    {
      title: "Device Location",
      type: "sort-search",
      name: "device_location",
      id: 14,
    },
  ])

  const showDamageModal = () => {
    dispatch(showDamageDeviceModalForVendor(true))
  }

  const showUndamageModal = () => {
    dispatch(showUndamageDeviceModalForVendor(true))
  }

  const showMissingModal = () => {
    dispatch(showMissingModalForVendor(true))
  }

  const onTransferDeviceClick = () => {
    dispatch(showTransferDeviceModal(true))
  }

  const onReturnToWarehouseClick = () => {
    dispatch(showReturnToWarehouseModal(true))
  }

  const onReturnFromWarrantyClick = () => {
    dispatch(showWarrantyModal(true))
  }

  const onShowHistoryLogModal = (id) => {
    dispatch(setDeviceHistoryLogID(id))
    dispatch(showDeviceHistoryLogModal(true))
  }

  const onClearSelectedDevices = () => {
    dispatch(setSelectedDeviceIDs([]))
    dispatch(setSelectedDevices([]))
    setHeaderChecked(false)
  }

  const onDeviceSelect = (id) => {
    if (selectedDeviceIDs?.some((item) => item === id)) {
      const ids = selectedDeviceIDs?.filter((item) => item !== id)
      dispatch(setSelectedDeviceIDs(ids))
    } else {
      dispatch(setSelectedDeviceIDs([...selectedDeviceIDs, ...[id]]))
    }
  }

  const toggleAllDevices = () => {
    if (isHeaderChecked) {
      setHeaderChecked(false)
      dispatch(setSelectedDeviceIDs([]))
    } else {
      const ids = data?.data?.map((item) => item.id)
      dispatch(setSelectedDeviceIDs(ids || []))
      setHeaderChecked(true)
    }
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetAdminDevices(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "admin_devices_list",
        columns: tableHeaderCells,
      }),
    )
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
    triggerGetTableColumns({ list_name: "admin_devices_list" })
  }, [requestParams, isFirstRender])

  useEffect(() => {
    if (columnsData?.data?.config) {
      setTableHeaderCells(reorderTableHeaderCells(tableHeaderCells, columnsData?.data?.config))
    }
  }, [columnsData])

  // get devices accordingly to deviceIDs
  useEffect(() => {
    let result

    if (!selectedDevices?.length && data?.data) {
      result = data?.data?.filter((item) => selectedDeviceIDs?.some((id) => id === item?.id))
    } else if (selectedDevices?.length && data?.data) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      result = uniqby([...selectedDevices, ...data?.data], "id")?.filter((item) =>
        selectedDeviceIDs?.some((id) => id === item?.id),
      )
    }

    dispatch(setSelectedDevices(result))
  }, [selectedDeviceIDs])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  const checkButtonsAvailability = (value: string[]) => {
    if (!selectedDevices?.length) return false
    return selectedDevices?.every((item) => value.some((status) => status === item.phisical_status))

    /*
      
      -- AVAILABILITY RULS --
      
      mark as damaged - stocked, warranty
      mark as undamaged - damaged
      transfer - stocked, transferring
      mark as missing - stocked, inventory
      return to warehouse - transferring
      return from warranty - warranty

    */
  }

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetPhysicalStatus />
        <WidgetInstaledDevices />
      </div>
      <ContentWrapper title="Devices">
        <nav className={styles.tableNavWrapper}>
          <div className={classNames(styles.actionButtonsWrapper)}>
            <button
              onClick={onTransferDeviceClick}
              disabled={!checkButtonsAvailability(["stocked", "transferring"])}
              className={classNames(styles.actionButton, styles.transfer)}
            >
              Transfer
            </button>
            <button
              onClick={showMissingModal}
              disabled={!checkButtonsAvailability(["stocked", "inventory"])}
              className={classNames(styles.actionButton, styles.missing)}
            >
              Mark as missing
            </button>
            <button
              onClick={showDamageModal}
              disabled={!checkButtonsAvailability(["stocked", "warranty"])}
              className={classNames(styles.actionButton, styles.damaged)}
            >
              Mark as Damaged
            </button>
            <button
              onClick={onReturnToWarehouseClick}
              disabled={!checkButtonsAvailability(["transferring"])}
              className={classNames(styles.actionButton, styles.warehouse)}
            >
              Return to Warehouse
            </button>
            <button
              onClick={onReturnFromWarrantyClick}
              disabled={!checkButtonsAvailability(["warranty"])}
              className={classNames(styles.actionButton, styles.warranty)}
            >
              Return from Warranty
            </button>
            <button
              onClick={showUndamageModal}
              disabled={!checkButtonsAvailability(["damaged"])}
              className={classNames(styles.actionButton, styles.damaged)}
            >
              Mark as Undamaged
            </button>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
              Edit columns
            </span>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>

        <FilterTags
          clearAdditionalTags={() => onClearSelectedDevices()}
          additionalTags={
            selectedDevices?.length
              ? [
                  {
                    label: "Device S/N",
                    values: selectedDevices?.map((item) => item.sn),
                  },
                ]
              : []
          }
        />
        <Table
          headerCells={tableHeaderCells}
          areHeaderColumnsDynamic
          isLoading={isFetching}
          currentItemIDs={selectedDeviceIDs}
          onCheckboxChange={onDeviceSelect}
          bodyRows={convertVendorDevicesToTableRows(data?.data, tableHeaderCells, onShowHistoryLogModal)}
          onHeaderCheckboxChange={toggleAllDevices}
          isHeaderChecked={isHeaderChecked}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
