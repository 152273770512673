import styles from "./index.module.scss"
import { PropsWithChildren, FormEvent } from "react"
import classNames from "classnames"

interface Props extends PropsWithChildren {
  isChecked?: boolean
  name?: string
  value?: string
  label?: string
  id?: string
  errorMessage?: string
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

export const RadioButton = ({ isChecked, children, value, name, label, id, errorMessage, onChange }: Props) => {
  return (
    <div className={classNames(styles.fieldWrapper)}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <label
        htmlFor={id}
        className={classNames(styles.radioLabel, {
          [styles.radioLabelChecked]: isChecked,
          [styles.error]: errorMessage,
        })}
      >
        <div className={styles.radioText}>{children}</div>
        <input
          name={name}
          type="radio"
          id={id}
          value={value}
          className={styles.input}
          checked={isChecked}
          onChange={onChange}
        />
      </label>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
