import styles from "./index.module.scss"
import { FormEvent } from "react"
import { UITypes } from "../../../types"
import { ReactComponent as IconPass } from "../../../assets/svg/icon-pass.svg"
import { ReactComponent as IconClean } from "../../../assets/svg/icon-clean.svg"
import { Spinner } from "../../common/Spinner"
import classNames from "classnames"

interface Props {
  placeholder?: string
  label?: string
  errorMessage?: string
  value?: string
  name?: string
  id?: string
  disabled?: boolean
  status?: UITypes.TenantIDStautus
  onChange?: (event: FormEvent<HTMLInputElement>) => void
  buttonHandler?: () => void
}

export const InputTenantID = ({
  placeholder,
  label,
  errorMessage,
  value,
  name,
  id,
  disabled,
  status,
  onChange,
  buttonHandler,
}: Props) => {
  const renderCurrentStatus = () => {
    switch (status) {
      case "check":
        return (
          <span className={styles.inputButton} onClick={buttonHandler}>
            Check
          </span>
        )
      case "error":
        return (
          <span className={styles.iconWrapper} onClick={buttonHandler}>
            <IconClean />
          </span>
        )
      case "pass":
        return (
          <span className={styles.iconWrapper} onClick={buttonHandler}>
            <IconPass />
          </span>
        )
      case "loading":
        return (
          <span className={classNames(styles.iconWrapper, styles.jobID)}>
            <Spinner height={18} width={18} />
          </span>
        )
      default:
        return (
          <span className={styles.inputButton} onClick={buttonHandler}>
            Check
          </span>
        )
    }
  }

  return (
    <div className={styles.fieldWrapper}>
      <div className={styles.fieldLabelNew}>{label}</div>
      <div className={styles.field}>
        <input
          className={classNames(styles.inputNew, {
            [styles.error]: errorMessage,
          })}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          name={name}
          id={id}
          onChange={onChange}
        />
        {renderCurrentStatus()}
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
