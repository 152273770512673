import styles from "./index.module.scss"
import { ReactComponent as IconDown } from "../../../assets/svg/icon-down.svg"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import classNames from "classnames"

interface Props {
  onSelect?: (value: number) => void
  currentNumber: number
  numbersOfPage: number[]
}

export const PaginationDropdown = ({ onSelect, currentNumber, numbersOfPage }: Props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  let timeoutId

  const openDropdown = () => {
    clearTimeout(timeoutId)
    setDropdownVisible(true)
  }

  const closeDropdown = () => {
    timeoutId = setTimeout(() => {
      setDropdownVisible(false)
    }, 300)
  }

  const onItemClick = (value: number) => {
    clearTimeout(timeoutId)
    setDropdownVisible(false)
    onSelect && onSelect(value)
  }

  return (
    <div className={styles.paginationDropdownWrapper} onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
      <div className={styles.paginationDropdown}>
        {currentNumber} / page{" "}
        <IconDown className={classNames(styles.paginationIcon, { [styles.active]: isDropdownVisible })} />
      </div>
      {isDropdownVisible && (
        <ul className={styles.paginationDropdownList} onMouseEnter={openDropdown}>
          {numbersOfPage?.map((item) => (
            <li
              className={classNames(styles.paginationDropdownItem, {
                [styles.active]: item === currentNumber,
              })}
              key={uuidv4()}
              onClick={() => onItemClick(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
