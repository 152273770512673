import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes, UITypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"
import { prepareQueryParams } from "../../utils/prepareQueryParams"

export const techniciansApi = createApi({
  reducerPath: "technicians",
  tagTypes: ["technicians"],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    createTechnician: builder.query<void, ApiTypes.Req.CreateTechnician>({
      query: (body) => ({
        url: `/technicians`,
        method: "POST",
        headers: { Accept: "application/json" },
        body,
      }),
    }),
    updateTechnician: builder.query<void, { data: ApiTypes.Req.UpdateTechnician; id: number }>({
      query: (body) => ({
        url: `/technicians/${body.id}`,
        method: "PUT",
        body: body.data,
      }),
    }),
    getTechnicians: builder.query<ApiTypes.Res.Technicians, UITypes.Params>({
      query: (arg) => {
        return {
          url: `/technicians`,
          method: "GET",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    installDevice: builder.query<void, ApiTypes.Req.DeviceInstall>({
      query: (body) => ({
        url: `/technicians/device/install`,
        method: "POST",
        headers: { Accept: "application/json" },
        body,
      }),
    }),
    checkJobID: builder.query<{ data: ApiTypes.Res.ServiceTitanCustomer }, string>({
      query: (id) => ({
        url: `/technicians/service-titan/customer/${id}`,
        method: "GET",
      }),
    }),
    warranty: builder.query<void, ApiTypes.Req.Warranty>({
      query: (body) => ({
        url: `/technicians/warranty`,
        method: "POST",
        headers: { Accept: "application/json" },
        body,
      }),
    }),
  }),
})

export const {
  useLazyCreateTechnicianQuery,
  useLazyGetTechniciansQuery,
  useLazyUpdateTechnicianQuery,
  useLazyInstallDeviceQuery,
  useLazyCheckJobIDQuery,
  useLazyWarrantyQuery,
} = techniciansApi

export const getTechnicians = techniciansApi.endpoints.getTechnicians
export const checkJobID = techniciansApi.endpoints.checkJobID
