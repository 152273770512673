import styles from "./index.module.scss"
import { ReactComponent as CloseIcon } from "../../../assets/svg/icon-close.svg"

interface Props {
  label: string
}

export const PerformanceTestLabel = ({ label }: Props) => {
  const handleRemoveLabel = () => {
    console.log("Remove label")
  }

  if (!label) return null

  return (
    <div className={styles.performanceTestLabel}>
      <p>{label}</p>
      <span onClick={handleRemoveLabel}>
        <CloseIcon />
      </span>
    </div>
  )
}
