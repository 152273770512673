import styles from "./index.module.scss"
import classNames from "classnames"
import { useRef, useEffect } from "react"
import "../../../assets/libs/markdown.css"
import {
  headingsPlugin,
  listsPlugin,
  tablePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  Separator,
  ListsToggle,
  BlockTypeSelect,
  MDXEditorMethods,
} from "@mdxeditor/editor"

interface Props {
  errorMessage?: string
  value: string
  id?: string
  onChange?: (value: string) => void
}

export const MarkdownEditor = ({ errorMessage, value, onChange }: Props) => {
  const editorRef = useRef<MDXEditorMethods>(null)

  const handleSetMarkdown = (markdown) => {
    if (editorRef.current) {
      editorRef.current.setMarkdown(markdown)
    }
  }

  const setEditorValue = (editorValue) => {
    onChange && onChange(editorValue)
  }

  useEffect(() => {
    if (value) {
      handleSetMarkdown(value)
    }
  }, [value])

  return (
    <>
      <MDXEditor
        ref={editorRef}
        onChange={setEditorValue}
        contentEditableClassName={classNames(styles.editor, { [styles.error]: errorMessage })}
        markdown={value}
        placeholder="Type your text..."
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          tablePlugin(),
          thematicBreakPlugin(),
          markdownShortcutPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <UndoRedo />
                <BoldItalicUnderlineToggles />
                <Separator />
                <ListsToggle options={["bullet", "number"]} />
                <BlockTypeSelect />
              </>
            ),
          }),
        ]}
      />
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </>
  )
}
