import { useState } from "react"
import { createPortal } from "react-dom"
import classNames from "classnames"
import { ModalLayout } from "../../layouts/ModalLayout"
import { useAppSelector } from "../../store/hooks"
import { StatsReportChartsWrapper } from "./StatsReportChartsWrapper"
import LiveDateView from "./LiveDataView"
import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const StatsReportModal = ({ onCancel }: Props) => {
  const [activeTab, setActiveTab] = useState("liveData")
  const device = useAppSelector((state) => state.tempData.selectedDeviceForMonitoringReport)

  if (!device) return null

  return (
    <>
      {createPortal(
        <ModalLayout buttonSize="auto" onClose={onCancel} width="770px" isDrawer isWithoutFooter>
          <div className={styles.modalContent}>
            <h3 className={styles.modalTitle}>Stats Report</h3>
          </div>
          <div className={styles.modalSubtitleWrapper}>
            <h4 className={styles.modalSubtitle}>Details</h4>
          </div>
          <div className={styles.statsScrollableArea}>
            <div className={classNames(styles.monitoringReportContent)}>
              <div className={classNames(styles.devicesDataContainer)}>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Customer</h4>
                  <div className={styles.devicesDataText}>{device?.homeowner_name || "-"}</div>
                </div>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Zip Code</h4>
                  <div className={styles.devicesDataText}>{device?.zip?.code || "-"}</div>
                </div>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Email</h4>
                  <div className={styles.devicesDataText}>{device?.email || "-"}</div>
                </div>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Mobile App</h4>
                  <div className={styles.devicesDataText}>Activated</div>
                </div>
              </div>
            </div>
            <div className={styles.drawerTabsWrapper}>
              <div className={styles.drawerTabs}>
                <div
                  className={classNames(styles.drawerTab, { [styles.active]: activeTab === "liveData" })}
                  onClick={() => setActiveTab("liveData")}
                >
                  Live Data
                </div>
                <div
                  className={classNames(styles.drawerTab, { [styles.active]: activeTab === "reporting" })}
                  onClick={() => setActiveTab("reporting")}
                >
                  Reporting
                </div>
              </div>
            </div>
            {activeTab === "liveData" ? (
              <LiveDateView device={device} />
            ) : (
              <StatsReportChartsWrapper deviceSn={device.sn} />
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
