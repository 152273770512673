import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconShipping } from "../../assets/svg/modal-icon-shipping.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useAppSelector } from "../../store/hooks"
import { useLazyShippDevicesQuery, useLazyGetWHOrdersQuery } from "../../store/api/warehouseManagerApi"
import { toast } from "react-toastify"

interface Props {
  onCancel: () => void
}

export const ShippingModal = ({ onCancel }: Props) => {
  const [triggerShippDevice, { isFetching }] = useLazyShippDevicesQuery()
  const [triggerGetWHOrders] = useLazyGetWHOrdersQuery()
  const selectedWHOrder = useAppSelector((state) => state.tempData.selectedWHOrder)

  const onSubmit = async (event) => {
    event.preventDefault()

    if (!selectedWHOrder?.id) return

    try {
      const res: any = await triggerShippDevice(selectedWHOrder?.id)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Order shipped successfully!")
        triggerGetWHOrders({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          isFetching={isFetching}
          onConfirm={onSubmit}
          onCancel={onCancel}
          confirmButtonText="Yes"
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconShipping />
            <h3 className={styles.modalTitle}>Shipping</h3>
            <div className={styles.modalMessage}>
              Are you sure you want to ship the Order number {selectedWHOrder?.id}?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
