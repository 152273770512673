import { useEffect, useState } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { RainbowChart } from "../../common/RainbowChart"
import classNames from "classnames"
import { Spinner } from "../../common/Spinner"
import { useLazyGetInstalledDevicesWidgetQuery } from "../../../store/api/adminApi"

const initialState = {
  online: 0,
  offline: 0,
  allDevices: 0,
}

export const WidgetInstaledDevices = () => {
  const [triggerGetInstalledDEvicesWidget, { isFetching, data }] = useLazyGetInstalledDevicesWidgetQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetInstalledDEvicesWidget()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data?.data) return

    setWidgetData({
      online: data?.data?.online,
      offline: data?.data?.offline,
      allDevices: data?.data?.total,
    })
  }, [data])

  return (
    <WidgetLayout title="Installed devices" className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <RainbowChart
            colors={["#22C55E", "#E2E8F0"]}
            values={[widgetData.online, widgetData.offline]}
            className={styles.chart}
          />
        </div>
        <div className={styles.widgetMainValue}>
          <span className={styles.text}>DEVICES</span>
          <div className={styles.widgetValueWrapper}>{widgetData.allDevices}</div>
        </div>
        <footer className={styles.widgetFooter}>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.online)}>Online</span>
            <div className={styles.widgetValueWrapper}>{widgetData.online}</div>
          </div>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.offline)}>Offline</span>
            <div className={styles.widgetValueWrapper}>{widgetData.offline}</div>
          </div>
        </footer>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
