import { DeleteModal } from "../DeleteModal"
import { useLazyDeleteMessageTemplateQuery, useLazyGetMessageTemplatesQuery } from "../../store/api/contractorsApi"
import { toast } from "react-toastify"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setCurrentTemplateID } from "../../store/tempDataSlise"

interface Props {
  onCancel: () => void
}

export const DeleteMessageTemplateModal = ({ onCancel }: Props) => {
  const [triggerDeleteMessageTemplate, { isFetching }] = useLazyDeleteMessageTemplateQuery()
  const [triggerGetMessageTemplates] = useLazyGetMessageTemplatesQuery()
  const currentTemplateID = useAppSelector((state) => state.tempData.currentTemplateID)
  const dispatch = useAppDispatch()

  const onSubmit = async (event) => {
    event.preventDefault()
    if (!currentTemplateID) return false

    try {
      const res: any = await triggerDeleteMessageTemplate(currentTemplateID)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Message template has been successfully deleted!")
        triggerGetMessageTemplates()
        dispatch(setCurrentTemplateID(undefined))
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DeleteModal
      title="Delete template"
      confirmButtonText="Delete"
      message="Are you sure you want to delete the selected template?"
      isFetching={isFetching}
      onCancel={onCancel}
      onConfirm={onSubmit}
    />
  )
}
