import styles from "./index.module.scss"
import { Table } from "../../table/Table"
import { UITypes } from "../../../types"
import { useAppDispatch } from "../../../store/hooks"
import { showDeleteMessageTemplateModal } from "../../../store/modalSlise"
import {
  useLazyGetMessageTemplatesQuery,
  useLazyGetTemplateLibraryGroupsQuery,
  useLazyAddTemplateLibraryQuery,
} from "../../../store/api/contractorsApi"
import React, { useEffect } from "react"
import { convertMessageTemplatesToTableRows, convertTemplatesLibraryToTableRows } from "../../../utils/convertData"
import { useNavigate, useSearchParams, useLocation } from "react-router-dom"
import { setCurrentTemplateID } from "../../../store/tempDataSlise"
import { NavLink } from "../NavLink"
import { toast } from "react-toastify"
import classNames from "classnames"

// TABLE HEADER
const libraryTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "ID",
    size: "tiny",
  },
  {
    title: "Title",
    size: "big",
  },
  {
    title: "Message",
    size: "extra-big",
  },
]

const templatesTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Title",
    // size: "big",
  },
  {
    title: "Creation date",
    size: "small",
  },
  {
    title: "Message",
    size: "extra-big",
  },
  {
    title: "ID",
    size: "tiny",
  },
]

const MessageTemplatesList = React.memo(() => {
  const [triggerGetMessageTemplates, { isFetching, data }] = useLazyGetMessageTemplatesQuery()
  const [triggerGetTemplateLibraryGroups, { isFetching: isTemplatesFetching, data: templatesData }] =
    useLazyGetTemplateLibraryGroupsQuery()
  const [triggerAddTemplateLibrary, { isFetching: isAddTemplateFetching }] = useLazyAddTemplateLibraryQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const id = searchParams.get("id")

  const onDeleteClick = (id) => {
    dispatch(setCurrentTemplateID(id))
    dispatch(showDeleteMessageTemplateModal(true))
    navigate(`/messages/create-template`)
  }

  const onAddTemplateLibrary = async (value: number) => {
    try {
      const res: any = await triggerAddTemplateLibrary(value)

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Message template has been successfully added!")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditClick = (id) => {
    navigate(`/messages/edit-template?id=${id}`)
  }

  const onViewClick = (id) => {
    navigate(`/messages/add-template?id=${id}`)
  }

  const renderRightLink = (path) => {
    switch (path) {
      case "/messages/add-template":
        return "/messages/edit-template"
      case "/messages/create-template":
        return "/messages/create-template"
      default:
        return "/messages/edit-template"
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetMessageTemplates()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetTemplateLibraryGroups()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={styles.templateList}>
      <div className={styles.tabsWrapper}>
        <NavLink className={styles.tab} to={renderRightLink(location.pathname)} activeClassName={styles.active}>
          My templates
        </NavLink>
        <NavLink className={classNames(styles.tab)} to="/messages/add-template" activeClassName={styles.active}>
          Templates library
        </NavLink>
      </div>
      {location.pathname === "/messages/add-template" ? (
        <Table
          tableName="message-library-templates"
          headerCells={libraryTableHeaderCells}
          className={styles.table}
          isLoading={isFetching || isTemplatesFetching || isAddTemplateFetching}
          currentItemID={id}
          dynamicNavHandler1={onAddTemplateLibrary}
          dynamicNavHandler2={onEditClick}
          bodyRows={convertTemplatesLibraryToTableRows(templatesData?.data)}
          onViewClick={onViewClick}
        />
      ) : (
        <Table
          headerCells={templatesTableHeaderCells}
          className={styles.table}
          isLoading={isFetching}
          currentItemID={id}
          bodyRows={convertMessageTemplatesToTableRows(data?.data)}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
        />
      )}
    </div>
  )
})

export default MessageTemplatesList
