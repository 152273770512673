import dayjs from "dayjs"
import dayjs_plugin_utc from "dayjs/plugin/utc"

export const dropDownOptions = [
  {
    value: "lastHour",
    label: "Last Hour",
  },
  {
    value: "last6Hours",
    label: "Last 6 Hours",
  },
  {
    value: "last24Hours",
    label: "Last 24 Hours",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "last3Days",
    label: "Last 3 Days",
  },
  {
    value: "last7Days",
    label: "Last 7 Days",
  },
  {
    value: "last30Days",
    label: "Last 30 Days",
  },
]

const setDateParam = (startDate: Date, endDate: Date) => {
  dayjs.extend(dayjs_plugin_utc)

  const startDateTime = dayjs.utc(startDate)
  const startDateString = startDateTime.format("YYYY-MM-DDTHH:mm:00+0000")
  const endDateString = dayjs.utc(endDate).format("YYYY-MM-DDTHH:mm:00+0000")

  return {
    startDateTime,
    startDateString,
    endDateString,
  }
}

export const getMonitoringDateRangeParams = (value: string | number | boolean) => {
  if (value === "lastHour") {
    const lastHour = new Date()
    lastHour.setHours(lastHour.getHours() - 1)

    return { ...setDateParam(lastHour, new Date()), intervalInMinutes: 1, itemsCount: 60 }
  }

  if (value === "last6Hours") {
    const last6Hours = new Date()
    last6Hours.setHours(last6Hours.getHours() - 6)

    return { ...setDateParam(last6Hours, new Date()), intervalInMinutes: 3, itemsCount: 120 }
  }

  if (value === "last24Hours") {
    const last24Hours = new Date()
    last24Hours.setHours(last24Hours.getHours() - 24)

    return { ...setDateParam(last24Hours, new Date()), intervalInMinutes: 10, itemsCount: 144 }
  }

  if (value === "yesterday") {
    const yesterdayStart = dayjs().utc().subtract(1, "day").startOf("day").toDate()
    const yesterdayEnd = dayjs().utc().subtract(1, "day").endOf("day").toDate()

    return { ...setDateParam(yesterdayStart, yesterdayEnd), intervalInMinutes: 10, itemsCount: 144 }
  }

  if (value === "last3Days") {
    const last3DaysStart = dayjs().utc().subtract(3, "day").startOf("day").toDate()
    const last3DaysEnd = dayjs().utc().subtract(1, "day").endOf("day").toDate()

    return { ...setDateParam(last3DaysStart, last3DaysEnd), intervalInMinutes: 30, itemsCount: 144 }
  }

  if (value === "last7Days") {
    const last7DaysStart = dayjs().utc().subtract(7, "day").startOf("day").toDate()
    const last7DaysEnd = dayjs().utc().subtract(1, "day").endOf("day").toDate()

    return { ...setDateParam(last7DaysStart, last7DaysEnd), intervalInMinutes: 60, itemsCount: 168 }
  }

  const last30DaysStart = dayjs().utc().subtract(30, "day").startOf("day").toDate()
  const last30DaysEnd = dayjs().utc().subtract(1, "day").endOf("day").toDate()

  return { ...setDateParam(last30DaysStart, last30DaysEnd), intervalInMinutes: 240, itemsCount: 180 }
}
