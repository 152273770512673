import styles from "./index.module.scss"
import { FormEvent, useState } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconContractor } from "../../assets/svg/modal-icon-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { InputLogo } from "../../components/form/InputLogo"
import { ToggleButton } from "../../components/form/ToggleButton"
import { setCurrentZip, setContractor } from "../../store/tempDataSlise"
import { useLazyEditContractorQuery, useLazyGetContractorsQuery } from "../../store/api/contractorsApi"
import { validationInitialState, onFormValidate, editContractor2Schema } from "../../utils/onValidate"
import { toast } from "react-toastify"
import { showEditContractorModal1, showEditContractorModal2, showEditContractorModal3 } from "../../store/modalSlise"
import { InputPhone } from "../../components/form/InputPhone"
import { urlPatternWithoutHttpPrefix } from "../../utils/regularExpressions"
import { ContractorCustomApp } from "../components/contractorCustomApp"
import { ContractorPhoneSupport } from "../components/contractorPhoneSupport"

interface Props {
  onCancel: () => void
}

export const EditContractorModal2 = ({ onCancel }: Props) => {
  const [triggerEditContractor, { isFetching }] = useLazyEditContractorQuery()
  const [triggerGetContractors] = useLazyGetContractorsQuery()
  const dispatch = useAppDispatch()
  const contractor = useAppSelector((state) => state.tempData.contractor)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [imageFile, setImageFile] = useState<File | undefined>(contractor?.image)
  // const [imageFile2, setImageFile2] = useState<File | undefined>(contractor?.logo2File)
  const [inputValues, setInputValues] = useState(contractor!)
  const [error, setError] = useState(validationInitialState)
  const isSTChecked = contractor?.isSTChecked === "1"

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
        ...(name === "isCustom" && !checked ? { appStoreLink: "", googlePlayLink: "" } : {}),
        ...(name === "phoneForSupport" && checked ? { phoneForSupportCall: "", phoneForSupportText: "" } : {}),
      }
    })
  }

  const onInputBlur = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget

    if (urlPatternWithoutHttpPrefix.test(value)) {
      const urlPattern = /^(http:\/\/|https:\/\/)/

      setInputValues((prevState) => {
        return { ...prevState, [name]: `${urlPattern.test(value) ? "" : "https://"}${value}` }
      })
    }
  }

  const onAddIntegrationClick = () => {
    dispatch(showEditContractorModal2(false))
    dispatch(showEditContractorModal3(true))
  }

  const onBack = () => {
    dispatch(
      setContractor({
        ...contractor,
        ...inputValues,
        ...{
          image: imageFile,
          // logo2File: imageFile2,
        },
      }),
    )
    dispatch(showEditContractorModal1(true))
    dispatch(showEditContractorModal2(false))
  }

  const prepareData = () => {
    if (!contractor) return

    const formData = new FormData()

    formData.append("name", contractor?.name!)
    formData.append("brand", contractor?.brand!)
    formData.append("ein", contractor?.ein!)
    formData.append("address1", contractor?.address1!)
    formData.append("address2", contractor?.address2!)
    formData.append("url", inputValues.url!)
    formData.append("schedule", inputValues.schedule!)
    formData.append("full_name", inputValues.fullName!)
    formData.append("tel1", inputValues.phone!)
    formData.append("email", inputValues.email!)
    contractor?.state && formData.append("state", contractor?.state[0].value! as string)
    contractor?.city && formData.append("city", contractor?.city[0].value! as string)
    contractor?.zip && formData.append("zip", contractor?.zip[0].value! as string)
    formData.append("is_enabled", inputValues?.isDisable ? "0" : "1")
    formData.append("is_custom", inputValues.isCustom ? "1" : "0")
    imageFile && formData.append("image", imageFile)
    // imageFile2 && formData.append("logo_w2h1", imageFile2)
    formData.append("app_store_link", inputValues.appStoreLink || "")
    formData.append("google_play_link", inputValues.googlePlayLink || "")
    formData.append("phone_for_support", inputValues.phoneForSupport ? "1" : "0")
    formData.append("phone_for_support_call", inputValues.phoneForSupportCall || "")
    formData.append("phone_for_support_text", inputValues.phoneForSupportText || "")
    return formData
  }

  const sendRequest = async () => {
    try {
      const res: any = await triggerEditContractor({ data: prepareData() as any, id: contractor?.id! })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Contractor has been successfully edited!")
        dispatch(setCurrentZip(undefined))
        triggerGetContractors(requestParams === null ? {} : requestParams)

        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onCancelClick = () => {
    onCancel()
    dispatch(setContractor(null))
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, editContractor2Schema, setError, true)) {
      dispatch(
        setContractor({
          ...contractor,
          ...inputValues,
          ...{
            image: imageFile,
            // logo2: imageFile2,
          },
        }),
      )

      if (isSTChecked) {
        onAddIntegrationClick()
      } else {
        sendRequest()
      }
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          cancelButtonText="Back"
          confirmButtonText={isSTChecked ? "Next" : "Save"}
          confirmButtonType="submit"
          onConfirm={onSubmit}
          isFetching={isFetching}
          onClose={onCancelClick}
          onCancel={onBack}
        >
          <ModalHeader title="Edit Contractor" subtitle={`Step ${isSTChecked ? "2/3" : "2/2"}`}>
            <IconContractor />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Company web URL"
              placeholder="Enter URL"
              name="url"
              value={inputValues.url}
              onChange={onInputChange}
              onBlur={onInputBlur}
              errorMessage={error.field === "url" ? error.message : ""}
            />
            <InputNew
              label="Schedule URL"
              placeholder="Enter schedule URL"
              name="schedule"
              value={inputValues.schedule}
              onChange={onInputChange}
              onBlur={onInputBlur}
              errorMessage={error.field === "schedule" ? error.message : ""}
            />
            <InputNew
              label="Full Name"
              placeholder="Enter Full Name"
              name="fullName"
              value={inputValues.fullName}
              onChange={onInputChange}
              errorMessage={error.field === "fullName" ? error.message : ""}
            />
            <InputPhone
              label="Phone number"
              placeholder="Enter phone"
              name="phone"
              value={inputValues.phone}
              onChange={(value) => setInputValues({ ...inputValues, ...{ phone: value } })}
              errorMessage={error.field === "phone" ? error.message : ""}
            />
            <ContractorPhoneSupport
              phoneForSupport={inputValues.phoneForSupport}
              phoneForSupportCall={inputValues.phoneForSupportCall}
              phoneForSupportText={inputValues.phoneForSupportText}
              inputValues={inputValues}
              error={error}
              onInputChange={onInputChange}
              setInputValues={setInputValues}
            />
            <InputNew
              label="Email"
              placeholder="Enter email address"
              name="email"
              value={inputValues.email}
              disabled={true}
              onChange={onInputChange}
              errorMessage={error.field === "email" ? error.message : ""}
            />
            <InputLogo
              name="logo"
              id="logo"
              value={imageFile && [imageFile]}
              imageUrl={contractor?.logo}
              errorMessage={error.field === "logo" ? error.message : ""}
              label="Company logo"
              formatNote="(Format: Png Ratio: 16:6 Max size: 750x375px)"
              onSetFile={(files) => setImageFile(files[0] as any)}
            />
            {/* <InputLogo
              name="logo2"
              id="logo2"
              value={imageFile2 && [imageFile2]}
              imageUrl={contractor?.logo2String}
              errorMessage={error.field === "logo2" ? error.message : ""}
              label="New logo 2:1"
              formatNote="(Format: Png Ratio: 2:1 Max size: 750x375px)"
              onSetFile={(files) => setImageFile2(files[0] as any)}
            /> */}
            <ContractorCustomApp
              customAppEnabled={inputValues.isCustom || false}
              appStoreLink={inputValues.appStoreLink}
              googlePlayLink={inputValues.googlePlayLink}
              error={error}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
            />
            <div className={styles.modalLine}></div>
            <div className={styles.toggleFieldWrapper}>
              <ToggleButton id="isDisable" name="isDisable" isChecked={inputValues.isDisable} onChange={onInputChange}>
                Disable contractor
              </ToggleButton>
              {!isSTChecked && (
                <span onClick={onAddIntegrationClick} className={styles.addButton}>
                  Add Integration
                </span>
              )}
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
