import dayjs from "dayjs"

export const perfTestHistoryFilters = [
  { id: 1, label: "All", value: "all" },
  { id: 2, label: "Today", value: "today" },
  { id: 3, label: "Yesterday", value: "yesterday" },
  { id: 4, label: "Last Week", value: "last-week" },
  { id: 5, label: "Last Month", value: "last-month" },
  { id: 6, label: "This Year", value: "this-year" },
  { id: 7, label: "Custom", value: "custom" },
]

export const setDateParam = (startDate: Date | null, endDate: Date | null) => {
  const startDateString = startDate ? dayjs(startDate).format("YYYY-MM-DD") : null
  const endDateString = endDate ? dayjs(endDate).format("YYYY-MM-DD") : null

  return {
    result_date_start: startDate
      ? {
          value: startDateString,
          label: "Start Date",
        }
      : null,
    result_date_end: endDate
      ? {
          value: endDateString,
          label: "End Date",
        }
      : null,
  }
}

export const getPerfTestDateRangeParams = (value: string) => {
  if (value === "today") {
    return setDateParam(new Date(), null)
  }

  if (value === "yesterday") {
    const yesterday = new Date()
    yesterday.setDate(new Date().getDate() - 1)
    return setDateParam(yesterday, yesterday)
  }

  if (value === "last-week") {
    const lastWeekStart = new Date()
    lastWeekStart.setDate(lastWeekStart.getDate() - 7 - lastWeekStart.getDay())
    const lastWeekEnd = new Date(lastWeekStart)
    lastWeekEnd.setDate(lastWeekEnd.getDate() + 6)
    return setDateParam(lastWeekStart, lastWeekEnd)
  }

  if (value === "last-month") {
    const lastMonthStart = new Date()
    lastMonthStart.setMonth(lastMonthStart.getMonth() - 1)
    lastMonthStart.setDate(1)
    const lastMonthEnd = new Date(lastMonthStart)
    lastMonthEnd.setMonth(lastMonthEnd.getMonth() + 1)
    lastMonthEnd.setDate(0)
    return setDateParam(lastMonthStart, lastMonthEnd)
  }

  if (value === "this-year") {
    const thisYearStart = new Date()
    thisYearStart.setMonth(0)
    thisYearStart.setDate(1)
    return setDateParam(thisYearStart, null)
  }

  return {
    result_date_start: null,
    result_date_end: null,
  }
}
