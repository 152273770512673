import styles from "./index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { ReactComponent as BackIcon } from "../../../assets/svg/icon-arrow-left.svg"
import { MessageForm } from "../../../components/form/MessageForm"
import MessageTemplatesList from "../../../components/common/MessageTemplatesList"
import { useLazyCreateMessageTemplateQuery, useLazyGetMessageTemplatesQuery } from "../../../store/api/contractorsApi"
import { UITypes, ApiTypes } from "../../../types"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

export const MessageTemplateCreate = () => {
  const [triggerCreateMessageTemplate] = useLazyCreateMessageTemplateQuery()
  const [triggerGetMessageTemplates] = useLazyGetMessageTemplatesQuery()
  const navigate = useNavigate()

  const onCreateTemplate = async (data: UITypes.MessageTemplate) => {
    try {
      const res: any = await triggerCreateMessageTemplate({
        title: data.title,
        body: data.message,
        repeat_every: data.frequency as ApiTypes.Model.MessageTemplateFrequency,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Message template has been successfully created!")
        triggerGetMessageTemplates()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ContentWrapper title="Create message template">
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <ButtonNew onClick={() => navigate(-1)} color="greyBordered" className={styles.backButton}>
            <BackIcon />
            Back
          </ButtonNew>
        </nav>
        <div className={styles.contentWrapper}>
          <div className={styles.sectionWrapper}>
            <MessageForm onSave={onCreateTemplate} />
          </div>
          <div className={styles.sectionWrapper}>
            <MessageTemplatesList />
          </div>
        </div>
      </ContentWrapper>
    </>
  )
}
