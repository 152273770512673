import styles from "./index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconDeviceSN } from "../../assets/svg/modal-icon-device-sn.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { ModalHeader } from "../../components/common/ModalHeader"
import { StatusOfPhysical } from "../../components/table/StatusOfPhysical"
import { useAppSelector } from "../../store/hooks"
import { useEffect, useState } from "react"
import { useLazyGetDeviceHistoryLogQuery } from "../../store/api/adminApi"
import { Spinner } from "../../components/common/Spinner"
import { convertDeviceHistoryLog } from "../../utils/convertData"
import { UITypes } from "../../types"
import { v4 as uuidv4 } from "uuid"

interface Props {
  onCancel: () => void
}

export const DeviceHistoryLogModal = ({ onCancel }: Props) => {
  const deviceHistoryLogID = useAppSelector((state) => state.tempData.deviceHistoryLogID)
  const [triggerGetDeviceHistoryLog, { isFetching }] = useLazyGetDeviceHistoryLogQuery()
  const [historyLog, setHistoryLog] = useState({
    id: "",
    sn: "",
    log: [],
  })

  useEffect(() => {
    if (!deviceHistoryLogID) return

    const fetchData = async () => {
      try {
        const res = await triggerGetDeviceHistoryLog(deviceHistoryLogID)
        if (res?.data?.data) {
          setHistoryLog({
            id: res?.data?.data?.id || "",
            sn: res?.data?.data?.sn || "",
            log: convertDeviceHistoryLog(res?.data?.data?.history_log),
          } as never)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [deviceHistoryLogID])

  return (
    <>
      {createPortal(
        <ModalLayout width="490px" isWithoutFooter={true} onCancel={onCancel}>
          <ModalHeader title={historyLog.sn}>
            <IconDeviceSN />
          </ModalHeader>
          <div className={classNames(styles.modalContent)}>
            <header className={styles.snTableHeader}>
              <span className={styles.snHederCell}>Last Update </span>
              <span className={styles.snHederCell}>status</span>
              <span className={styles.snHederCell}>Author</span>
            </header>
            {isFetching ? (
              <Spinner />
            ) : (
              <div>
                {historyLog.log?.map((item: UITypes.HistoryLog) => (
                  <div className={styles.snBodyRow} key={uuidv4()}>
                    <span className={styles.snBodyCell}>{item?.lastUpdate}</span>
                    <span className={styles.snBodyCell}>
                      <StatusOfPhysical type={item?.status} />
                    </span>
                    <span className={styles.snBodyCell}>{item?.author}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
