import { Line } from "react-chartjs-2"
import { Chart, ScriptableContext, registerables } from "chart.js"
import { borderDashWithSkipped, defaultChartTooltipOptions, getChartGradient } from "../../utils/chartUtils"
import dayjs from "dayjs"
import { NoDataLayer } from "../../components/chart/NoDataLayer"
import { ApiTypes } from "../../types"
import { ChartSettings } from "./StatsReportChartsWrapper"
import styles from "./index.module.scss"

Chart.register(...registerables)

interface Props {
  selectedTime: string | number | boolean
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
  isSingleDataPoint: boolean
}

const HumidityChart = ({ selectedTime, historicalData, isSingleDataPoint }: Props) => {
  const settings = ChartSettings[selectedTime as keyof typeof ChartSettings]

  const parsedData =
    historicalData.map((item) => ({
      humidity: item["avg#average_current_humidity_embedded"].value,
      timestamp: item.key_as_string,
    })) || []

  const data = {
    labels: [...parsedData.map((item) => item.timestamp)],
    datasets: [
      {
        label: "",
        data: parsedData.map((item) => item.humidity),
        fill: "start",
        backgroundColor: (context: ScriptableContext<"line">) =>
          getChartGradient(context, "rgba(67,56,202,0.5)", "rgba(255,255,255,0)"),
        borderColor: "#6266f1",
        pointStyle: isSingleDataPoint as unknown as "circle",
        pointBackgroundColor: "#fff",
        spanGaps: true,
        segment: {
          borderDash: borderDashWithSkipped,
        },
      },
      ...(parsedData.length === 0
        ? [
            {
              label: "",
              data: [50, 51, 52, 53, 54],
            },
          ]
        : []),
    ],
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...defaultChartTooltipOptions,
        callbacks: {
          title: () => {
            return ""
          },
          label: function (this: any, tooltipItem) {
            const date = dayjs(tooltipItem.label)
            const label = `${tooltipItem.formattedValue}% ${date.format("YYYY-MM-DD HH:mm")}`

            return label
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          color: "#e2e8f0",
        },
        grid: {
          color: "#e2e8f0",
        },
        ticks: {
          maxRotation: 0,
          maxTicksLimit: settings.ticksCount,
          color: "#94A3B8",
          callback: (value) => value / settings.dataCountsDivider,
        },
      } as any,
      y: {
        position: "left",
        border: {
          display: false,
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          maxTicksLimit: 6,
          stepSize: parsedData.length === 0 ? 1 : 0.1,
          color: "#94A3B8",
          callback: function (value) {
            return `${value.toFixed(1)}%`
          },
        },
      } as any,
    },
    onHover: (ctx) => {
      ctx.chart.data.datasets[0].pointStyle = true
    },
  }

  return (
    <div className={styles.chartContainer}>
      {parsedData.length === 0 && <NoDataLayer />}
      <Line data={data} options={options} />
    </div>
  )
}

export default HumidityChart
