import { useState, useEffect } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { DoughnutChart } from "../../common/DoughnutChart"
import classNames from "classnames"
import { useLazyGetOrderStatusesQuery } from "../../../store/api/warehouseManagerApi"
import { Spinner } from "../../common/Spinner"

const initialState = {
  confirmed: 0,
  packed: 0,
  shipping: 0,
}

export const WidgetOrders = () => {
  const [triggerGetOrderStatuses, { isFetching }] = useLazyGetOrderStatusesQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetOrderStatuses()
        if (res?.data) {
          setWidgetData({
            confirmed: res?.data?.data?.confirmed,
            packed: res?.data?.data?.packing,
            shipping: res?.data?.data?.shipped,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <WidgetLayout title="Orders by status" className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <DoughnutChart values={Object.values(widgetData)} colors={["#22C55E", "#FCD34D", "#2563EB"]} />
            <div className={styles.widgetMainValue}>
              <span className={styles.widgetNote}>ORDERS</span>
              <div className={styles.widgetValueWrapper}>
                {Object.values(widgetData).reduce((acc, current) => acc + current, 0)}
              </div>
            </div>
          </div>
          <div className={styles.chartNotesWrapper}>
            <div className={classNames(styles.chartNote, styles.green)}>
              Confirmed
              <span className={styles.chartNoteValue}>{widgetData.confirmed}</span>
            </div>
            <span className={styles.chartNoteLine}></span>
            <div className={classNames(styles.chartNote, styles.yellow)}>
              Packed
              <span className={styles.chartNoteValue}>{widgetData.packed}</span>
            </div>
            <span className={styles.chartNoteLine}></span>
            <div className={classNames(styles.chartNote, styles.purple)}>
              Shipping
              <span className={styles.chartNoteValue}>{widgetData.shipping}</span>
            </div>
          </div>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
