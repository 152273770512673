import styles from "./index.module.scss"
import { useEffect, useState } from "react"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { CheckboxNew } from "../../../components/form/CheckboxNew"
import { useLazyGetVendorFulfilledOrdersQuery, useLazyGetVendorOrdersQuery } from "../../../store/api/vendorApi"
import { UITypes } from "../../../types"
import { toast } from "react-toastify"
import { convertVSMOrdersToTableRows } from "../../../utils/convertData"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  showDeleteOrderModalForVendor,
  showNewVendorOrderModal,
  showOrderFulfillmentModal,
  showEditVendorOrderModal,
  showEditColumnsModal,
} from "../../../store/modalSlise"
import {
  setRequestParams,
  setSelectedVSMOrder,
  setCurrentOrder,
  setCurrentTableColumns,
} from "../../../store/tempDataSlise"
import { WidgetWarehouseInformation } from "../../../components/widgets/WidgetWarehouseInformation"
import { WidgetVendorOrderStatus } from "../../../components/widgets/WidgetVendorOrderStatus"
import { FilterTags } from "../../../components/common/FilterTags"
import { useLazyGetTableColumnsQuery } from "../../../store/api/dashboardApi"
import { reorderTableHeaderCells } from "../../../utils/reorderTabbleColumns"

export const Orders = () => {
  const dispatch = useAppDispatch()
  const [triggerGetOrders, { isFetching, data }] = useLazyGetVendorOrdersQuery()
  const [triggerGetFulfilledOrder, { isFetching: isFetchingFulfilled, data: dataFulfilled }] =
    useLazyGetVendorFulfilledOrdersQuery()
  const [triggerGetTableColumns, { isFetching: isColumnsFetching, data: columnsData }] = useLazyGetTableColumnsQuery()
  const [selectFulfilled, setSelectFulfilled] = useState<boolean>(false)
  const selectedVSMOrder = useAppSelector((state) => state.tempData.selectedVSMOrder)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [isFirstRender, setFirstRender] = useState(true)
  const [tableHeaderCells, setTableHeaderCells] = useState<UITypes.TableHeaderCell[]>([
    {
      title: "",
      name: "checkbox",
      id: 1,
    },
    {
      title: "Order number",
      type: "sort-search",
      name: "id",
      id: 2,
    },
    {
      title: "Status",
      type: "select",
      name: "orderStatus",
      id: 3,
    },
    {
      title: "Company name",
      type: "sort-search",
      name: "company_name",
      id: 4,
    },
    {
      title: "Creation Date",
      type: "sort-date",
      name: "create_date",
      id: 5,
    },
    {
      title: "Quantity",
      type: "sort-search",
      name: "quantity",
      id: 6,
    },
    {
      title: "Warehouse",
      type: "sort-search",
      name: "warehouse",
      id: 7,
    },
    {
      title: "Phone number",
      type: "sort-search",
      name: "phone_number",
      id: 8,
    },
    {
      title: "Email",
      type: "sort-search",
      name: "email",
      id: 9,
    },
    {
      title: "Status Updated",
      type: "sort-search",
      name: "status_updated",
      id: 10,
    },
  ])

  const onSetCurrentOrder = (id?: number) => {
    if (!selectFulfilled) {
      const ordersList = data?.data

      if (!id || !ordersList?.length) {
        dispatch(setSelectedVSMOrder(undefined))
        dispatch(setCurrentOrder(undefined))
      } else {
        const selectedVSMOrder = ordersList.filter((item) => item.id === id)[0]
        dispatch(setSelectedVSMOrder(selectedVSMOrder))
        dispatch(setCurrentOrder(selectedVSMOrder))
      }
    }
  }

  const onEditClick = (id: number) => {
    if (!selectFulfilled) {
      onSetCurrentOrder(id)
      dispatch(showEditVendorOrderModal(true))
    }
  }

  const onDeleteClick = (id: number) => {
    if (!selectFulfilled) {
      onSetCurrentOrder(id)
      dispatch(showDeleteOrderModalForVendor(true))
    }
  }

  const onNewClick = () => {
    dispatch(showNewVendorOrderModal(true))
  }

  const onShowFulfill = (event: React.FormEvent<HTMLInputElement>) => {
    setSelectFulfilled(event.currentTarget.checked)
  }

  const onFulfillClick = () => {
    if (!checkFulfillAbility()) return false
    dispatch(showOrderFulfillmentModal(true))
  }

  const onCheckboxChange = (id: number) => {
    onSetCurrentOrder(id === selectedVSMOrder?.id ? undefined : id)
  }

  const checkFulfillAbility = () => {
    return selectedVSMOrder?.id && selectedVSMOrder?.status === "shipping"
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = selectFulfilled ? await triggerGetFulfilledOrder(params) : await triggerGetOrders(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "admin_orders_list",
        columns: tableHeaderCells,
      }),
    )
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
    triggerGetTableColumns({ list_name: "admin_orders_list" })
  }, [requestParams, selectFulfilled, isFirstRender])

  useEffect(() => {
    if (columnsData?.data?.config) {
      setTableHeaderCells(reorderTableHeaderCells(tableHeaderCells, columnsData?.data?.config))
    }
  }, [columnsData])

  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetWarehouseInformation />
        <WidgetVendorOrderStatus />
      </div>
      <ContentWrapper title="Orders history">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onNewClick} color="blue">
              New order
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
              Edit columns
            </span>
            <ButtonNew
              disabled={!checkFulfillAbility()}
              onClick={onFulfillClick}
              color={!checkFulfillAbility() ? "grey" : "blue"}
            >
              Fulfill
            </ButtonNew>
            <CheckboxNew onChange={onShowFulfill} className={styles.tableNavItem} isReverse={true} id="fulfilled">
              Show fulfilled
            </CheckboxNew>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <FilterTags
          clearAdditionalTags={() => dispatch(setSelectedVSMOrder(undefined))}
          additionalTags={
            selectedVSMOrder
              ? [
                  {
                    label: "Order number",
                    values: [selectedVSMOrder.id],
                  },
                ]
              : []
          }
        />
        <Table
          tableName="vendor-orders"
          headerCells={tableHeaderCells}
          areHeaderColumnsDynamic
          bodyRows={convertVSMOrdersToTableRows(selectFulfilled ? dataFulfilled?.data : data?.data, tableHeaderCells)}
          isLoading={isFetching || isFetchingFulfilled}
          currentItemID={selectedVSMOrder?.id}
          onCheckboxChange={onCheckboxChange}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
        <Pagination meta={selectFulfilled ? dataFulfilled?.meta : data?.meta} />
      </ContentWrapper>
    </>
  )
}
